export default {
    messageQuestionService: "שירות זה מיועד לשימוש רק אם יש לך בעיה במילוי טופס הבקשה, איננו יכולים להשיב לשאלות אחרות כאן.",
    labelName: "שֵׁם",
    labelReference: "הפנייה להזמנה",
    labelPhone: "מספר טלפון",
    labelEmail: "אימייל",
    labelQuery: "השאלה שלך",
    labelAskQuestion: "שלח לנו שאלה כאן ...",
    buttonOpenModal: "שאל שאלה",
    title: "שאל שאלה",
    modalTitle: "4. יש לך בעיה טכנית עם הטופס?",
    toastSuccess: "קיבלנו את שאלתך וניצור איתך קשר תוך 24 שעות",
    toastError: "משהו השתבש בבקשתך, נסה שוב",
    nameValidation: "נדרש שם",
    referenceValidation: "יש להפנות",
    questionValidation: "שאלתך נדרשת",
}