import * as React from 'react'
import Box from '@mui/material/Box';
import {
    Form,
    Formik,
    FormikProps
} from 'formik';
import {
    useAutoSave,
    useMsClarityInjection,
    useLiveChatEnabledStatus
} from '../hooks/queries';
import {useFormContext} from './providers/FormProvider';
import {IFormValues} from './Interfaces/IFormValues';
import {useIsOffline} from './utils/helpers';
import {BookingAndContactInformationSection} from './sections/BookingAndContactInformationSection';
import {HomeSection} from './sections/HomeSection';
import {Loader} from './providers/Loader';
import {Footer} from './navigation/Footer';
import {useFormSchema} from './schemas/formSchema';
import {Header} from './navigation/Header';
import {RefundRequestSection} from './sections/RefundRequestSection';
import {PaymentInformationSection} from './sections/PaymentInformationSection'
import {ConfirmationSection} from './sections/ConfirmationSection';
import {Section} from './enums/section';
import {LiveChat} from '../../liveChat/LiveChat'
import {Steps} from './navigation/Steps';

interface MainFormProps {
    memberId: string | null,
    url: string
}

export const Main = (props: MainFormProps) => {

    const {
        memberId,
        url
    } = props;

    const {
        allTicketsAssigned,
        applicationReference,
        applicationSubmitted,
        bankAddressSubmit,
        bookingMatchedAndFoundCompleted,
        initialFormState,
        isRefundStatusDeclined,
        offlinePaymentSubmit
    } = useFormContext();

    const isOfflinePayment = useIsOffline();
    const formSchema = useFormSchema();
    useAutoSave();
    useMsClarityInjection();
    useLiveChatEnabledStatus();

    const showLiveChatWidget = !isRefundStatusDeclined &&
        !bookingMatchedAndFoundCompleted &&
        !allTicketsAssigned &&
        applicationReference?.length > 0;

    return (
        <>
            <LiveChat showChat={showLiveChatWidget}/>

            <Formik
                initialValues={initialFormState}
                validationSchema={formSchema}
                enableReinitialize
                validateOnBlur
                onSubmit={!isOfflinePayment ? bankAddressSubmit : offlinePaymentSubmit}>
                {(formProps: FormikProps<IFormValues>) =>
                    <Form>
                        <Header/>

                        <Loader
                            formProps={formProps}
                            url={url}
                        />

                        {!applicationSubmitted &&
                            <>
                                <Steps formProps={formProps}/>

                                <Box sx={{
                                    pt: '20px',
                                    ml: 2,
                                    mr: 2
                                }}>
                                    <HomeSection
                                        formProps={formProps}
                                        url={url}
                                        memberId={memberId}
                                    />

                                    <BookingAndContactInformationSection
                                        key={Section.BookingAndContactInformation}
                                        formProps={formProps}
                                    />

                                    <RefundRequestSection
                                        key={Section.RefundRequest}
                                        formProps={formProps}
                                    />

                                    <PaymentInformationSection
                                        key={Section.PaymentInformation}
                                        formProps={formProps}
                                    />
                                </Box>
                            </>
                        }

                        {applicationSubmitted &&
                            <Box sx={{
                                ml: 2,
                                mr: 2
                            }}>
                                <ConfirmationSection formProps={formProps}/>
                            </Box>
                        }

                        <Box sx={{
                            pb: {
                                xs: '100px'
                            }
                        }}>
                            <Footer
                                formProps={formProps}
                                url={url}
                            />
                        </Box>

                    </Form>
                }
            </Formik>
        </>
    );
}