export default {
    messageQuestionService: "Dieser Service ist nur zur Verfügung, wenn Sie Probleme beim Ausfüllen des Antragsformulars haben. Wir können hier keine weiteren Fragen beantworten.",
    labelName: "Name",
    labelReference: "Buchungsreferenz",
    labelPhone: "Telefonnummerr",
    labelEmail: "Email",
    labelQuery: "Ihre Frage",
    labelAskQuestion: "Senden Sie uns hier eine Frage ...",
    buttonOpenModal: "Stelle eine Frage",
    title: "Stelle eine Frage",
    modalTitle: "4. Haben Sie ein technisches Problem mit dem Formular?",
    toastSuccess: "Wir haben Ihre Frage erhalten und werden Sie innerhalb von 24 Stunden kontaktieren",
    toastError: "Bei Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    nameValidation: "Name ist erforderlich",
    referenceValidation: "Referenz ist erforderlich",
    questionValidation: "Ihre Frage ist erforderlich"
}