export default {
    labelSavedApplicationReference: "Сақталған қолданба сілтемесі:",
    labelLanguage: "Тіл",
    messageWarningBookingHeading1: "Брондау тоқтатылды:",
    messageWarningBookingHeading2: "Брондау кейінге қалдырылды/қайта жоспарланған:",
    messageWarningBookingBody1: "Ұйымдастырушы немесе авиакомпания брондауыңыздан бас тартса, бұл команда сізге ақшаны қайтаруға көмектесе алмайды. Сізге тікелей тұтынушыларға қызмет көрсету тобына хабарласу қажет; Байланыс мәліметтерін брондау растамасын немесе веб-сайтты қараңыз.",
    messageWarningBookingBody2: "Брондау кейінге қалдырылған болса, ұзартылған қайтарылатын шарттар жаңа күнге ауысады. Егер сізде біздің шарттарымызға сәйкес ақшаны қайтаруға дәлелді себеп болса, дұрыс растайтын дәлелдермен пішінді толтырыңыз.",
    messageWarningBookingBody3: "Назар аударыңыз: бұл қызмет брондау растау электрондық поштасында қолжетімді біздің тұжырымымызда қамтылған себептер бойынша ғана қайтаруды қамтамасыз ете алады.",
    messageWarningBookingBody4: "Біздің шарттарымызға жатпайтын себептерге байланысты брондау жасай алмасаңыз, біз көмектесе алмаймыз. Сізге тұтынушыларға қызмет көрсету тобына тікелей хабарласу қажет, себебі олар көмектесе алады; Байланыс мәліметтерін брондау растамасын немесе веб-сайтты қараңыз.",
    linkWarningBooking: "Мұнда",
    formSectionNavigatorStep1: "1-қадам",
    formSectionNavigatorStep2: "2-қадам",
    formSectionNavigatorStep3: "3-қадам",
    formSectionNavigatorStep1Text: "Байланыс және брондау мәліметтері",
    formSectionNavigatorStep2Text: "Қайтару себебі",
    formSectionNavigatorStep3Text: "Төлем мәліметтері",
    messageBookingMatchedAndFound: "Егер брондау туралы анықтамаңыз бар қолданбамен байланысты болса, біз сізге қолданбаңызға кіру сілтемесі бар электрондық хат жібереміз",
    messageBookingMatchedAndFoundCompleted: "Қайтару туралы өтінішіңіз әлдеқашан мақұлданды! Төлемге қатысты қандай да бір мәселелер туындаса, хабарласыңыз:",
    applicationDeclinedMessage: "Өтінішіңіз қабылданбады және қазір жабылды.",
    messageBookingNotFound1: "Біз бұл анықтамамен брондау таба алмаймыз. Брондау растау электрондық поштаңызды тексеріп, қайта енгізіңіз.",
    messageBookingNotFound2: "Уайымдамаңыз, егер брондау анықтамаңыз жұмыс істемесе, «Брондауды табу» опциясын таңдап, мәліметтеріңізді енгізуіңізге болады. Пішінді толтырған кезде брондау мәліметтерін табуға көмектесу үшін брондауды растау электрондық поштасының көшірмесін жүктеп салыңыз.",
    messageLocatingBooking: "Брондауыңызды анықтау...",
    messageLoadingBank: "Банк ақпараты жүктелуде...",
    messageFailCurrencyLookup: "Валютаны алу қатесі",
    messageSavingApplication: "Қолданбаңыз сақталуда...",
    messageSavedApplication: "Қайтару қолданбасы сәтті сақталды",
    messageFailSavedApplication: "Қайтару қолданбасы сақталмады",
    messageSendingEmailReminder: "Электрондық пошта еске салғышын жіберу...",
    messageErrorLocationDetails: "Орын мәліметтерін алу қатесі",
    messageFailFormSubmission: "Пішін жіберу сәтсіз аяқталды",
    messageApplicationCannotBeFound: "Қолданба табылмады",
    messageApplicationRetrievalError: "Қолданбаны шығарып алу қатесі",
    messageSubmittingApplication: "Өтінім берілуде...",
    messageEventDateLimitError: "Уақыт шегінен тыс хабарландыру себебі",
    messageAllTicketsAssigned: "Барлық билеттер басқа қайтару өтініміне тағайындалды.",
    selectPrefix: "таңдаңыз",
    selectPostfix: "",
    fileUpload: "жүктеп салу үшін осы жерді басыңыз",
    fileUploadAcceptedFilesMessage: "Біз тек PDF файлдары мен .png, .jpeg, .heic, .webp кескін пішімдерін қабылдаймыз.",
    fileUploadNoFiles: "0 файл таңдалды",
    fileUploadPreviouslyUploaded: "Бұрын жүктелген:",
    fileUploadSelectedUploaded: "Таңдалғандар әлдеқашан жүктеп салынған:",
    buttonNext: "Келесі",
    buttonPrevious: "Алдыңғы",
    buttonSave: "Сақтау",
    buttonCancel: "Болдырмау",
    buttonSubmit: "Жіберу",
    buttonConfirm: "Растау",
    buttonYes: "Иә",
    buttonNo: "Жоқ",
    buttonClose: "Жабық",
    linkPrivacyStatement: "Құпиялық туралы мәлімдеме",
    linkComplaintsPolicy: "Шағымдар саясаты",
    validationRequired: "Міндетті",
    validationInvalid: "Жарамсыз",
    labelUploadPrefix: "Жүктеп салу",
    labelUploadPostfix: "",
    labelChange: "Өзгерту",
    labelView: "Көрініс",
    browserNavigationMessage: "Сақталмаған өзгертулер жоғалады"
};