export default {
    labelSavedApplicationReference:"已保存的应用程序引用:",
    labelLanguage: "語言",
    messageWarningBookingHeading1: "预订已取消：",
    messageWarningBookingHeading2: "预订推迟/重新安排：",
    messageWarningBookingBody1: "如果组织者或航空公司取消了您的预订，此团队将无法协助您退款。 您将需要直接与您的客户服务团队联系； 请参阅您的预订确认信或网站以获取联系方式。",
    messageWarningBookingBody2: "如果您的预订已被推迟，则您延长的可退款条款将延续到新日期。如果您有根据我们条款退款的正当理由，请填写表格并提供正确的支持证据。",
    messageWarningBookingBody3: "请注意：此服务只能根据我们的措辞中包含的原因提供退款，可在您的预订确认电子邮件中找到。",
    messageWarningBookingBody4: "如果您因我们条款以外的原因无法预订，我们将无法提供帮助。您需要直接联系您的客户服务团队，因为他们可能会提供帮助；请查看您的预订确认信或网站了解详细联系信息。",
    linkWarningBooking: "这里",
    formSectionNavigatorStep1:"步驟1",
    formSectionNavigatorStep2:"第2步",
    formSectionNavigatorStep3:"第3步",
    formSectionNavigatorStep1Text: "联系方式和预订详情",
    formSectionNavigatorStep2Text: "退款原因",
    formSectionNavigatorStep3Text: "付款详情",
    messageBookingMatchedAndFound: "如果您的预订参考号与现有申请相关联，我们将向您发送一封电子邮件，其中包含访问您的申请的链接",
    messageBookingMatchedAndFoundCompleted: "您的退款申请已被批准！如果您的付款有任何问题，请联系：",
    applicationDeclinedMessage: "您的申请已被拒绝，现已关闭。",
    messageBookingNotFound1: "我们找不到带有该参考的预订。请检查您的预订确认电子邮件并重新输入。",
    messageBookingNotFound2: "别担心，如果您的预订编号不起作用，您可以选择“查找预订”并输入您的详细信息。填写表格时，请上传您的预订确认电子邮件的副本，以帮助我们找到您的预订详细信息。",
    messageLocatingBooking:"您的預訂位置...",
    messageLoadingBank:"正在加載銀行信息...",
    messageFailCurrencyLookup:"检索货币时出错",
    messageSavingApplication:"正在保存您的應用程序...",
    messageSavedApplication:"退款請求已成功保存",
    messageFailSavedApplication:"無法保存退款請求",
    messageSendingEmailReminder:"加工...",
    messageErrorLocationDetails:"檢索位置詳細信息時出錯",
    messageFailFormSubmission:"表單提交失敗",
    messageApplicationCannotBeFound:"找不到該應用程序",
    messageApplicationRetrievalError:"檢索應用程序時出錯",
    messageSubmittingApplication:"正在提交应用程序...",
    messageEventDateLimitError:"在限制时间内进行通知的原因",
    messageAllTicketsAssigned: "所有机票都已分配给另一个退款申请。",
    selectPrefix:"選擇",
    selectPostfix: "",
    fileUpload:"單擊此處下載",
    fileUploadAcceptedFilesMessage: "我们只接受 PDF 和 .png、.jpeg、.heic、.webp 的图像格式",
    fileUploadNoFiles:"選擇了0個文件",
    fileUploadPreviouslyUploaded:"先前已上傳:",
    fileUploadSelectedUploaded: "所选内容已上传：",
    buttonNext:"下一步",
    buttonPrevious:"上一個",
    buttonSave:"保存",
    buttonCancel:"取消",
    buttonSubmit:"提交",
    buttonConfirm: "确认",
    buttonYes: "是的",
    buttonNo: "不",
    buttonClose: "关闭",
    linkPrivacyStatement: "隐私声明",
    linkComplaintsPolicy: "投诉政策",
    validationRequired: "必需的",
    validationInvalid: "无效的",
    labelUploadPrefix: "上传",
    labelUploadPostfix: "",
    labelChange: "改变",
    labelView: "视图",
    browserNavigationMessage: "未保存的更改将丢失"
};