export default {
    labelSavedApplicationReference: "保存されたアプリケーション参照：",
    labelLanguage: "言語",
    messageWarningBookingHeading1: "予約がキャンセルされました：",
    messageWarningBookingHeading2: "予約の延期/再スケジュール：",
    messageWarningBookingBody1: "主催者または航空会社によって予約がキャンセルされた場合、このチームは払い戻しを支援することはできません。 カスタマーサービスチームに直接連絡する必要があります。 連絡先の詳細については、予約確認書またはWebサイトを参照してください。",
    messageWarningBookingBody2: "予約が延期された場合、延長された返金可能な条件は新しい日付に引き継がれます。当社の条件に基づく払い戻しの正当な理由がある場合は、正しい裏付けとなる証拠を添えてフォームに記入してください。",
    messageWarningBookingBody3: "注意: このサービスでは、予約確認メールに記載されている理由に基づいてのみ払い戻しを行うことができます。",
    messageWarningBookingBody4: "規約外の理由でご予約いただけない場合は、ご対応いたしかねます。カスタマー サービス チームがサポートできる可能性があるため、カスタマー サービス チームに直接連絡する必要があります。連絡先の詳細については、予約確認書またはウェブサイトをご覧ください。",
    linkWarningBooking: "ここに",
    formSectionNavigatorStep1: "ステップ1",
    formSectionNavigatorStep2: "ステップ2",
    formSectionNavigatorStep3: "ステップ3",
    formSectionNavigatorStep1Text: "連絡先と予約の詳細",
    formSectionNavigatorStep2Text: "返金理由",
    formSectionNavigatorStep3Text: "お支払い詳細",
    messageBookingMatchedAndFound: "予約番号が既存のアプリケーションに関連付けられている場合は、アプリケーションにアクセスするためのリンクを含む電子メールを送信します。",
    messageBookingMatchedAndFoundCompleted: "払い戻しの申し込みはすでに承認されています。お支払いに問題がある場合は、以下にお問い合わせください。",
    applicationDeclinedMessage: "アプリケーションは拒否され、現在クローズされています。",
    messageBookingNotFound1: "その参照で予約を見つけることができません。予約確認メールを確認して、再入力してください。",
    messageBookingNotFound2: "予約参照が機能しない場合は、[予約を検索]を選択して詳細を入力できますのでご安心ください。フォームに記入する際は、予約確認メールのコピーをアップロードして、予約の詳細を確認してください。",
    messageLocatingBooking: "予約の場所...",
    messageLoadingBank: "銀行情報を読み込んでいます...",
    messageFailCurrencyLookup: "通貨の取得中にエラーが発生しました",
    messageSavingApplication: "アプリケーションを保存しています...",
    messageSavedApplication: "払い戻しアプリケーションが正常に保存されました",
    messageFailSavedApplication: "払い戻しアプリケーションを保存できませんでした",
    messageSendingEmailReminder: "処理...",
    messageErrorLocationDetails: "場所の詳細の取得中にエラーが発生しました",
    messageFailFormSubmission: "フォームの送信に失敗しました",
    messageApplicationCannotBeFound: "アプリケーションが見つかりませんでした",
    messageApplicationRetrievalError: "アプリケーションの取得中にエラーが発生しました",
    messageSubmittingApplication: " 申請書の提出...",
    messageEventDateLimitError: "制限時間外に通知する理由",
    messageAllTicketsAssigned: "すべてのチケットは別の払い戻し申請に割り当てられています。",
    selectPrefix: "選択する",
    selectPostfix: "",
    fileUpload: "アップロードするにはここをクリックしてください",
    fileUploadAcceptedFilesMessage: ".png、.jpeg、.heic、.webpのPDFおよび画像形式のみを受け付けます",
    fileUploadNoFiles: "0ファイルが選択されました",
    fileUploadPreviouslyUploaded: "以前にアップロードされた：",
    fileUploadSelectedUploaded: "選択したものはすでにアップロードされています:",
    buttonNext: "次",
    buttonPrevious: "前",
    buttonSave: "セーブ",
    buttonCancel: "キャンセル",
    buttonSubmit: "参加する",
    buttonConfirm: "確認",
    buttonYes: "はい",
    buttonNo: "番号",
    buttonClose: "閉じる",
    linkPrivacyStatement: "プライバシーに関する声明",
    linkComplaintsPolicy: "苦情ポリシー",
    validationRequired: "必須",
    validationInvalid: "無効",
    labelUploadPrefix: "アップロード",
    labelUploadPostfix: "",
    labelChange: "変える",
    labelView: "眺める",
    browserNavigationMessage: "保存されていない変更は失われます"
};