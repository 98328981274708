import * as React from "react";
import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {FormikHelpers, FormikProps} from "formik";
import {nameof} from "ts-simple-nameof";
import {getIsoCountryCode, getIsoCurrencyCode} from "../../../geoLocation/locationService";
import {getIpAddress} from "../../../ipLocation/ipLocationService";
import axios, {AxiosResponse} from "axios";
import {defaultFormState} from "../../state/defaultFormState";
import {toast} from "react-toastify";
import {useResetRecoilState, useSetRecoilState} from "recoil";
import {loadingShimState, SavedFormState} from "../../state/activity";
import {RefundStatus} from "../enums/refundStatus";
import {IDropDownItem} from "../Interfaces/IDropDownItem";
import {isAutoDeclineReason, isNullOrWhitespace} from "../utils/helpers";
import {useTranslation} from 'react-i18next';
import {IFindRefundResponse} from "../Interfaces/IFindRefundResponse";
import {OptionNo,
        OptionYes,
        refundBlanketCovidDeclineReasons,
        refundBlanketDeclineReasons
} from "../utils/constants";
import {IProductItem} from "../Interfaces/IProductItem";
import {defaultQuestionSetFieldValues} from "./formProviderHelper";
import {IMember} from "../Interfaces/IMember";
import {ICreateApplicationResponse} from "../Interfaces/ICreateApplicationResponse"
import {ICreateFeedbackRequest} from "../Interfaces/ICreateFeedbackRequest"
import {IEvidenceReason} from "../Interfaces/IEvidenceReason"
import {Section} from '../enums/section';
import {IFormValues} from '../Interfaces/IFormValues';
import {IPaymentError} from '../Interfaces/IPaymentError';
import {EventType} from '../enums/eventType';
import {ILookupResultData, IProductData} from '../Interfaces/ILookupResult';
import {IFeedbackStatus} from '../Interfaces/IFeedbackStatus';

interface IPaymentField {
    name: string;
    vendorId?: string;
    code: string;
    informationMessage?: string;
    informationCode: number;
    status?: string;
    regEx?: string;
    isConfigured: boolean;
    valuesAllowed: Record<string, string>[];
    ignoreLatinValidation: boolean;
}

interface IFormContextValues {
    bookingRefLookupAttempted: boolean,
    bookingMatched: boolean,
    bookingMatchedAndFound: boolean,
    bookingMatchedAndFoundCompleted: boolean,
    applicationDeclined: boolean,
    selectedSection: Section,
    isEventHidden: boolean,
    isFlightHidden: boolean,
    isHotelHidden: boolean,
    isEventDateBeyondTimeLimit: boolean,
    dateOfEventLabel: string,
    dateOfEventTooltip: string,
    requiredEvidence: IEvidenceReason[],
    displayUploadModal: boolean,
    remittanceLine3: string,
    remittanceLine4: string,
    isFetchingBooking: boolean,
    cancel: (formProps: FormikProps<IFormValues>) => void,
    fetchBookingData: (formProps: FormikProps<IFormValues>, memberId?: string, urlPath?: string) => void,
    fetchBookingBasedOnCustomerName: (formProps: FormikProps<IFormValues>, urlPath?: string) => Promise<boolean>,
    updateRequiredEvidence: (reasonId: number) => Promise<void>,
    updateReasonDeclineStatus: (formProps: FormikProps<IFormValues>, reasonId: number) => Promise<void>,
    updateReasonDeclineStatusWithConfirmId: (refundReasonConfirmId: number, reasonId: number) => Promise<void>,
    handleSectionChange: (formProps: FormikProps<IFormValues>, page: Section) => () => void,
    getPreviousSection: (formProps: FormikProps<IFormValues>) => Section,
    getNextSection: (formProps: FormikProps<IFormValues>) => Section,
    showBookingTypeFields: (bookingType: number) => void,
    updateBeneficiaryLabels: (formProps: FormikProps<IFormValues>, memberId: number, beneficiaryCountryCode: string, currencyCode: string, targetAmount: number, showLoader?: boolean, paymentType?: string) => void,
    changeCurrency: (formProps: FormikProps<IFormValues>, selectedCurrency: string) => void,
    loadingBeneficiaryLabels: boolean;
    isHiddenCallback: (page: Section, isBookingRef?: boolean) => boolean,
    setDisplayUploadModal: (value: boolean) => void,
    setSelectedSection: (value: Section) => void,
    saveForm: (formProps: FormikProps<IFormValues>) => void,
    loadApplication: (formProps: FormikProps<IFormValues>, page: Section, urlPath: string) => void,
    sendEmailReminder: (email: string, url: string) => void,
    initialFormState: IFormValues;
    applicationReference: string;
    onApplicationEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    applicationEmail: string;
    previousBookingConfirmationFiles: string[];
    previousRefundReasonFiles: string[];
    previousRefundReasonFilesAdditional: string[];
    paymentResponseFields: IPaymentField[];
    bankAddressSubmit: (values: IFormValues, helpers: FormikHelpers<IFormValues>) => void;
    bankAddress: string;
    showBankModal: boolean;
    setShowBankModal: (value: boolean) => void;
    updateRegion: (homeCountryCode: string, currency: string, resetRegions?: boolean) => void;
    regionDropdownItems: IDropDownItem[];
    setUrl: (value: string) => void;
    clearFiles: boolean;
    setClearFiles: (clearFiles: boolean) => void;
    bookingCounter: number;
    setBookingCounter: (value: number) => void;
    validCurrency: boolean,
    setLanguage: (value: string) => void;
    language: string;
    isEvidenceRequired: boolean;
    isBookingConfirmationRequired: boolean;
    handleConfirm: (formProps: FormikProps<IFormValues>) => void,
    applicationSubmitted: boolean,
    emailReminder: boolean,
    createApplicationResponse: ICreateApplicationResponse,
    showNegativeFeedbackModal: boolean,
    setShowNegativeFeedbackModal: (value: boolean) => void,
    saveFeedback: (createFeedbackRequest: ICreateFeedbackRequest) => void,
    customerPaymentsEmail: string;
    paymentErrors: IPaymentError[];
    setCountryCodeDialingCodeValue: (formProps: FormikProps<IFormValues>, diallingCode: string) => void,
    updateBankBranches: (currencyCode: string, countryCode: string, bankCode: string) => void,
    setBankBranchesDropDownItems: (value: IDropDownItem[]) => void,
    bankBranchesDropdownItems: IDropDownItem[],
    updateCountryCurrency: (countryCode: string, currencyCode: string) => void,
    countryCurrencyValue: string,
    member: IMember,
    offlinePaymentSubmit: (values: IFormValues, helpers: FormikHelpers<IFormValues>) => void,
    isOfflinePayment: boolean,
    isDecline: boolean,
    isDeclineOverride: boolean,
    isExtendedTerms?: boolean,
    hidePaymentInformation: (formProps: FormikProps<IFormValues>) => boolean,
    platformProductItems: IProductItem[],
    disableTicketChange: boolean,
    allTicketsAssigned: boolean,
    disableRefundAmount: boolean,
    disableTypeOfBooking: boolean,
    isRefundStatusDeclined: boolean
}

type Props = {
    children: React.ReactNode
};

const useFormContext = () => useContext(Context);

const Context = createContext<IFormContextValues>({
    bookingMatched: false,
    bookingMatchedAndFound: false,
    bookingMatchedAndFoundCompleted: false,
    applicationDeclined: false,
    bookingRefLookupAttempted: false,
    cancel(formProps: FormikProps<IFormValues>): void { },
    displayUploadModal: false,
    fetchBookingData(formProps: FormikProps<IFormValues>, memberId: string | undefined, urlPath: string): void { },
    fetchBookingBasedOnCustomerName(formProps: FormikProps<IFormValues>, urlPath?: string): Promise<boolean> { return Promise.resolve(false); },
    getPreviousSection(formProps: FormikProps<IFormValues>): Section { return undefined; },
    getNextSection(formProps: FormikProps<IFormValues>): Section { return undefined; },
    handleSectionChange(formProps: FormikProps<IFormValues>, page: Section): () => void { return function () { }; },
    isEventHidden: false,
    isFetchingBooking: false,
    isFlightHidden: false,
    isHotelHidden: false,
    isEventDateBeyondTimeLimit: false,
    dateOfEventLabel: "",
    dateOfEventTooltip: "",
    remittanceLine3: "",
    remittanceLine4: "",
    requiredEvidence: [],
    selectedSection: Section.BookingAndContactInformation,
    setDisplayUploadModal(value: boolean): void { },
    setSelectedSection(value: Section): void { },
    showBookingTypeFields(bookingType: number): void { },
    updateBeneficiaryLabels(formProps: FormikProps<IFormValues>, memberId: number, beneficiaryCountryCode: string, currencyCode: string, targetAmount: number): void { },
    changeCurrency(formProps: FormikProps<IFormValues>, selectedCurrency: string): void { },
    loadingBeneficiaryLabels: false,
    updateRequiredEvidence(reasonId: number): Promise<void> { return Promise.resolve(undefined); },
    updateReasonDeclineStatus(formProps: FormikProps<IFormValues>, reasonId: number): Promise<void> { return Promise.resolve(undefined); },
    updateReasonDeclineStatusWithConfirmId(refundReasonConfirmId: number, reasonId: number): Promise<void> { return Promise.resolve(undefined); },
    isHiddenCallback(page: Section, isBookingRef: boolean | undefined): boolean { return false; },
    saveForm(formProps: FormikProps<IFormValues>): void { },
    loadApplication(formProps: FormikProps<IFormValues>, page: Section, urlPath: string): void { },
    sendEmailReminder(email: string, url: string): void { },
    initialFormState: defaultFormState,
    applicationReference: '',
    applicationEmail: "",
    onApplicationEmailChange(event: React.ChangeEvent<HTMLInputElement>): void { },
    previousBookingConfirmationFiles: [],
    previousRefundReasonFiles: [],
    previousRefundReasonFilesAdditional: [],
    paymentResponseFields: [],
    bankAddress: "",
    bankAddressSubmit: (values: IFormValues, helpers: FormikHelpers<IFormValues>) => { },
    showBankModal: false,
    setShowBankModal: (value: boolean) => void {},
    updateRegion: (homeCountryCode: string, currency: string, resetRegions = true) => void {},
    regionDropdownItems: [],
    setUrl: (value: string) => void {},
    clearFiles: false,
    setClearFiles: (value: boolean) => void {},
    bookingCounter: 0,
    setBookingCounter: (value: number) => void {},
    validCurrency: false,
    setLanguage: (value: string) => void {},
    language: "",
    isEvidenceRequired: true,
    isBookingConfirmationRequired: false,
    handleConfirm(formProps: FormikProps<IFormValues>): void { },
    applicationSubmitted: false,
    emailReminder: false,
    createApplicationResponse: {} as ICreateApplicationResponse,
    showNegativeFeedbackModal: false,
    setShowNegativeFeedbackModal: (value: boolean) => void {},
    saveFeedback: (createFeedbackRequest: ICreateFeedbackRequest) => void {},
    customerPaymentsEmail: "",
    paymentErrors: [],
    setCountryCodeDialingCodeValue: (formProps: FormikProps<IFormValues>, diallingCode: string) => void {},
    updateBankBranches: (currencyCode: string, countryCode: string, bankCode: string) => void {},
    setBankBranchesDropDownItems: (value: IDropDownItem[]) => void {},
    bankBranchesDropdownItems: [],
    updateCountryCurrency: (countryCode: string, currencyCode: string) => void {},
    countryCurrencyValue: "",
    member: { memberId: 0, name: '', isOfflinePayment: false, evidenceRequired: true, bookingConfirmationRequired: true, partialRefundEnabled: true, typeOfBooking: EventType.None },
    offlinePaymentSubmit: (values: IFormValues, helpers: FormikHelpers<IFormValues>) => { },
    isOfflinePayment: false,
    isDecline: false,
    isDeclineOverride: false,
    isExtendedTerms: null,
    hidePaymentInformation(formProps: FormikProps<IFormValues>): boolean { return false; },
    platformProductItems: [],
    disableTicketChange: false,
    allTicketsAssigned: false,
    disableRefundAmount: false,
    disableTypeOfBooking: false,
    isRefundStatusDeclined: false
})

const FormProvider = ({ children }: Props) => {

    const { t, i18n } = useTranslation();

    const [bookingRefLookupAttempted, setBookingRefLookupAttempted] = useState<boolean>(false);
    const [bookingMatched, setBookingMatched] = useState<boolean>(false);
    const [bookingMatchedAndFound, setBookingMatchedAndFound] = useState<boolean>(false);
    const [bookingMatchedAndFoundCompleted, setBookingMatchedAndFoundCompleted] = useState<boolean>(false);
    const [applicationDeclined, setApplicationDeclined] = useState<boolean>(false);
    const [selectedSection, setSelectedSectionState] = useState<Section>(Section.BookingAndContactInformation);
    const [isEventHidden, setIsEventHidden] = useState<boolean>(true);
    const [isFlightHidden, setIsFlightHidden] = useState<boolean>(true);
    const [isHotelHidden, setIsHotelHidden] = useState<boolean>(true);
    const [isEventDateBeyondTimeLimit, setDateOfEventBeyondTimeLimit] = useState<boolean>(true);
    const [dateOfEventLabel, setDateOfEventLabel] = useState<string>('');
    const [dateOfEventTooltip, setDateOfEventTooltip] = useState<string>('');
    const [requiredEvidence, setRequiredEvidence] = useState<IEvidenceReason[]>([]);
    const [displayUploadModal, setDisplayUploadModalState] = useState<boolean>(false);
    const [remittanceLine3, setRemittanceLine3] = useState<string>('Remittance Line 3');
    const [remittanceLine4, setRemittanceLine4] = useState<string>('Remittance Line 4');
    const [isFetchingBooking, setIsFetchingBooking] = useState<boolean>(false);
    const [applicationReference, setApplicationReference] = useState<string>('');
    const [initialFormState, setInitialFormStateState] = useState<IFormValues>(defaultFormState);
    const [applicationEmail, setApplicationEmail] = useState<string>('');
    const [clearFiles, setClearFiles] = useState<boolean>(false);
    const [previousBookingConfirmationFiles, setPreviousBookingConfirmationFiles] = useState<string[]>([]);
    const [previousRefundReasonFiles, setPreviousRefundReasonFiles] = useState<string[]>([]);
    const [previousRefundReasonFilesAdditional, setPreviousRefundReasonFilesAdditional] = useState<string[]>([]);
    const [paymentResponseFields, setPaymentResponseFields] = useState<IPaymentField[]>([]);
    const [loadingBeneficiaryLabels, setLoadingBeneficiaryLabels] = useState<boolean>(false);
    const [bankAddress, setBankAddress] = useState<string>('');
    const [showBankModal, setShowBankModal] = useState<boolean>(false);
    const [regionDropdownItems, setRegionDropDownItems] = useState<IDropDownItem[]>([]);
    const [url, setUrl] = useState<string>('');
    const [bookingCounter, setBookingCounter] = useState<number>(0);
    const [validCurrency, setValidCurrency] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>('');
    const [isEvidenceRequired, setEvidenceRequired] = useState<boolean>(true);
    const [isBookingConfirmationRequired, setBookingConfirmationRequired] = useState<boolean>(true);
    const [applicationSubmitted, setApplicationSubmitted] = useState<boolean>(false);
    const [emailReminder, setEmailReminder] = useState<boolean>(false);
    const [createApplicationResponse, setCreateApplicationResponse] = useState<ICreateApplicationResponse>({} as ICreateApplicationResponse);
    const [showNegativeFeedbackModal, setShowNegativeFeedbackModal] = useState<boolean>(false);
    const [customerPaymentsEmail, setCustomerPaymentsEmail] = useState<string>('');
    const [paymentErrors, setPaymentErrors] = useState<IPaymentError[]>([]);
    const [bankBranchesDropdownItems, setBankBranchesDropDownItems] = useState<IDropDownItem[]>([]);
    const [countryCurrencyValue, setCountryCurrencyValue] = useState<string>('');
    const [member, setMember] = useState<IMember | null>(null);
    const [isOfflinePayment, setIsOfflinePayment] = useState<boolean>(false);
    const [isDecline, setIsDecline] = useState<boolean>(false);
    const [isDeclineOverride, setIsDeclineOverride] = useState<boolean>(false);
    const [isExtendedTerms, setIsExtendedTerms] = useState<boolean | null>(null);
    const [platformProductItems, setPlatformProductItems] = useState<IProductItem[]>([]);
    const [disableTicketChange, setDisableTicketChange] = useState<boolean>(false);
    const [allTicketsAssigned, setAllTicketsAssigned] = useState<boolean>(false);
    const [disableRefundAmount, setDisableRefundAmount] = useState<boolean>(false);
    const [disableTypeOfBooking, setDisableTypeOfBooking] = useState<boolean>(false);
    const [isRefundStatusDeclined, setIsRefundStatusDeclined] = useState<boolean>(false);
    const setLoadingShim = useSetRecoilState(loadingShimState);
    const resetLoadingShim = useResetRecoilState(loadingShimState);
    const setSavedForm = useSetRecoilState(SavedFormState);

    const isHidden = (page: Section, isBookingRef = false): boolean => (isBookingRef && selectedSection !== page) || (!isBookingRef && !bookingRefLookupAttempted || selectedSection !== page);
    const isHiddenCallback = useCallback((page: Section, isBookingRef?: boolean) => isHidden(page, isBookingRef), [selectedSection, bookingRefLookupAttempted, bookingMatched]);
    
    const tryPrePopulateLocalInfo = async (formProps: FormikProps<IFormValues>, countryCode: string, matchedCurrencyCode: string,
                                           matchedMemberId: number, bookingValue: number, isBookingMatched?: boolean): Promise<void> => {

        let currencyCode;

        try {
            if (countryCode.length > 0) {
                currencyCode = await getIsoCurrencyCode(countryCode);
                formProps.setFieldValue(nameof<IFormValues>(x => x.contactNumberCountryCode), countryCode);
                formProps.setFieldValue(nameof<IFormValues>(x => x.homeCountry), countryCode);
                if (!isBookingMatched) {
                    formProps.setFieldValue(nameof<IFormValues>(x => x.currency), currencyCode);
                }
            }
        } catch {
            console.log(t('common:messageErrorLocationDetails'));
        } finally {

            const validCountryCode = countryCode && countryCode.length === 2;
            const validCurrencyCode = currencyCode && currencyCode.length === 3;

            if (validCountryCode && validCurrencyCode) {

                updateBeneficiaryLabels(formProps, matchedMemberId, countryCode, currencyCode, bookingValue);
                await updateRegion(countryCode, currencyCode);

                if (matchedCurrencyCode !== null) {
                    await updateCountryCurrency(countryCode, matchedCurrencyCode);
                }
            }
        }
    }

    const setOutsideTimeLimitFields = (dateOfEvent: string, formProps: FormikProps<IFormValues>) => {
        
        if (dateOfEvent === null) {
            void formProps.setFieldValue(nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit), false);
            void formProps.setFieldValue(nameof<IFormValues>(x => x.reasonForNotificationOutsideTimeLimit), "");
            setDateOfEventBeyondTimeLimit(false);
            return;
        }
        
        let todayBeyondTimeLimit = new Date();
        todayBeyondTimeLimit.setDate(todayBeyondTimeLimit.getDate() - 61);
        if (Number(dateOfEvent) < Number(todayBeyondTimeLimit)) {
            setDateOfEventBeyondTimeLimit(true);
            void formProps.setFieldValue(nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit), true);
        } else {
            void formProps.setFieldValue(nameof<IFormValues>(x => x.unableToNotifyWithinTimeLimit), false);
            void formProps.setFieldValue(nameof<IFormValues>(x => x.reasonForNotificationOutsideTimeLimit), "");
            setDateOfEventBeyondTimeLimit(false);
        }
    }

    useEffect(() => {
        setDateOfEventLabel(t('form:labelEventTypeDate'))
        setDateOfEventTooltip(t('form:tooltipEventTypeDate'))
    }, [t]);

    const showBookingTypeFields = async (bookingType: number): Promise<void> => {
        setIsEventHidden(true);
        setIsFlightHidden(true);
        setIsHotelHidden(true);
        setDateOfEventLabel(t('form:labelEventTypeDate'));
        setDateOfEventTooltip(t('form:tooltipEventTypeDate'));

        //TODO: Enums and label text to be moved to db as part of language updates
        switch (bookingType) {
            case (EventType.Event):
                setIsEventHidden(false);
                setDateOfEventLabel(t('form:labelEventTypeDate'));
                setDateOfEventTooltip(t('form:tooltipEventTypeDate'));
                break;
            case (EventType.Tour):
                setDateOfEventLabel(t('form:labelEventTypeTour'));
                setDateOfEventTooltip(t('form:tooltipEventTypeTour'));
                break;
            case (EventType.Flight):
                setIsFlightHidden(false);
                setDateOfEventLabel(t('form:labelEventTypeFlight'));
                setDateOfEventTooltip(t('form:tooltipEventTypeFlight'));
                break;
            case (EventType.Accommodation):
                setIsHotelHidden(false);
                setDateOfEventLabel(t('form:labelEventTypeCheckIn'));
                setDateOfEventTooltip(t('form:tooltipEventTypeCheckIn'));
                break;
            case (EventType.Transfer):
                setDateOfEventLabel(t('form:labelEventTypeTransfer'));
                setDateOfEventTooltip(t('form:tooltipEventTypeTransfer'));
                break;
            case (EventType.Parking):
                setDateOfEventLabel(t('form:labelEventTypeParking'));
                setDateOfEventTooltip(t('form:tooltipEventTypeParking'));
                break;
            case (EventType.OtherTravel):
                setDateOfEventLabel(t('form:labelEventTypeOtherTravel'));
                setDateOfEventTooltip(t('form:tooltipEventTypeOtherTravel'));
                break;
        }
    }


    const cancel = (formProps: FormikProps<IFormValues>): void => {
        formProps.resetForm();
        setSelectedSection(Section.BookingAndContactInformation);
        setBookingRefLookupAttempted(false);
        setBookingMatched(false);
        setBookingCounter(0);
        setIsEventHidden(true);
        setIsFlightHidden(true);
        setIsHotelHidden(true);
        setDateOfEventLabel(t('form:labelEventTypeDate'));
        setDateOfEventTooltip(t('form:tooltipEventTypeDate'));
        setApplicationEmail('');
        setApplicationReference('');
        setRequiredEvidence([]);
        setPreviousBookingConfirmationFiles([]);
        setPreviousRefundReasonFiles([]);
        setPreviousRefundReasonFilesAdditional([]);
        setClearFiles(true);
        setInitialFormStateState(defaultFormState);
        setEvidenceRequired(true);
        setBookingConfirmationRequired(true);
        setIsDecline(false);
        setIsDeclineOverride(false);
        setIsOfflinePayment(false);
    }

    const fetchBookingData = async (formProps: FormikProps<IFormValues>, memberId?: string, urlPath?: string) => {

        const bookingReference = formProps.values.bookingReference.trim();

        if (bookingReference === '' || formProps.errors.bookingReference !== undefined) {
            return;
        }

        const getPosition = (): Promise<Position> => {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(resolve, reject)
            );
        }

        let countryCode = '';

        try {
            const position = await getPosition();
            countryCode = await getIsoCountryCode(position.coords.latitude, position.coords.longitude);
        }
        catch {
            console.log(t('common:messageErrorLocationDetails'));
        }

        const lookupUrl = `/api/lookup?bookingReference=${encodeURIComponent(bookingReference)}&memberId=${memberId ?? 0}&countryCode=${countryCode}`;
        await fetchBookingDataInternal(formProps, lookupUrl, false, urlPath, countryCode);
    };
    
    const fetchBookingBasedOnCustomerName = async (formProps: FormikProps<IFormValues>, urlPath?: string): Promise<boolean> => {
        
        const getNameOfErrorField = () => {
            if (formProps.errors.customerName !== undefined) {
                return nameof<IFormValues>(x => x.customerName);
            }
            if (formProps.errors.dateOfEvent !== undefined) {
                return nameof<IFormValues>(x => x.dateOfEvent);
            }
            if (formProps.errors.typeOfBooking !== undefined) {
                return nameof<IFormValues>(x => x.typeOfBooking);
            }
            return "";
        }
        
        formProps.validateField(nameof<IFormValues>(x => x.customerName));
        formProps.validateField(nameof<IFormValues>(x => x.dateOfEvent));
        formProps.validateField(nameof<IFormValues>(x => x.typeOfBooking));

        const isValid = formProps.errors.customerName === undefined && formProps.errors.dateOfEvent === undefined
            && formProps.errors.typeOfBooking === undefined;
        
        if (!isValid) {

            formProps.setFieldTouched(nameof<IFormValues>(x => x.customerName));
            formProps.setFieldTouched(nameof<IFormValues>(x => x.dateOfEvent));
            formProps.setFieldTouched(nameof<IFormValues>(x => x.typeOfBooking));
            const nameOfErrorField = getNameOfErrorField();

            if (nameOfErrorField !== "") {
                const selector = `[name="${nameOfErrorField}"]`;
                const errorElement = document.querySelector(selector) as HTMLInputElement;
                errorElement?.focus();
            }
            return false;
        }
        
        const customerName = formProps.values.customerName.trim();
        const eventDate = new Date(Date.parse(formProps.values.dateOfEvent));
        const lookupUrl = `/api/lookup/customer-name-and-event-date?customerName=${encodeURIComponent(customerName)}&eventDate=${eventDate.toDateString()}`;
        await fetchBookingDataInternal(formProps, lookupUrl, false, urlPath);
        formProps.setFieldValue(nameof<IFormValues>(x => x.customerNameLookup), customerName);
        return true;
    }
    
    const productItemsStatusCheck = (productData: IProductData[], productItems: IProductItem[], refundStatus: RefundStatus[]): boolean => {

        if (productData == null || productItems === null) {
            return false;
        }

        const platformProductIds = productData.map(x => x.product_id);
        const productItemIds = productItems.map(x => x.productId);

        return platformProductIds.length === productItemIds.length &&
            productItems.every(x => refundStatus.some(y => y === x.refundStatus));
    }

    const fetchBookingDataInternal = async (formProps: FormikProps<IFormValues>, lookupUrl: string, autoLoad: boolean, urlPath?: string, countryCode: string = "") => {
        const resetBookingReference = () => {
            formProps.setFieldTouched(nameof<IFormValues>(x => x.bookingReference), false, false);
        }

        setLoadingShim({
            open: true,
            message: t('common:messageLocatingBooking')
        })

        let bookingMatched = false;
        let bookingMatchedAndFound = false;
        let bookingMatchedAndFoundCompleted = false;
        let allTicketsAssigned = false;
        let currencyCode = "";
        let matchedMemberId = 0;
        let productItemsResponse: AxiosResponse<IProductItem[]>;
        let productsPresent = false;
        let transactionBookingValue = 0;
        
        try {
            const response = await axios.get<ILookupResultData>(lookupUrl);
            bookingMatched = response.status === 200 && response.data !== null;
            if (bookingMatched) {
                const result = response.data;
                currencyCode = result.purchase_currency_code;
                transactionBookingValue = result.transaction_total_value;
                
                // See if the transactionId matches any existing refund database record(s)
                if (!autoLoad) {
                    const emailReminderUrl = url === '' ? urlPath : url;
                    const findRefundRequestUrl = `/api/refund/find?bookingReference=${result.transaction_reference}&transactionId=${result.transaction_id}`
                    const findRefundResponses = await axios.get<IFindRefundResponse[]>(findRefundRequestUrl);
                    productsPresent = response.data?.products?.length > 0;
                    
                    if (productsPresent) {
                        // Check for related applications in progress
                        const encodedBookingReference = encodeURIComponent(result.transaction_reference);
                        const encodedTransactionId = encodeURIComponent(result.transaction_id);
                        const productItemsRequestUrl = `/api/refund/product-items?bookingReference=${encodedBookingReference}&transactionId=${encodedTransactionId}`;
                        productItemsResponse = await axios.get<IProductItem[]>(productItemsRequestUrl);
                        if (productItemsResponse.data) {
                            formProps.setFieldValue(nameof<IFormValues>(x => x.productItems), productItemsResponse.data);
                        }
                    }
                    
                    await Promise.all(findRefundResponses.data?.map(async (x) => {
                        if (x.emailAddress !== undefined && x.applicationReference !== undefined) {
                            switch (x.status) {
                                case RefundStatus.Approved:
                                case RefundStatus.Settled:
                                    if (!productsPresent && findRefundResponses.data.length > 0 &&
                                        findRefundResponses.data.every(x => x.status === RefundStatus.Approved
                                            || x.status === RefundStatus.Settled)) {
                                        bookingMatchedAndFoundCompleted = true;
                                        break;
                                    }

                                    const allProductsApprovedOrSettled = productsPresent &&
                                        productItemsStatusCheck(response.data?.products, productItemsResponse.data,
                                            [RefundStatus.Approved, RefundStatus.Settled]);

                                    if (allProductsApprovedOrSettled) {
                                        bookingMatchedAndFoundCompleted = true;
                                    }
                                    break;
                                case RefundStatus.Declined:
                                case RefundStatus.Retracted:
                                    setIsRefundStatusDeclined(true);
                                    if (x.refundReasonConfirm === OptionNo ||
                                        isAutoDeclineReason(x.refundReasonCategory, x.isExtendedTerms)) {
                                        bookingMatchedAndFound = true;
                                        await sendEmailReminder(x.emailAddress, emailReminderUrl, x.applicationReference);
                                        break;
                                    }
                                    
                                    if (!productsPresent && findRefundResponses.data.length > 0 && 
                                        findRefundResponses.data.every(x => x.status === RefundStatus.Declined
                                        || x.status === RefundStatus.Retracted)) {
                                            bookingMatchedAndFoundCompleted = true;
                                            setApplicationDeclined(true);
                                            break;
                                    }

                                    const allProductsDeclinedOrRetracted = productsPresent &&
                                        productItemsStatusCheck(response.data?.products, productItemsResponse.data,
                                            [RefundStatus.Declined, RefundStatus.Retracted]);

                                    if (allProductsDeclinedOrRetracted) {
                                        bookingMatchedAndFoundCompleted = true;
                                        setApplicationDeclined(true);
                                    }
                                    break;
                                default:
                                    bookingMatchedAndFound = true;

                                    if (response.data?.products?.length > 1) {
                                        await sendEmailReminder(x.emailAddress, emailReminderUrl, x.applicationReference);
                                        break;
                                    }

                                    await sendEmailReminder(x.emailAddress, emailReminderUrl, x.applicationReference);
                                    break;
                            }
                        }
                    }));
                }

                formProps.setFieldValue(nameof<IFormValues>(x => x.transactionId), result.transaction_id != 0 ? result.transaction_id : null);

                if (!autoLoad || productsPresent) {
                    let customerName = `${result.first_name} ${result.last_name}` ?? '';
                    formProps.setFieldValue(nameof<IFormValues>(x => x.customerName), customerName);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.bookingReference), result.transaction_reference);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.currency), currencyCode);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.originalCurrency), currencyCode);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), result.transaction_total_value);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.originalBookingValue), result.transaction_total_value);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.dateOfPurchase), result.purchase_date);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.dateOfEvent), result.transaction_date);

                    if (lookupUrl.includes('customer-name-and-event-date')) {
                        formProps.setFieldValue(nameof<IFormValues>(x => x.isEnhancedMatched), true);
                    }
                    
                    // Set member.
                    const member = await getMemberDetails(result.member_id, currencyCode, result.transaction_total_value);
                    if (member !== null) {
                        const disableTypeOfBooking = member.typeOfBooking !== EventType.None;
                        setMember(member);
                        setBookingConfirmationRequired(member.bookingConfirmationRequired);
                        setEvidenceRequired(member.evidenceRequired);
                        setIsOfflinePayment(member.isOfflinePayment);
                        setIsExtendedTerms(member.extendedTerms);
                        setDisableTypeOfBooking(disableTypeOfBooking)
                        await showBookingTypeFields(member.typeOfBooking);
                        const productCount = response.data?.products?.length === 0 ? 1 : response.data?.products?.length;
                        checkRefundAmountForDisabling(formProps, member.partialRefundEnabled, result.transaction_total_value, productCount);
                        formProps.setFieldValue(nameof<IFormValues>(x => x.typeOfBooking), member.typeOfBooking);
                    }
                    
                    // Check if all tickets have been assigned
                    if (response.data?.products.length > 0 
                        && response.data?.products.length === productItemsResponse?.data.length
                            && productItemsResponse.data.every(x => x.disabled)) 
                    {
                        allTicketsAssigned = true;
                    }
                }
                
                if (!bookingMatchedAndFound && !bookingMatchedAndFoundCompleted &&
                    response.data?.products?.length > 0) {
                    const platformProductItems = response.data?.products.map(x => ({
                        productId: x.product_id,
                        reference: x.product_reference,
                        description: x.product_description,
                        currencyCode: currencyCode,
                        totalValue: x.total_value
                    } as IProductItem)) ?? [];
                    setPlatformProductItems(platformProductItems);
                }
                
                matchedMemberId = result.member_id;
                formProps.setFieldValue(nameof<IFormValues>(x => x.memberId), +(result.member_id != 0 ? result.member_id : null));
                formProps.setFieldValue(nameof<IFormValues>(x => x.isSold), result.is_sold);
                formProps.setFieldValue(nameof<IFormValues>(x => x.isCancelled), result.is_cancelled);
                formProps.setFieldValue(nameof<IFormValues>(x => x.isMatched), bookingMatched);
                formProps.setFieldValue(nameof<IFormValues>(x => x.refundedCount), Object.keys(result.refunds)?.length);
            } else {
                resetBookingReference();
                setBookingConfirmationRequired(false);
            }
        } catch (e) {
            resetBookingReference();
        } finally {
            setLoadingShim({
                open: false,
                message: ''
            })
            setBookingMatchedAndFound(bookingMatchedAndFound);
            if (bookingMatchedAndFound) {
                return;
            }
            setBookingMatchedAndFoundCompleted(bookingMatchedAndFoundCompleted);
            if (bookingMatchedAndFoundCompleted) {
                await getCustomerPaymentsEmailAddress();
                return;
            }
            setAllTicketsAssigned(allTicketsAssigned);
            if (allTicketsAssigned) {
                return;
            }
            setIsFetchingBooking(false);
            setBookingMatched(bookingMatched);
            if (!bookingMatched && !autoLoad) {
                setBookingCounter(bookingCounter + 1);
            } else {
                setBookingCounter(2);
            }
            setBookingRefLookupAttempted(true);
            if (!autoLoad) {
                await tryPrePopulateLocalInfo(formProps, countryCode, currencyCode, matchedMemberId, transactionBookingValue, bookingMatched);
            }

            if (bookingMatched || autoLoad) {
                const ipAddress = await getIpAddress();
                formProps.setFieldValue(nameof<IFormValues>(x => x.ipAddress), ipAddress?.ip);
            }
        }
    }

    const updateRequiredEvidence = async (reasonId: number): Promise<void> => {

        let requiredEvidenceArray: IEvidenceReason[] = [];

        try {
            const response = await axios.get<IEvidenceReason[]>(`/api/referencedata/evidence?reasonId=${reasonId}`);
            if (response.status === 200) {
                requiredEvidenceArray = response.data;
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRequiredEvidence(requiredEvidenceArray);
        }
    }

    const getCustomerPaymentsEmailAddress = async () => {
        try {
            const response = await axios.get<string>(`/api/referencedata/paymentsemailaddress`);
            setCustomerPaymentsEmail(response.data);
        } catch {
            setCustomerPaymentsEmail('payments@refundable.me');
        }
    }

    const updateRegion = async (homeCountryCode: string, currencyCode: string, resetRegions = true) => {

        let regionsArray: any = [];

        if (!resetRegions && regionDropdownItems?.length > 0) {
            return regionDropdownItems;
        }

        const validHomeCountryCode = homeCountryCode && homeCountryCode.length === 2;
        const validCurrencyCode = currencyCode && currencyCode.length === 3;

        if (!validHomeCountryCode || !validCurrencyCode) {
            setRegionDropDownItems(regionsArray);
            return;
        }

        try {
            const response = await axios.get<IDropDownItem[]>(`/api/paymentdetails/regions?countryCode=${homeCountryCode}&currencyCode=${currencyCode}`);
            if (response.status === 200) {
                regionsArray = response.data.map(region => {
                    return { key: region.key, value: region.value }
                });
            }
        } catch (e) {
            console.log(e);
            setValidCurrency(false)
        } finally {
            setRegionDropDownItems(regionsArray);
        }
    }
    
    const updateBeneficiaryLabels = (formProps: FormikProps<IFormValues>, memberId: number, countryCode: string, currencyCode: string, targetAmount: number, showLoader?: boolean, paymentType?: string): void => {
        
        const validCountryCode = countryCode && countryCode.length === 2;
        const validCurrencyCode = currencyCode && currencyCode.length === 3;
        const validPaymentType = (paymentType === undefined || null) ? "" : paymentType;

        if (!(validCountryCode && validCurrencyCode)) {
            setPaymentResponseFields([]);
            setValidCurrency(false);
            return;
        }
        
        const requestUrlOfflineCurrency = `/api/paymentdetails/offlinecurrency?countryCode=${countryCode}&memberId=${memberId}`
        axios.get(requestUrlOfflineCurrency)
            .then(response => {
                if (response.status === 200) {
                    setIsOfflinePayment(response.data);
                    if (response.data === true) {
                        return;
                    }
                }
            }).catch(error => {
                setIsOfflinePayment(false);
            })
        
        if (!!showLoader) {
            setLoadingShim({
                open: true,
                message: t('common:messageLoadingBank')
            })
        }

        setPaymentResponseFields([]);
        setLoadingBeneficiaryLabels(true)

        const requestUrl = `/api/paymentdetails/RemittanceLabels?beneficiaryCountryCode=${countryCode}&currencyCode=${currencyCode}&targetAmount=${targetAmount}&paymentType=${validPaymentType}`;
        void axios.get<IPaymentField[]>(requestUrl)
            .then(response => {
                if (response.status === 200) {
                    setValidCurrency(true)
                    setPaymentResponseFields(response.data || [])

                    const valuesAllowed = response.data.map(x => x?.valuesAllowed).reduce(function (x, y) { return y })
                    formProps.setFieldValue(nameof<IFormValues>(x => x.paymentType),
                        valuesAllowed?.length === 1 ? valuesAllowed.find(x => x.key).key : validPaymentType)
                }
            }).catch(error => {
                error.response.status === 400 ?
                    setValidCurrency(false) :
                    toast(t('common:messageFailCurrencyLookup'), { type: "error" });
            })
            .finally(() => {
                setLoadingBeneficiaryLabels(false)
                setLoadingShim({
                    open: false,
                    message: ''
                })
            });
    }

    const changeCurrency = async (formProps: FormikProps<IFormValues>, selectedCurrency: string) => {

        if (selectedCurrency?.length !== 3)
        {
            return;
        }
        
        if (formProps.values.originalCurrency == selectedCurrency) {
            formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), formProps.values.originalBookingValue);
            formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), formProps.values.originalBookingValue);
            return;
        }

        const SetDefaults = () =>  {
            formProps.setFieldValue(nameof<IFormValues>(x => x.currency), formProps.values.originalCurrency);
            formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), formProps.values.originalBookingValue);
            formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), formProps.values.originalBookingValue);
        }

        try {

            if (formProps.values.productItems?.length > 0) {
                
                const bookingValueResponse = await axios.get<number>(`/api/refund/bookingvalueinrequestedcurrency?sourceCurrency=${formProps.values.originalCurrency}&bookingValue=${formProps.values.originalBookingValue}&requestedCurrency=${selectedCurrency}`);
                if (bookingValueResponse.status === 200 && bookingValueResponse.data > 0) {
                    formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), bookingValueResponse.data);
                }

                const refundAmount = formProps.values.productItems
                    .map(x => x.totalValue).reduce((a, b) => a + b, 0);

                const refundAmountResponse = await axios.get<number>(`/api/refund/bookingvalueinrequestedcurrency?sourceCurrency=${formProps.values.originalCurrency}&bookingValue=${refundAmount}&requestedCurrency=${selectedCurrency}`);
                if (refundAmountResponse.status === 200 && refundAmountResponse.data > 0) {
                    formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), refundAmountResponse.data);
                }
                
                return;
            }
            
            // Get the booking value in the requested currency
            const response = await axios.get<number>(`/api/refund/bookingvalueinrequestedcurrency?sourceCurrency=${formProps.values.originalCurrency}&bookingValue=${formProps.values.originalBookingValue}&requestedCurrency=${selectedCurrency}`);
            if (response.status === 200 && response.data > 0) {
                formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), response.data);
                formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), response.data);
            } else {
                SetDefaults();
            }
        } catch {
            toast(t('common:messageFailCurrencyLookup'), { type: "error" });
            SetDefaults();
        }
    }

    const saveForm = (formProps: FormikProps<IFormValues>) => {
        formProps.values.url = url;
        formProps.values.language = language;
        formProps.values.bankAccountType = formProps.values.bankAccountType.toString();
        formProps.values.beneficiaryRegion = formProps.values.beneficiaryRegion.toString();
        formProps.values.bankNameSelect = formProps.values.bankNameSelect.toString();
        formProps.values.branchCodeSelect = formProps.values.branchCodeSelect.toString();
        formProps.values.remittanceLine3Select = formProps.values.remittanceLine3Select.toString();

        setLoadingShim({
            open: true,
            message: t('common:messageSavingApplication')
        })

        void axios.post('/api/refund/save', formProps.values)
            .then(response => {
                toast(t('common:messageSavedApplication'), { type: "success" });
                setSavedForm(true);
                const applicationReference = response?.data.toString();
                setApplicationReference(applicationReference);
                formProps.setFieldValue(nameof<IFormValues>(x => x.applicationReference), applicationReference);
            })
            .catch(() => toast(t('common:messageFailSavedApplication'), { type: "error" }))
            .finally(() => {
                setLoadingShim({
                    open: false,
                    message: ''
                });
            });
    }

    const bankAddressSubmit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {

        const validationResult = await helpers.validateForm(values)

        if (Object.keys(validationResult).length) {
            return;
        }

        values.applicationReference = values.applicationReference.toString();
        values.bankAccountType = values.bankAccountType.toString();
        values.beneficiaryRegion = values.beneficiaryRegion.toString();
        values.bankNameSelect = values.bankNameSelect.toString();
        values.branchCodeSelect = values.branchCodeSelect.toString();
        values.remittanceLine3Select = values.remittanceLine3Select.toString();
        values.language = language;
        values.contactNumberCountryValue = values.contactNumberCountryValue.toString();
        setSelectedSection(Section.PaymentInformation);

        const requestUrl = '/api/paymentdetails/bankaddress';
        setLoadingShim({
            open: true,
            message: t('common:messageLoadingBank')
        });
        void axios.post(requestUrl, values).then(response => {
            if (response.status === 200) {
                setShowBankModal(true);
                setBankAddress(response.data.bankAddress);
                values.url = url;
            }
            helpers.setSubmitting(false);
        }).catch(({ response }) => {
            toast(t('common:messageFailFormSubmission'), { type: "error" });
            helpers.setSubmitting(false);

            if (response.data === undefined) {
                return;
            }

        }).finally(() => {
            setLoadingShim({
                open: false,
                message: ''
            })
        });
    }

    const handleConfirm = (formProps: FormikProps<IFormValues>) => {

        let errors = 0;

        if (formProps.values.bankAccountNumber?.length > 0 && formProps.values.bankAccountNumber?.trim() !== formProps.values.bankAccountNumberConfirm?.trim()) {
            if (formProps.values.bankAccountNumber?.length > 0 && formProps.values.bankAccountNumberConfirm?.length == 0) {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.bankAccountNumberConfirm)).setError(t('payment:modalValidationPleaseConfirm'));
                errors++;
            } else {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.bankAccountNumberConfirm)).setError(t('payment:modalValidationMustMatch'));
                errors++;
            }
        }

        if (formProps.values.sortCode?.length > 0 && formProps.values.sortCode?.trim() !== formProps.values.sortCodeConfirm?.trim()) {
            if (formProps.values.sortCode?.length > 0 && formProps.values.sortCodeConfirm?.length == 0) {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.sortCodeConfirm)).setError(t('payment:modalValidationPleaseConfirm'));
                errors++;
            } else {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.sortCodeConfirm)).setError(t('payment:modalValidationMustMatch'));
                errors++;
            }
        }

        if (formProps.values.bankSwiftBic?.length > 0 && formProps.values.bankSwiftBic?.trim() !== formProps.values.bankSwiftBicConfirm?.trim()) {
            if (formProps.values.bankSwiftBic?.length > 0 && formProps.values.bankSwiftBicConfirm?.length == 0) {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.bankSwiftBicConfirm)).setError(t('payment:modalValidationPleaseConfirm'));
                errors++;
            } else {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.bankSwiftBicConfirm)).setError(t('payment:modalValidationMustMatch'));
                errors++;
            }
        }

        if (formProps.values.cardNumber?.length > 0 && formProps.values.cardNumber?.trim() !== formProps.values.cardNumberConfirm?.trim()) {
            if (formProps.values.cardNumber?.length > 0 && formProps.values.cardNumberConfirm?.length == 0) {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.cardNumberConfirm)).setError(t('payment:modalValidationPleaseConfirm'));
                errors++;
            } else {
                formProps.getFieldHelpers(nameof<IFormValues>(x => x.cardNumberConfirm)).setError(t('payment:modalValidationMustMatch'));
                errors++;
            }
        }

        if (errors == 0) {
            setShowBankModal(false);

            setLoadingShim({
                open: true,
                message: t('common:messageSubmittingApplication')
            });

            void axios.post<ICreateApplicationResponse>('/api/refund', formProps.values)
                .then(response => {
                    if (response.status === 200) {
                        setApplicationSubmitted(true);
                        setCreateApplicationResponse(response.data ?? createApplicationResponse);
                        setSavedForm(true);
                    }
                }).catch(({ response }) => {
                    setPaymentErrors(response.data.errors)
                    setApplicationReference(response.data.applicationReference);
                    formProps.setFieldValue(nameof<IFormValues>(x => x.applicationReference), response.data.applicationReference);
                    toast(t('common:messageFailFormSubmission'), { type: "error" });

                }).finally(() => {
                    setLoadingShim({
                        open: false,
                        message: ''
                    });
                });
        }
    }

    const offlinePaymentSubmit = async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        
        values.beneficiaryRegion = values.beneficiaryRegion.toString();
        values.bankNameSelect = values.bankNameSelect.toString();
        values.language = language;
        values.contactNumberCountryValue = values.contactNumberCountryValue.toString();

        setLoadingShim({
            open: true,
            message: t('common:messageSubmittingApplication')
        });

        void axios.post<ICreateApplicationResponse>('/api/refund', values)
            .then(response => {
                if (response.status === 200) {
                    setApplicationSubmitted(true);
                    setCreateApplicationResponse(response.data ?? createApplicationResponse);
                }
            }).catch(({ response }) => {
                setApplicationReference(response.data.applicationReference);
                toast(t('common:messageFailFormSubmission'), { type: "error" });
            }).finally(() => {
                resetLoadingShim();
            });
    }

    const saveFeedback = async (createFeedbackRequest: ICreateFeedbackRequest): Promise<void> => {

        try {
            const response = await axios.post('/api/refund/savefeedback', createFeedbackRequest);
            if (response.status === 200) {
                if (!createFeedbackRequest.isAutoApproved || 
                    (createFeedbackRequest.feedback !== nameof<IFeedbackStatus>(x => x.feedbackExcellent)
                        && createFeedbackRequest.feedback !== nameof<IFeedbackStatus>(x => x.feedbackGreat))) {
                    toast(t('confirmation:messageFeedbackSubmitted'), { type: "info" });
                }
            }
        } catch {
            toast(t('common:messageFailFormSubmission'), { type: "error" });
        }
    }

    const onApplicationEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplicationEmail(event.currentTarget.value);
    }

    const sendEmailReminder = async (email: string, url: string, applicationReference: string = null) => {

        if (!isNullOrWhitespace(email)) {
            setLoadingShim({
                open: true,
                message: t('common:messageSendingEmailReminder')
            });
            await axios.post('/api/refund/emailreminder', { email: email, url: url, applicationReference: applicationReference })
                .finally(() => {
                    setLoadingShim({
                        open: false,
                        message: ''
                    })
                    setEmailReminder(true);
                });
        }
    }

    const updateReasonDeclineStatus = async (formProps: FormikProps<IFormValues>, reasonId: number): Promise<void> => {

        try {
            
            setRequiredEvidence([]);
            setIsDeclineOverride(false);
            
            // Set as non-decline based on reason and reason approval limit?
            const overrideDecline = await isOverrideDecline(reasonId, formProps.values.memberId ?? 0, formProps.values.currency, formProps.values.bookingValue);

            if (overrideDecline || formProps.values.isBankDetailsRequested) {
                setIsDecline(false);
                setIsDeclineOverride(true);
                UpdateRefundAmount(formProps, formProps.values.bookingValue);
                formProps.setFieldValue(nameof<IFormValues>(x => x.numberOfTicketsToRefund), 1);
                return;
            }

            if (isExtendedTerms === false && refundBlanketCovidDeclineReasons.some(x => x.id === reasonId)) {
                setIsDecline(true);
                return;
            }

            setIsDecline(refundBlanketDeclineReasons.some(x => x.id === reasonId));
            
        } catch (e) {
            console.log(e);
        } finally {
            formProps.setFieldValue(nameof<IFormValues>(x => x.refundReasonConfirm), 0);
        }
    }
    
    const updateReasonDeclineStatusWithConfirmId = async (refundReasonConfirmId: number, reasonId: number): Promise<void> => {
        
        if (isAutoDeclineReason(reasonId, isExtendedTerms)) {
            setIsDecline(refundReasonConfirmId === OptionYes && !isDeclineOverride);
            setRequiredEvidence([]);
            return;
        }

        setIsDecline(refundReasonConfirmId === OptionNo);

        if (refundReasonConfirmId !== OptionYes) {
            setRequiredEvidence([]);
        }
    }

    const processMemberLogic = async (formProps: FormikProps<IFormValues>, refundReasonId: number, memberId: number,
                                      currencyCode: string, bookingAmount: number, isBankDetailsRequested: boolean): Promise<void> =>
    {
        if (memberId === 0) {
            setIsDecline(refundBlanketDeclineReasons.some(x => x.id === refundReasonId));
            return;
        }
        
        try {

            // Set as non-decline based on reason and reason approval limit?
            const overrideDecline = await isOverrideDecline(refundReasonId, memberId, currencyCode, bookingAmount);

            if (overrideDecline || isBankDetailsRequested) {
                setIsDecline(false);
                setIsDeclineOverride(true);
                UpdateRefundAmount(formProps, bookingAmount);
                formProps.setFieldValue(nameof<IFormValues>(x => x.numberOfTicketsToRefund), 1);
                return;
            }

            const member = await getMemberDetails(memberId, currencyCode, bookingAmount);
            if (member === null) {
                return;
            }

            setMember(member);
            setBookingConfirmationRequired(member.bookingConfirmationRequired);
            setEvidenceRequired(member.evidenceRequired);
            setIsOfflinePayment(member.isOfflinePayment);
            setIsExtendedTerms(member.extendedTerms);

            const productCount = formProps.values?.productItems?.length === 0 ? 1: formProps.values?.productItems?.length;
            checkRefundAmountForDisabling(formProps, member.partialRefundEnabled, bookingAmount, productCount);
            
            if (member.extendedTerms !== null && !member.extendedTerms
                && refundBlanketCovidDeclineReasons.some(x => x.id === refundReasonId)) {
                setIsDecline(true);
                return;
            }

            setIsDecline(refundBlanketDeclineReasons.some(x => x.id === refundReasonId));
           
        } catch {
            return;
        }
    }

    const getMemberDetails = async (memberId: number, currencyCode: string, bookingAmount: number): Promise<IMember> => {
        try {
            const response = await axios.get<IMember>(`/api/member/member?memberId=${memberId}&currencyCode=${currencyCode}&bookingAmount=${bookingAmount}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch {
            return null;
        }
        return null;
    }

    const isOverrideDecline = async (refundReasonId: number, memberId: number, currencyCode: string, bookingAmount: number): Promise<boolean> => {
        try {
            const response = await axios.get<boolean>(`/api/refund/reason-override?reasonId=${refundReasonId}&memberId=${memberId}&bookingAmount=${bookingAmount}&currencyCode=${currencyCode}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch {
            return false;
        }
        return false;
    }

    const loadApplication = (formProps: FormikProps<IFormValues>, page: Section, urlPath: string) => {

        setLoadingShim({
            open: true,
            message: t('common:messageLocatingBooking')
        })

        axios.get<IFormValues>(`/api/refund/load?applicationReference=${formProps.values.applicationReference}&applicationEmail=${formProps.values.emailAddress}`)
            .then(async response => {

                if (response.status !== 200) {
                    return;
                }

                await i18n.changeLanguage(response.data.language);
                setLanguage(response.data.language);

                const relatedApplications = response.data.productItems?.filter(x => x.hasRelatedApplication) ?? [];

                switch (response.data.status) {
                    case RefundStatus.Approved:
                    case RefundStatus.Settled:
                        if (relatedApplications.length > 0 && relatedApplications.every(
                            x => x.refundStatus === RefundStatus.Approved || x.refundStatus === RefundStatus.Settled)) {
                            setBookingMatchedAndFoundCompleted(true);
                            await getCustomerPaymentsEmailAddress();
                            break;
                        }
                        setBookingMatchedAndFoundCompleted(true);
                        await getCustomerPaymentsEmailAddress();
                        break;
                    case RefundStatus.Declined:
                    case RefundStatus.Retracted:
                        if (relatedApplications.length > 0
                            && relatedApplications.every(
                            x => x.refundStatus === RefundStatus.Declined || x.refundStatus === RefundStatus.Retracted)) {
                            setBookingMatchedAndFoundCompleted(true);
                            setApplicationDeclined(true);
                            break;
                        }
                        if (response.data.refundReasonConfirm === OptionNo ||
                            isAutoDeclineReason(response.data.refundReasonCategory, response.data.isExtendedTerms)) {
                            setIsExtendedTerms(response.data.isExtendedTerms);
                            await processLoad(response.data);
                            break;
                        }
                        setBookingMatchedAndFoundCompleted(true);
                        setApplicationDeclined(true);
                        break;
                    case RefundStatus.New:
                    case RefundStatus.Open:
                    case RefundStatus.Referred:
                    case RefundStatus.Lapsed:
                    case RefundStatus.Expired:
                    case RefundStatus.Processing:
                        if (response.data.productItems?.length > 0) {
                            setDisableTicketChange(true);
                        }
                        await processLoad(response.data);
                        break;
                    default:
                        await processLoad(response.data);
                }
            }).catch(error => {
                setApplicationReference('');
                error.response.status === 404 ?
                    toast(t('common:messageApplicationCannotBeFound'), { type: "info" }) :
                    toast(t('common:messageApplicationRetrievalError'), { type: "error" });
            }).finally(() => {
                formProps.values.applicationReference = '';
                formProps.values.emailAddress = '';
                setLoadingShim({
                    open: false,
                    message: ''
                })
            });
        
        const processLoad = async (formValues: IFormValues) =>
        {
            setInitialFormStateState(formValues);
            const lookupUrl = `/api/lookup?bookingReference=${encodeURIComponent(formValues.bookingReference)}&memberId=${formValues.memberId?.toString() ?? 0}`;
            await fetchBookingDataInternal(formProps, lookupUrl, true);
            await processMemberLogic(formProps, formValues.refundReasonCategory, formValues.memberId ?? 0, formValues.currency, formValues.bookingValue, formValues.isBankDetailsRequested);
            setPreviousRefundReasonFiles(formValues.refundReasonFiles.map(x => x.fileName));
            setPreviousRefundReasonFilesAdditional(formValues.refundReasonFilesSecondary.map(x => x.fileName));
            setPreviousBookingConfirmationFiles(formValues.bookingConfirmationFiles.map(x => x.fileName));
            await showBookingTypeFields(formValues.typeOfBooking);
            setSelectedSection(page);
            setOutsideTimeLimitFields(formValues.dateOfEvent, formProps);
            setApplicationReference(formProps.values.applicationReference);

            formProps.setFieldValue(nameof<IFormValues>(x => x.applicationReference), formValues.applicationReference);
            formProps.setFieldValue(nameof<IFormValues>(x => x.currency), formValues.currency);
            formProps.setFieldValue(nameof<IFormValues>(x => x.originalCurrency), formValues.currency);
            formProps.setFieldValue(nameof<IFormValues>(x => x.bookingValue), formValues.bookingValue);
            formProps.setFieldValue(nameof<IFormValues>(x => x.originalBookingValue), formValues.bookingValue);

            formProps.setFieldValue(nameof<IFormValues>(x => x.confirmEmailAddress), formValues.emailAddress);
            formProps.setFieldValue(nameof<IFormValues>(x => x.bankAccountNumberConfirm), '');
            formProps.setFieldValue(nameof<IFormValues>(x => x.cardNumberConfirm), '');
            formProps.setFieldValue(nameof<IFormValues>(x => x.sortCodeConfirm), '');
            formProps.setFieldValue(nameof<IFormValues>(x => x.bankSwiftBicConfirm), '');
            formProps.setFieldValue(nameof<IFormValues>(x => x.hasAgreedTermsAndConditions), false);
            formProps.setFieldValue(nameof<IFormValues>(x => x.isBankDetailsRequested), formValues.isBankDetailsRequested);

            if (formValues.isBankDetailsRequested) {
                UpdateRefundAmount(formProps, formValues.bookingValue);
                formProps.setFieldValue(nameof<IFormValues>(x => x.numberOfTicketsToRefund), 1);
                formProps.setFieldValue(nameof<IFormValues>(x => x.isBankDetailsRequested), true);
                defaultQuestionSetFieldValues(formProps);
                setBookingConfirmationRequired(false);
                setEvidenceRequired(false);
            }

            if (formValues.isRefundAmountDisabled) {
                setDisableRefundAmount(true);
            }

            if (formValues.isTypeOfBookingDisabled) {
                setDisableTypeOfBooking(true);
            }

            if (formValues.contactNumberCountryCode !== '') {
                await setCountryCodeDialingCodeValue(formProps, formValues.contactNumberCountryCode);
            }

            if (formValues.homeCountry !== '' && formValues.currency !== '') {
                updateBeneficiaryLabels(formProps, formValues.memberId ?? 0, formValues.homeCountry, formValues.currency, formValues.bookingValue, null, formValues.paymentType);
            }

            if (formValues.homeCountry != '') {
                await updateRegion(formValues.homeCountry, formValues.currency);
            }

            await updateCountryCurrency(formValues.homeCountry, formValues.currency);
        }
    }

    const setCountryCodeDialingCodeValue = async (formProps: FormikProps<IFormValues>, diallingCode: string) => {

        try {
            const response = await axios.get<IDropDownItem[]>(`/api/referencedata/diallingCodes?diallingCode=${diallingCode}`);
            if (response.status === 200) {
                var dropDownItem = response.data.map(region => {
                    return { key: region.key, value: region.value }
                });

                if (dropDownItem !== null && dropDownItem.length == 1) {
                    formProps.setFieldValue(nameof<IFormValues>(x => x.contactNumberCountryValue), dropDownItem[0].value);
                }
            }
        } catch (e) {
            console.log(e);
            formProps.setFieldValue(nameof<IFormValues>(x => x.contactNumberCountryValue), "")
        }
    }

    const updateBankBranches = async (currencyCode: string, countryCode: string, bankCode: string) => {

        let bankBranchesArray: any = [];

        const validCountryCode = countryCode && countryCode.length === 2;
        const validCurrencyCode = currencyCode && currencyCode.length === 3;

        if (!validCountryCode || !validCurrencyCode) {
            setBankBranchesDropDownItems(bankBranchesArray);
            return;
        }

        try {
            const response = await axios.get<IDropDownItem[]>(`/api/paymentdetails/bankbranches?currencyCode=${currencyCode}&countryCode=${countryCode}&bankCode=${bankCode}`);
            if (response.status === 200) {
                bankBranchesArray = response.data.map(region => {
                    return { key: region.key, value: region.value }
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setBankBranchesDropDownItems(bankBranchesArray);
        }
    }

    const updateCountryCurrency = async (countryCode: string, currencyCode: string) => {

        setCountryCurrencyValue('');
        const validCountryCode = countryCode && countryCode.length === 2;
        const validCurrencyCode = currencyCode && currencyCode.length === 3;

        if (!validCountryCode || !validCurrencyCode) {
            return;
        }

        try {
            const response = await axios.get(`/api/paymentdetails/countrycurrency?countryCode=${countryCode}&currencyCode=${currencyCode}`);
            if (response.status === 200) {
                setCountryCurrencyValue(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const hidePaymentInformation = (formProps: FormikProps<IFormValues>) => {
        
        if (isOfflinePayment) {
            return true;
        }

        if (isDeclineOverride && formProps.values.refundReasonConfirm !== OptionYes) {
            return true;
        }
        
        if (isDeclineOverride) {
            return false;
        }

        if (formProps.values.refundReasonCategory === 0) {
            return true;
        }
        
        if (isAutoDeclineReason(formProps.values.refundReasonCategory, isExtendedTerms) &&
            formProps.values.refundReasonConfirm === OptionYes) {
            return true;
        }

        return formProps.values.refundReasonCategory !== 0 && formProps.values.refundReasonConfirm !== OptionYes;
    }
    
    const checkRefundAmountForDisabling = (formProps: FormikProps<IFormValues>, partialRefundEnabled: boolean,
                                           bookingAmount: number, numberOfProducts: number) => {
        
        if (!partialRefundEnabled && numberOfProducts === 1) {
                formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), bookingAmount);
                setDisableRefundAmount(true);
        }
    }

    const UpdateRefundAmount = (formProps: FormikProps<IFormValues>, bookingValue: number) => {
        if (formProps.values?.productItems?.length > 0) {
            formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), formProps.values.totalRefundAmount);
            return;
        }
        formProps.setFieldValue(nameof<IFormValues>(x => x.totalRefundAmount), bookingValue);
    }

    // TODO: Custom hook to be added for logic cleanup.
    const handleSectionChange = (formProps: FormikProps<IFormValues>, page: Section) => () => {
        setOutsideTimeLimitFields(formProps.values.dateOfEvent, formProps);
        setSelectedSection(page);
    }
    const getNextSection = (formProps: FormikProps<IFormValues>): Section => {

        setOutsideTimeLimitFields(formProps.values.dateOfEvent, formProps);

        if (selectedSection === Section.BookingAndContactInformation && !formProps.values.isBankDetailsRequested) {
            return Section.RefundRequest;
        }
        return Section.PaymentInformation;
    }
    const getPreviousSection = (formProps: FormikProps<IFormValues>): Section => 
        selectedSection === Section.PaymentInformation && !formProps.values.isBankDetailsRequested
            ? Section.RefundRequest 
            : Section.BookingAndContactInformation;
    const setDisplayUploadModal = (value: boolean) => setDisplayUploadModalState(value);
    const setSelectedSection = (value: Section) => setSelectedSectionState(value);

    return (
        <Context.Provider value={{
            bookingRefLookupAttempted,
            bookingMatched,
            bookingMatchedAndFound,
            bookingMatchedAndFoundCompleted,
            applicationDeclined,
            selectedSection,
            isEventHidden,
            isFlightHidden,
            isHotelHidden,
            isEventDateBeyondTimeLimit,
            dateOfEventLabel,
            dateOfEventTooltip,
            requiredEvidence,
            displayUploadModal,
            remittanceLine3,
            remittanceLine4,
            isFetchingBooking,
            showBookingTypeFields,
            cancel,
            fetchBookingData,
            fetchBookingBasedOnCustomerName,
            updateRequiredEvidence,
            updateReasonDeclineStatus,
            updateReasonDeclineStatusWithConfirmId,
            handleSectionChange,
            getPreviousSection,
            getNextSection,
            updateBeneficiaryLabels,
            changeCurrency,
            loadingBeneficiaryLabels,
            isHiddenCallback,
            setDisplayUploadModal,
            setSelectedSection,
            saveForm,
            loadApplication,
            sendEmailReminder,
            initialFormState,
            applicationReference,
            applicationEmail,
            onApplicationEmailChange,
            previousBookingConfirmationFiles,
            previousRefundReasonFiles,
            previousRefundReasonFilesAdditional,
            paymentResponseFields,
            bankAddressSubmit,
            bankAddress,
            showBankModal,
            setShowBankModal,
            regionDropdownItems,
            updateRegion,
            setUrl,
            clearFiles,
            setClearFiles,
            bookingCounter,
            setBookingCounter,
            validCurrency,
            setLanguage,
            language,
            isEvidenceRequired,
            isBookingConfirmationRequired,
            handleConfirm,
            applicationSubmitted,
            emailReminder,
            createApplicationResponse,
            showNegativeFeedbackModal,
            setShowNegativeFeedbackModal,
            saveFeedback,
            customerPaymentsEmail,
            paymentErrors,
            setCountryCodeDialingCodeValue,
            updateBankBranches,
            setBankBranchesDropDownItems,
            bankBranchesDropdownItems,
            countryCurrencyValue,
            updateCountryCurrency,
            member,
            offlinePaymentSubmit,
            isOfflinePayment,
            isDecline,
            isDeclineOverride,
            isExtendedTerms,
            hidePaymentInformation,
            platformProductItems,
            disableTicketChange,
            allTicketsAssigned,
            disableRefundAmount,
            disableTypeOfBooking,
            isRefundStatusDeclined
        }}>
            {children}
        </Context.Provider>
    )
}
export { FormProvider, useFormContext }