export default {
    messageQuestionService: "Αυτή η υπηρεσία προορίζεται μόνο για χρήση εάν αντιμετωπίζετε πρόβλημα με τη συμπλήρωση της φόρμας αίτησης, δεν μπορούμε να απαντήσουμε σε άλλες ερωτήσεις εδώ.",
    labelName: "Ονομα",
    labelReference: "Αναφορά κράτησης",
    labelPhone: "Τηλεφωνικό νούμερο",
    labelEmail: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    labelQuery: "Η ερώτησή σου",
    labelAskQuestion: "Στείλτε μας μια ερώτηση εδώ...",
    buttonOpenModal: "Κάνε μια ερώτηση",
    title: "Κάνε μια ερώτηση",
    modalTitle: "4. Αντιμετωπίζετε τεχνικό πρόβλημα με τη φόρμα;",
    toastSuccess: "Λάβαμε την ερώτησή σας και θα επικοινωνήσουμε μαζί σας εντός 24 ωρών",
    toastError: "Παρουσιάστηκε κάποιο πρόβλημα με το αίτημά σας, δοκιμάστε ξανά",
    nameValidation:"Το όνομα απαιτείται",
    referenceValidation:"Απαιτείται αναφορά",
    questionValidation: "Η ερώτησή σας είναι απαραίτητη"
}