export default {
    messageQuestionService: "このサービスは、申し込みフォームの記入に問題がある場合にのみ使用できます。その他の質問にはここで回答することはできません。",
    labelName: "名前",
    labelReference: "予約番号",
    labelPhone: "電話番号",
    labelEmail: "Eメール",
    labelQuery: "あなたの質問",
    labelAskQuestion: "ここに質問を送ってください...",
    buttonOpenModal: "質問する",
    title: "質問する",
    modalTitle: "4.フォームに技術的な問題がありますか？",
    toastSuccess: "ご質問を承りました。24時間以内にご連絡いたします。",
    toastError: "リクエストで問題が発生しました。もう一度お試しください",
    nameValidation: "名前が必要です",
    referenceValidation: "参照が必要です",
    questionValidation: "あなたの質問が必要です"
}