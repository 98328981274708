import {useFormContext} from '../providers/FormProvider';
import {nameof} from 'ts-simple-nameof';
import {IFormValues} from '../Interfaces/IFormValues';
import {formSchemaMain} from './formSchemaMain';
import {useTranslation} from 'react-i18next';

export const useFormSchema: any = () => {

    const {t} = useTranslation();

    const {
        isOfflinePayment,
        countryCurrencyValue,
        isBookingConfirmationRequired,
        paymentResponseFields,
        isEvidenceRequired,
        regionDropdownItems,
        isExtendedTerms,
        previousBookingConfirmationFiles,
        previousRefundReasonFiles,
        previousRefundReasonFilesAdditional
    } = useFormContext();

    const isPaymentTypeSelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.paymentType))?.valuesAllowed.length <= 1;
    const isBankNameSelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankNameSelect)) === undefined;
    const getBranchCodeRegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.branchCode))?.regEx ?? '';
    const isBranchCodeHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.branchCode)) === undefined;
    const isBranchCodeSelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.branchCodeSelect)) === undefined;
    const isBankAccountNumberHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankAccountNumber)) === undefined;
    const getBankAccountNumberRegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankAccountNumber))?.regEx ?? '';
    const isBankAccountTypeHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankAccountType)) === undefined;
    const isCardNumberHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.cardNumber)) === undefined;
    const getSortCodeRegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.sortCode))?.regEx ?? '';
    const isSortCodeHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.sortCode)) === undefined;
    const getSwiftCodeRegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankSwiftBic))?.regEx ?? '';
    const isSwiftCodeHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankSwiftBic)) === undefined;
    const isSwiftSelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.bankSwiftBicSelect)) === undefined;
    const isRemittanceLine3SelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine3Select)) === undefined;
    const isRemittanceLine3Hidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine3)) === undefined;
    const getRemittanceLine3RegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine3))?.regEx ?? '';
    const isRemittanceLine4SelectHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine4Select)) === undefined;
    const isRemittanceLine4Hidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine4)) === undefined;
    const getRemittanceLine4RegEx = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.remittanceLine4))?.regEx ?? '';
    const isDateOfBirthHidden = paymentResponseFields.find(x => x.code === nameof<IFormValues>(x => x.dateOfBirth)) === undefined;
    const ignoreLatinValidation = paymentResponseFields.some(x => x.ignoreLatinValidation);
    
    return formSchemaMain(t,
        isOfflinePayment,
        previousBookingConfirmationFiles?.length > 0,
        previousRefundReasonFiles?.length > 0,
        previousRefundReasonFilesAdditional?.length > 0,
        ignoreLatinValidation,
        countryCurrencyValue,
        isBookingConfirmationRequired,
        isEvidenceRequired,
        isPaymentTypeSelectHidden,
        isBankNameSelectHidden,
        getBranchCodeRegEx,
        isBranchCodeHidden,
        isBranchCodeSelectHidden,
        isBankAccountNumberHidden,
        getBankAccountNumberRegEx,
        isBankAccountTypeHidden,
        isCardNumberHidden,
        getSortCodeRegEx,
        isSortCodeHidden,
        getSwiftCodeRegEx,
        isSwiftCodeHidden,
        isSwiftSelectHidden,
        regionDropdownItems?.length > 0,
        isRemittanceLine3SelectHidden,
        isRemittanceLine3Hidden,
        getRemittanceLine3RegEx,
        isRemittanceLine4SelectHidden,
        isRemittanceLine4Hidden,
        getRemittanceLine4RegEx,
        isDateOfBirthHidden,
        isExtendedTerms
    );
}