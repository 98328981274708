import {
    refundableUrl,
    RefundReasonBookedTooManyTickets,
    RefundReasonBookedWrongDate,
    RefundReasonBookedWrongTickets,
    RefundReasonBookingCancelledByOperator,
    RefundReasonBookingRescheduledByOperator,
    RefundReasonCOVID19PositiveTest,
    RefundReasonCOVID19TravelRestrictions
} from './constants';
import {useFormContext} from '../providers/FormProvider';
import {useMemberTermsSubDirectory} from '../../hooks/queries';
import {useRecoilValue} from 'recoil';
import {MemberTermsSubDirectory} from '../../state/activity';
import {useRefundableTermsLanguageLink} from '../../../languages/locales/languageSettings';
import {useTranslation} from 'react-i18next';
import {IFormValues} from '../Interfaces/IFormValues';
import {EventType} from '../enums/eventType';
import * as currencySymbolMap from 'currency-symbol-map';

export function isNullOrWhitespace(input: string) {

    if (typeof input === 'undefined' || input == null) {
        return true;
    }

    return input.replace(/\s/g, '').length < 1;
}

export function getQueryParamValue(name: string, url: string) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regexS = `[\\?&]${name}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    return results == null ? null : results[1];
}

export const useIsOffline = (): boolean => {

    const {
        isDecline,
        isOfflinePayment,
    } = useFormContext();
    
    return isDecline || isOfflinePayment;
}

export function useGetMemberTermsUrl(memberId: number) {

    useMemberTermsSubDirectory(memberId ?? 0);
    const memberTermsSubDirectory = useRecoilValue(MemberTermsSubDirectory);
    
    return `${refundableUrl}${memberTermsSubDirectory}${useRefundableTermsLanguageLink()}`;
}

export const labelExpression = (prefixValue: string, postfixValue: string, labelValue: string) => {
    if (!isNullOrWhitespace(prefixValue) && isNullOrWhitespace(postfixValue)) {
        return `${prefixValue} ${labelValue}`;
    }

    if (!isNullOrWhitespace(postfixValue) && isNullOrWhitespace(prefixValue)) {
        return `${labelValue} ${postfixValue}`;
    }

    return labelValue;
}

export const useDynamicEvidence = (isSecondaryEvidence: boolean) => {

    const {t} = useTranslation();
    
    const {
        requiredEvidence
    } = useFormContext();

    if (requiredEvidence.length === 0)
    {
        return '';
    }

    const evidenceValues = requiredEvidence
        .filter(x => x.isSecondaryEvidence === isSecondaryEvidence)
        .map(x => `${t('form:textRefundEvidence' + x.id)}`);

    return labelExpression
        (t('common:labelUploadPrefix'),
         t('common:labelUploadPostfix'),
         evidenceValues.join(' / '));
}

export const isAutoDeclineReason = (reasonId: number, isExtendedTerms?: boolean) => {
    
    if (reasonId === RefundReasonCOVID19PositiveTest && isExtendedTerms === false) {
        return true;
    }
    
    return reasonId === RefundReasonBookingCancelledByOperator ||
        reasonId === RefundReasonBookingRescheduledByOperator ||
        reasonId === RefundReasonBookedWrongDate ||
        reasonId === RefundReasonBookedWrongTickets ||
        reasonId === RefundReasonBookedTooManyTickets ||
        reasonId === RefundReasonCOVID19TravelRestrictions
}

export const getDateFormat = () => {
    
    if (Intl.NumberFormat().resolvedOptions().locale?.toLocaleLowerCase() === 'en-us')
    {
        return 'MM/dd/yyyy';
    }
    return 'dd/MM/yyyy';
}

export const isRefundAmountLess = (formValues: IFormValues, totalRefundAmount: number) => {
  return totalRefundAmount > 0 && totalRefundAmount < formValues.bookingValue
    && formValues.currency?.length === 3 && formValues.typeOfBooking !== EventType.Flight
}

const isUpper = (s: string): boolean => s.match(/[A-Z]/) !== null;

export const splitAtCapitals = (value: string): string => {
    const words = value.split('').map((s: string, i: number) => {
        if (i === 0) {
            return s;
        }

        return (isUpper(s) && !isUpper(value[i - 1])) ? ` ${s}` : s;
    })

    return words.reduce((c: string, n: string) => `${c}${n}`, '');
}

export const isEmptyOrSpaces = (str: string): boolean => {
    return str === null || str?.match(/^ *$/) !== null;
}

export const removeSpacesAndSpecialChars = (str: string): string => {
    return str !== null ? str?.replace(/[^a-zA-Z0-9]/g, '').trim() : '';
}

export const getCurrencySymbol = (currencyCode: string): string => {
    const currencySymbol = currencySymbolMap(currencyCode);
    return currencySymbol !== undefined ? currencySymbol : '';
}