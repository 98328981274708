export default {
    labelBookingCancelledPostponedHeading: "Has your booking been cancelled or postponed? Read more",
    labelBookingCancelledPostponedText1: "Click",
    labelBookingCancelledPostponedText2: "for more info.",
    labelNewRefundHeading: "New refund application",
    labelNewRefundText: "Enter your booking reference to apply for a refund",
    placeholderBookingReference: "Booking Reference",
    validationBookingReference: "Booking Reference is required",
    validationEmailAsBookingReference: "Email address is not accepted as the booking reference",
    buttonFindBooking: "Find Booking",
    labelEmailHeading: "Already started?",
    labelEmailText: "Send an email reminder to retrieve your application",
    placeholderEmail: "Email",
    buttonEmailReminder: "Email Reminder",
    labelCustomerName : "Customer Name",
    validationCustomerName: "Customer Name is required",
    labelTypeOfBooking: "Type of Booking",
    validationTypeOfBooking: "Type of Booking is required",
    labelEventName:"Event Name",
    labelVenueName:"Venue Name",
    labelFlightNumber:"Flight Number",
    labelOriginAirport: "Origin Airport",
    labelHotelName: "Accommodation Name",
    labelHotelLocation: "Accommodation Location",
    labelCurrency: "Currency",
    validationCurrency: "Currency is required",
    validationCountryCurrency: "The Currency selected must be the currency of your Home Country",
    validationOfflinePayment: "We cannot process refunds for your currency and country selection, please select alternatives",
    labelBookingValue: "Booking Value",
    validationBookingValue: "Booking Value is required",
    validationBookingValueAmount: "Must be greater than 0",
    labelDateOfPurchase: "Date of Purchase",
    validationDateOfPurchase: "Date of Purchase is required",
    labelEmailAddress: "Email Address",
    validationEmailAddress: "Email Address is required",
    validationEmailAddressFormat: "Incorrect email format",
    labelConfirmEmailAddress: "Confirm Email Address",
    validationConfirmEmailAddressFormat: "Incorrect email format",
    validationConfirmEmailAddress: "Confirmation Email Address is required",
    validationConfirmEmailAddressMatch: "Email addresses do not match",
    validationEmailDomain: "This email provider is not accepted, please use an alternative",
    validationDateOfEvent: "Date of event is required",
    labelHomeCountry: "Home Country",
    validationHomeCountry: "Home Country is required",
    labelContactNumberCountryCode: "Contact Number Country Code",
    validationContactNumberCountryCode: "Country Dial Code is required",
    labelContactNumber: "Contact Number",
    validationContactNumber: "Contact Number is required",
    labelAddressLine1: "Address Line 1",
    labelAddressLine2: "Address Line 2",
    labelCity: "City",
    labelZipCode: "Zip Code",
    labelRegion: "Region",
    validationRegion: "Region is required",
    labelNumberOfTicketsToRefund: "Number of Tickets to Refund",
    validationNumberOfTicketsToRefund: "Number of Tickets to Refund is required",
    validationNumberOfTicketsToRefundValue: "Must be greater than 0",
    labelCostPerTicket: "Cost of each Ticket",
    validationCostPerTicket: "Cost per Ticket is required",
    validationCostPerTicketValue: "Must be greater than 0",
    labelTotalRefundAmount: "Total Refund Amount",
    validationTotalRefundAmount: "Total Refund Amount is required",
    validationTotalRefundAmountValue: "Must be greater than 0",
    validationTotalRefundGreaterThanBookingValue: "Total Refund Amount cannot be greater than the Booking Value above",
    labelBookingConfirmationFiles: "Upload Booking Confirmation",
    validationBookingConfirmationFiles: "Attach at least one file or click Save and return later when you have the documents",
    validationEvidenceFilesPopup: "Evidence is required to be uploaded before submitting. If you do not have the evidence right now please save the application and return once you have. We accept electronic copies, scanner copies and photos.",
    validationBookingConfirmationFilesPopup: "Booking confirmation is required to be uploaded before submitting. If you do not have the booking confirmation right now please save the application and return once you have. We accept electronic copies, scanner copies and photos.",
    alertUploadDocuments: "Please upload the relevant documentation detailed in the email and then proceed to Step 3.",
    labelRefundReasonCategory: "Refund Reason",
    validationRefundReasonCategory: "Refund Reason is required",
    labelDateOfIncident: "Date of Incident",
    labelAdditionalComments: "Additional Comments",
    tooltipAdditionalComments: "Additional Comments",
    validationAdditionalComments: "Additional Comments are required",
    labelUnableToNotifyWithinTimeLimit: "Unable To Notify Within 60 Days From",
    labelReasonForNotificationOutsideTimeLimit: "Notification Outside Time Limit Explanation",
    validationReasonForNotificationOutsideTimeLimit: "A Reason for notification after 60 days is required",
    labelRefundReasonFiles: "Upload Evidence",
    validationRefundReasonFiles: "Attach at least one file or click Save and return later when you have the documents",
    validationTextScript: "Must match latin script",
    optionTypeOfBooking1: "Event",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Flight",
    optionTypeOfBooking4: "Accommodation",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parking",
    optionTypeOfBooking7: "Other Travel",
    labelEventTypeDate: "Date of Event",
    labelEventTypeTour: "Date of Tour",
    labelEventTypeFlight: "Date of Flight",
    labelEventTypeCheckIn: "Date of Check In",
    labelEventTypeTransfer: "Date of Transfer",
    labelEventTypeParking: "Date of Parking",
    labelEventTypeOtherTravel: "Date of Travel",
    optionRefundReason11: "Adverse Weather",
    optionRefundReason10: "Armed Forces & Emergency Services Recall",
    optionRefundReason15: "Changes to Examination Dates",
    optionRefundReason18: "Court Summons",
    optionRefundReason17: "COVID-19 Travel Restrictions",
    optionRefundReason19: "COVID-19 Positive Test",
    optionRefundReason20: "Booking Cancelled by Operator/Organiser",
    optionRefundReason21: "Booking Rescheduled by Operator/Organiser",
    optionRefundReason22: "Booked Wrong Date",
    optionRefundReason23: "Booked Wrong Tickets",
    optionRefundReason24: "Booked Too Many Tickets",
    optionRefundReason4: "Death",
    optionRefundReason16: "Emergency Circumstances",
    optionRefundReason14: "Government Travel Ban",
    optionRefundReason9: "Home Emergency",
    optionRefundReason1: "Illness/Injury/Accident",
    optionRefundReason8: "Jury Service",
    optionRefundReason7: "Mechanical Breakdown",
    optionRefundReason2: "Pre-existing Medical Condition",
    optionRefundReason3: "Pregnancy Complication",
    optionRefundReason5: "Public Transport Failure",
    optionRefundReason6: "Flight Disruption",
    optionRefundReason13: "Theft of Document(s)",
    optionRefundReason12: "Work Relocation",
    optionRefundReason25: "Workplace Redundancy",
    optionRefundReason26: "Test Option",
    optionRefundReasonConfirm1: "No",
    optionRefundReasonConfirm2: "Yes",
    optionRefundReasonArmedForces1: "Army",
    optionRefundReasonArmedForces2: "Navy",
    optionRefundReasonArmedForces3: "Air Force",
    optionRefundReasonArmedForces4: "Special Forces",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Fire",
    optionRefundReasonArmedForces7: "Ambulance",
    optionRefundReasonArmedForces8: "Doctor/Nurse",
    optionRefundReasonArmedForces9: "Dentist",
    optionRefundReasonArmedForces10: "Other",
    optionRefundReasonCourtSummons1: "Criminal Prosecution",
    optionRefundReasonCourtSummons2: "Civil Legal Case",
    optionRefundReasonCourtSummons3: "Witness in a Prosecution",
    optionRefundReasonCourtSummons4: "Witness in a Civil Case",
    optionRefundReasonCourtSummons5: "Plaintiff",
    optionRefundReasonCourtSummons6: "Defendant",
    optionRefundReasonCourtSummons7: "Other",
    optionRefundReasonDeath1: "Husband",
    optionRefundReasonDeath2: "Wife",
    optionRefundReasonDeath3: "Son",
    optionRefundReasonDeath4: "Daughter",
    optionRefundReasonDeath5: "Father",
    optionRefundReasonDeath6: "Mother",
    optionRefundReasonDeath7: "Brother",
    optionRefundReasonDeath8: "Sister",
    optionRefundReasonDeath9: "Grandfather",
    optionRefundReasonDeath10: "Grandmother",
    optionRefundReasonDeath11: "Other",
    optionRefundReasonHomeEmergency1: "Fire",
    optionRefundReasonHomeEmergency2: "Flood",
    optionRefundReasonHomeEmergency3: "Robbery",
    optionRefundReasonHomeEmergency4: "Vandalism",
    optionRefundReasonHomeEmergency5: "Domestic Abuse",
    optionRefundReasonHomeEmergency6: "Kidnap",
    optionRefundReasonHomeEmergency7: "Other",
    optionRefundReasonCOVID19Person1: "Myself",
    optionRefundReasonCOVID19Person2: "Another person in the booking",
    optionRefundReasonCOVID19Person3: "A person in my Household",
    optionRefundReasonCOVID19Person4: "Other",
    optionRefundReasonCOVID19TestType1: "PCR Test",
    optionRefundReasonCOVID19TestType2: "RAT Test",
    optionRefundReasonCOVID19TestType3: "Lateral Flow Test",
    optionRefundReasonCOVID19TestType4: "Self Administered Test",
    optionRefundReasonCOVID19TestType5: "No test but I have symptoms",
    optionRefundReasonCOVID19TestType6: "Other",
    optionRefundReasonBreakdown1: "Breakdown",
    optionRefundReasonBreakdown2: "Accident",
    optionRefundReasonBreakdown3: "Fire",
    optionRefundReasonBreakdown4: "Theft",
    optionRefundReasonPublicTransportType1: "Train",
    optionRefundReasonPublicTransportType2: "Bus",
    optionRefundReasonPublicTransportType3: "Tram",
    optionRefundReasonPublicTransportType4: "Boat",
    optionRefundReasonDocumentType1: "Passport",
    optionRefundReasonDocumentType2: "Travel Permit",
    optionRefundReasonDocumentType3: "National ID",
    optionRefundReasonDocumentType4: "Tickets",
    optionRefundReasonWorkRelocationType1: "Temporary",
    optionRefundReasonWorkRelocationType2: "Permanent",
    optionRefundReasonWorkRelocationTemporary1: "0-3 months",
    optionRefundReasonWorkRelocationTemporary2: "3-6 months",
    optionRefundReasonWorkRelocationTemporary3: "Over 6 months",
    optionRefundReasonBookingRelationshipType1: "Someone in the booking",
    optionRefundReasonBookingRelationshipType2: "Immediate relative of someone in the booking",
    optionEmploymentType1: "Full Time",
    optionEmploymentType2: "Part Time",
    optionEmploymentType3: "Contract",
    optionEmploymentLengthType1: "0-1 Years",
    optionEmploymentLengthType2: "1-2 Years",
    optionEmploymentLengthType3: "More Than 2 Years",
    textRefundEvidenceHeading: "Please provide the following evidence:",
    textRefundEvidence1: "Airline Confirmation",
    textRefundEvidence2: "Any appropriate supporting evidence",
    textRefundEvidence3: "Breakdown service call out confirmation",
    textRefundEvidence4: "Death Certificate",
    textRefundEvidence5: "Confirmation cancellation from airline",
    textRefundEvidence6: "Weather Report",
    textRefundEvidence7: "Correspondence from booking agent confirming no capability to re-issue tickets",
    textRefundEvidence8: "Letter from Court",
    textRefundEvidence9: "Medical Certificate / Doctor’s note",
    textRefundEvidenceDescription9: "Confirming details of the illness or injury, the date it first occurred, that it prevents You from Attending the Booking.",
    textRefundEvidence10: "Employer letter confirmation",
    textRefundEvidence11: "Insurance claim correspondence",
    textRefundEvidence12: "Letter from Employer",
    textRefundEvidence13: "National Government website confirmation",
    textRefundEvidence14: "Examination Change Notice",
    textRefundEvidence15: "Police Report",
    textRefundEvidence16: "Police report and reference number",
    textRefundEvidence17: "Transport provider confirmation",
    textRefundEvidence18: "Travel Warning",
    textRefundEvidence20: "COVID-19 Test Certificate",
    textRefundEvidence24: "Government Isolation Notice",
    textRefundEvidence25: "Letter from Commanding Officer",
    textRefundEvidence26: "Letter from Insurers",
    textRefundEvidence27: "Proof of Address",
    textRefundEvidence28: "Proof of relationship to the deceased",
    textRefundEvidence29: "Proof of relationship to the injured/ill person",
    textRefundEvidence30: "Payslip",
    tooltipBookingReference: "To identify your booking reference, check your booking confirmation and view the section about our extended service.",
    tooltipCustomerName: "First and last name that was used to make the booking.",
    tooltipTypeOfBooking: "Type of booking you would like to be refunded.",
    tooltipEventName: "The name of the event.",
    tooltipVenueName: "The name of the venue.",
    tooltipFlightNumber: "The flight number.",
    tooltipOriginAirport: "The departure airport.",
    tooltipHotelName: "The name of the accommodation.",
    tooltipHotelLocation: "The location of the accommodation.",
    tooltipCurrency: "The currency used to pay for the booking.",
    tooltipBookingValue: "Total price you paid for the booking.",
    tooltipDateOfPurchase: "The date that the booking was made.",
    tooltipEmailAddress: "Please use a valid email address. We use this email for correspondence during the refund application.",
    tooltipConfirmEmailAddress: "Use the same email address as above, this is for validation.",
    tooltipHomeCountry: "This will help us bring up the correct banking information on the final step.",
    tooltipContactNumberCountryCode: "Contact number country code.",
    tooltipContactNumber: "Please use a valid number. We use this for correspondence during the refund application.",
    tooltipAddressLine1: "Please note your address will be used when validating your bank account.",
    tooltipAddressLine2: "Second line of address.",
    tooltipCity: "Your home city, details can be used for validating banking details.",
    tooltipZipCode: "Zip Code / Postal Code.",
    tooltipRegion: "Region of your home city, details can be used for validating banking details.",
    tooltipNumberOfTicketsToRefund: "The number of tickets you would like to be refunded.",
    tooltipTotalRefundAmount: "This cannot be more than the total booking value, you will not be able to submit the application if it does.",
    tooltipBookingConfirmationFiles: "Screenshot/attachment of your full booking confirmation which can usually be found in your emails.",
    tooltipRefundReasonCategory: "Please select your reason for refund.",
    tooltipDateOfIncident: "The date of which the incident that impacted your booking occurred.",
    tooltipRefundExplanation: "Please give as much details as possible that support the claim of refund.",
    tooltipUnableToNotifyWithinTimeLimit: "Unable to notify within 60 Days time limit.",
    tooltipReasonForNotificationOutsideTimeLimit: "Notification Outside Time Limit Explanation.",
    tooltipRefundReasonFiles: "Please upload any of the required documents.",
    tooltipEventTypeDate: "Date of the actual event / booking.",
    tooltipEventTypeTour: "Date of the actual tour.",
    tooltipEventTypeFlight: "Date of the actual flight.",
    tooltipEventTypeCheckIn: "Date of the actual check in.",
    tooltipEventTypeTransfer: "Date of the actual transfer.",
    tooltipEventTypeParking: "Date of the actual parking.",
    tooltipEventTypeOtherTravel: "Start date of the actual trip."
};