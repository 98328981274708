export default {
    messageQuestionService: "សេវាកម្មនេះគឺសម្រាប់តែការប្រើប្រាស់ ប្រសិនបើអ្នកមានបញ្ហាក្នុងការបំពេញទម្រង់ពាក្យសុំ យើងមិនអាចឆ្លើយតបទៅនឹងសំណួរផ្សេងទៀតនៅទីនេះបានទេ។.",
    labelName: "មោះ",
    labelReference: "ឯកសារយោងការកក់",
    labelPhone: "លេខទូរសព្ទ",
    labelEmail: "អ៊ីមែល",
    labelQuery: "សំណួរ​របស់​អ្នក",
    labelAskQuestion: "ផ្ញើសំណួរមកយើងនៅទីនេះ...",
    buttonOpenModal: "សួរ​សំណួរ​មួយ",
    title: "សួរ​សំណួរ​មួយ",
    modalTitle: "4. មានបញ្ហាបច្ចេកទេសជាមួយទម្រង់?",
    toastSuccess: "យើងបានទទួលសំណួររបស់អ្នក ហើយយើងនឹងទាក់ទងអ្នកក្នុងរយៈពេល 24 ម៉ោង។",
    toastError: "មានអ្វីមួយខុសជាមួយសំណើរបស់អ្នក សូមព្យាយាមម្តងទៀត",
    nameValidation:"ទាមទារឈ្មោះ",
    referenceValidation:"ឯកសារយោងត្រូវបានទាមទារ",
    questionValidation: "សំណួររបស់អ្នកត្រូវបានទាមទារ"
}