import * as React from 'react';
import {useState} from 'react';
import {FormikProps, useField} from 'formik';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {useFormContext} from '../providers/FormProvider';
import {IFormValues} from '../Interfaces/IFormValues';
import {isRefundAmountLess} from '../utils/helpers';
import {CurrencyInput} from '../formInputs';
import {ConfirmRefundAmountModal} from '../modals/ConfirmRefundAmountModal';

interface CurrencyInputsControlProps {
    formProps: FormikProps<IFormValues>;
}

export const CurrencyInputsControl = (props: CurrencyInputsControlProps) => {

    const {
        formProps
    } = props;

    const {
        bookingMatched,
        disableRefundAmount
    } = useFormContext();

    const {t} = useTranslation();

    const [showConfirmRefundAmountModal, setShowConfirmRefundAmountModal] = useState<boolean>(false);
    const [bookingValueField, bookingValueMeta, bookingValueHelper] = useField(nameof<IFormValues>(x => x.bookingValue))
    const [currencyField, currencyMeta, currencyHelper] = useField(nameof<IFormValues>(x => x.currency))

    const handleConfirmTotalRefundAmountModal = (event: React.FocusEvent<HTMLInputElement>) => {
        const totalRefundAmount = +event.target.value;

        if (isRefundAmountLess(formProps.values, totalRefundAmount)) {
            setShowConfirmRefundAmountModal(true);
        }
    }

    const handleCloseConfirmTotalRefundAmountModal = () => {
        setShowConfirmRefundAmountModal(false);
    }

    const resetOriginalBookingValues = () => {
        if (bookingMatched) {
            void bookingValueHelper.setValue(formProps.values.originalBookingValue);
            void currencyHelper.setValue(formProps.values.originalCurrency);
        }
    }

    return (
        <>
            <CurrencyInput
                key={nameof<IFormValues>(x => x.bookingValue)}
                label={t('form:labelBookingValue')}
                placeholder={t('form:labelBookingValue')}
                currencyCode={formProps.values.currency}
                controlId={nameof<IFormValues>(x => x.bookingValue)}
                onBlur={resetOriginalBookingValues}
                hidden={false}
                disabled={bookingMatched}
                tooltip={t('form:tooltipBookingValue')}
                required
            />

            <CurrencyInput
                key={nameof<IFormValues>(x => x.totalRefundAmount)}
                label={t('form:labelTotalRefundAmount')}
                placeholder={t('form:labelBookingValue')}
                currencyCode={formProps.values.currency}
                controlId={nameof<IFormValues>(x => x.totalRefundAmount)}
                hidden={false}
                disabled={disableRefundAmount}
                onBlur={(e) => handleConfirmTotalRefundAmountModal(e)}
                tooltip={t('form:tooltipTotalRefundAmount')}
                required
            />

            <ConfirmRefundAmountModal
                formValues={formProps.values}
                showModal={showConfirmRefundAmountModal}
                handleClose={handleCloseConfirmTotalRefundAmountModal}
            />
        </>
    );
}