export default {
    labelSavedApplicationReference: "सहेजा गया आवेदन संदर्भ:",
    labelLanguage: "भाषा",
    messageWarningBookingHeading1: "बुकिंग रद्द:",
    messageWarningBookingHeading2: "बुकिंग स्थगित / पुनर्निर्धारित:",
    messageWarningBookingBody1: "अगर आपकी बुकिंग आयोजक या एयरलाइन द्वारा रद्द कर दी गई है, तो यह टीम धनवापसी में आपकी सहायता नहीं कर पाएगी। आपको सीधे अपनी ग्राहक सेवा टीम से संपर्क करना होगा; संपर्क विवरण के लिए अपनी बुकिंग पुष्टिकरण या वेबसाइट देखें।",
    messageWarningBookingBody2: "यदि आपकी बुकिंग स्थगित कर दी गई है, तो आपकी विस्तारित वापसी योग्य शर्तें नई तिथि पर लागू होंगी। यदि आपके पास हमारी शर्तों के तहत धनवापसी का कोई वैध कारण है, तो कृपया सही सहायक साक्ष्य के साथ फ़ॉर्म को पूरा करें।",
    messageWarningBookingBody3: "कृपया ध्यान दें: यह सेवा केवल हमारे शब्दों में शामिल कारणों के लिए धनवापसी प्रदान कर सकती है, जो आपके बुकिंग पुष्टिकरण ईमेल में उपलब्ध है।",
    messageWarningBookingBody4: "यदि आप हमारी शर्तों से बाहर के कारणों से बुकिंग नहीं कर सकते हैं, तो हम सहायता करने में सक्षम नहीं होंगे। आपको अपनी ग्राहक सेवा टीम से सीधे संपर्क करने की आवश्यकता होगी क्योंकि वे मदद करने में सक्षम हो सकते हैं; संपर्क विवरण के लिए अपनी बुकिंग की पुष्टि या वेबसाइट देखें।",
    linkWarningBooking: "यहां",
    formSectionNavigatorStep1: "चरण 1",
    formSectionNavigatorStep2: "चरण 2",
    formSectionNavigatorStep3: "चरण 3",
    formSectionNavigatorStep1Text: "संपर्क एवं बुकिंग विवरण",
    formSectionNavigatorStep2Text: "वापसी का कारण",
    formSectionNavigatorStep3Text: "भुगतान विवरण",
    messageBookingMatchedAndFound: "यदि आपका बुकिंग संदर्भ किसी मौजूदा एप्लिकेशन से जुड़ा है तो हम आपको एक ईमेल भेजेंगे जिसमें आपके एप्लिकेशन तक पहुंचने के लिए एक लिंक होगा",
    messageBookingMatchedAndFoundCompleted: "आपका धनवापसी आवेदन पहले ही स्वीकृत हो चुका है! यदि आपको अपने भुगतान में कोई समस्या है तो कृपया संपर्क करें:",
    applicationDeclinedMessage: "आपका आवेदन अस्वीकार कर दिया गया है और अब बंद कर दिया गया है।",
    messageBookingNotFound1: "हमें उस संदर्भ में कोई बुकिंग नहीं मिल रही है। कृपया अपना बुकिंग पुष्टिकरण ईमेल जांचें और पुनः दर्ज करें।",
    messageBookingNotFound2: "चिंता न करें, यदि आपका बुकिंग संदर्भ काम नहीं करता है तो आप 'बुकिंग खोजें' का चयन कर सकते हैं और अपना विवरण दर्ज कर सकते हैं। फ़ॉर्म भरते समय कृपया अपने बुकिंग पुष्टिकरण ईमेल की एक प्रति अपलोड करें ताकि हमें आपके बुकिंग विवरण का पता लगाने में सहायता मिल सके।",
    messageLocatingBooking: "आपकी बुकिंग का पता लगाया जा रहा है...",
    messageLoadingBank: "बैंक जानकारी लोड हो रही है...",
    messageFailCurrencyLookup: "मुद्रा प्राप्त करने में त्रुटि",
    messageSavingApplication: "आपका आवेदन सहेजा जा रहा है...",
    messageSavedApplication: "धनवापसी आवेदन सफलतापूर्वक सहेजा गया",
    messageFailSavedApplication: "धनवापसी आवेदन सहेजने में विफल",
    messageSendingEmailReminder: "प्रसंस्करण...",
    messageErrorLocationDetails: "स्थान विवरण प्राप्त करने में त्रुटि",
    messageFailFormSubmission: "फ़ॉर्म सबमिशन विफल",
    messageApplicationCannotBeFound: "आवेदन नहीं मिल सका",
    messageApplicationRetrievalError: "आवेदन प्राप्त करने में त्रुटि",
    messageSubmittingApplication: "आवेदन जमा किया जा रहा है...",
    messageEventDateLimitError: "समय सीमा के बाहर अधिसूचना का कारण",
    messageAllTicketsAssigned: "सभी टिकट किसी अन्य धनवापसी आवेदन को सौंपे गए हैं।",
    selectPrefix: "चुनें",
    selectPostfix: "",
    fileUpload: "अपलोड करने के लिए यहां क्लिक करें",
    fileUploadAcceptedFilesMessage: "हम केवल .png, .jpeg, .heic, .webp के PDF और छवि प्रारूप स्वीकार करते हैं",
    fileUploadNoFiles: "0 फ़ाइलें चयनित",
    fileUploadPreviouslyUploaded: "पहले से अपलोड किया हुआ:",
    fileUploadSelectedUploaded: "चयनित पहले ही अपलोड किए जा चुके हैं:",
    buttonNext: "अगला",
    buttonPrevious: "पिछला",
    buttonSave: "सहेजें",
    buttonCancel: "रद्द करना",
    buttonSubmit: "प्रस्तुत करना",
    buttonConfirm: "पुष्टि करें",
    buttonYes: "हाँ",
    buttonNo: "नहीं",
    buttonClose: "बंद करे",
    linkPrivacyStatement: "गोपनीयता वाले कथन",
    linkComplaintsPolicy: "शिकायत नीति",
    validationRequired: "आवश्यक",
    validationInvalid: "अमान्य",
    labelUploadPrefix: "डालना",
    labelUploadPostfix: "",
    labelChange: "परिवर्तन",
    labelView: "देखना",
    browserNavigationMessage: "सहेजे न गए परिवर्तन खो जाएंगे"
};
