export default {
    labelBookingCancelledPostponedHeading: "Vai jūsu rezervācija ir atcelta vai atlikta? Lasīt vairāk",
    labelBookingCancelledPostponedText1: "Noklikšķiniet uz",
    labelBookingCancelledPostponedText2: "lai iegūtu vairāk informācijas.",
    labelNewRefundHeading: "Jauns atmaksas pieteikums?",
    labelNewRefundText: "Ievadiet savu rezervācijas atsauci šeit...",
    placeholderBookingReference: "Rezervācijas atsauce",
    validationBookingReference: "Rezervācijas atsauce ir nepieciešama",
    validationEmailAsBookingReference: "E-pasta adrese netiek pieņemta kā rezervācijas atsauce",
    buttonFindBooking: "Atrast rezervāciju",
    labelEmailHeading: "Vai jau esat sācis?",
    labelEmailText: "Nosūtiet e-pasta atgādinājumu, lai izgūtu lietojumprogrammu.",
    placeholderEmail: "E-pasts",
    buttonEmailReminder: "E-pasta atgādinājums",
    validationCustomerName: "Klienta vārds un uzvārds ir obligāts",
    labelTypeOfBooking: "Rezervācijas veids",
    validationTypeOfBooking: "Rezervācijas veids ir obligāts",
    labelEventName: "Notikuma nosaukums",
    labelVenueName: "Norises vietas nosaukums",
    labelFlightNumber: "Reisa numurs",
    labelOriginAirport: "Izcelsmes lidosta",
    labelHotelName: "Naktsmītnes nosaukums",
    labelHotelLocation: "Naktsmītnes atrašanās vieta",
    labelCurrency: "Valūta",
    validationCurrency: "Nepieciešama valūta",
    validationCountryCurrency: "Izvēlētajai valūtai ir jābūt jūsu mītnes valsts valūtai",
    validationOfflinePayment: "Mēs nevaram apstrādāt atmaksu par jūsu valūtu un valsts izvēli, lūdzu, izvēlieties alternatīvas",
    labelBookingValue: "Rezervācijas vērtība",
    validationBookingValue: "Rezervācijas vērtība ir nepieciešama",
    validationBookingValueAmount: "Jābūt lielākam par 0",
    labelDateOfPurchase: "Iegādes datums",
    validationDateOfPurchase: "Nepieciešams iegādes datums",
    labelEmailAddress: "E-pasta adrese",
    validationEmailAddress: "E-pasta adrese ir jānorāda",
    validationEmailAddressFormat: "Nepareizs e-pasta formāts",
    labelConfirmEmailAddress: "Apstipriniet e-pasta adresi",
    validationConfirmEmailAddressFormat: "Nepareizs e-pasta formāts",
    validationConfirmEmailAddress: "Nepieciešama apstiprinājuma e-pasta adrese",
    validationConfirmEmailAddressMatch: "E-pasta adreses neatbilst",
    validationEmailDomain: "Šis e-pasta pakalpojumu sniedzējs netiek pieņemts, lūdzu, izmantojiet alternatīvu",
    validationDateOfEvent: "Jānorāda notikuma datums",
    labelHomeCountry: "Mītnes valsts",
    validationHomeCountry: "Mītnes valsts ir jānorāda",
    labelContactNumberCountryCode: "Kontakttālrunis Valsts kods",
    validationContactNumberCountryCode: "Nepieciešams valsts numura sastādīšanas kods",
    labelContactNumber: "Kontakttālrunis",
    validationContactNumber: "Nepieciešams kontakttālrunis",
    labelAddressLine1: "Adreses 1. rindiņa",
    labelAddressLine2: "Adreses 2. rindiņa",
    labelCity: "Pilsēta",
    labelZipCode: "Pasta indekss",
    labelRegion: "Reģions",
    validationRegion: "Reģions ir nepieciešams",
    labelNumberOfTicketsToRefund: "Biļešu skaits, lai atmaksātu naudu",
    validationNumberOfTicketsToRefund: "Nepieciešams biļešu skaits, lai saņemtu atmaksu",
    validationNumberOfTicketsToRefundValue: "Jābūt lielākam par 0",
    labelCostPerTicket: "Katras biļetes cena",
    validationCostPerTicket: "Maksa par biļeti ir nepieciešama",
    validationCostPerTicketValue: "Jābūt lielākam par 0",
    labelTotalRefundAmount: "Kopējā kompensācijas summa",
    validationTotalRefundAmount: "Kopējā atmaksas summa ir nepieciešama",
    validationTotalRefundAmountValue: "Jābūt lielākam par 0",
    validationTotalRefundGreaterThanBookingValue: "Atmaksas kopējā summa nevar būt lielāka par iepriekš norādīto Rezervācijas vērtību",
    labelBookingConfirmationFiles: "Augšupielādēt rezervācijas apstiprinājumu",
    validationBookingConfirmationFiles: "Pievienojiet vismaz vienu failu vai noklikšķiniet uz Saglabāt un atgriezties vēlāk, kad jums ir dokumenti",
    validationEvidenceFilesPopup: "Pirms iesniegšanas ir jāaugšupielādē pierādījumi. Ja jums šobrīd nav pierādījumu, lūdzu, saglabājiet pieteikumu un atgriezieties, kad esat to izdarījis. Mēs pieņemam elektroniskās kopijas, skeneru kopijas un fotoattēlus.",
    validationBookingConfirmationFilesPopup: "Rezervācijas apstiprinājums ir jāaugšupielādē pirms iesniegšanas. Ja jums šobrīd nav rezervācijas apstiprinājuma, lūdzu, saglabājiet pieteikumu un atgriezieties, tiklīdz esat to izdarījis. Mēs pieņemam elektroniskās kopijas, skeneru kopijas un fotoattēlus.",
    alertUploadDocuments: "Lūdzu, augšupielādējiet attiecīgo dokumentāciju, kas sīki aprakstīta e-pastā, un pēc tam pārejiet pie 3. darbības.",
    labelRefundReasonCategory: "Atmaksas iemesls",
    validationRefundReasonCategory: "Atmaksas iemesls ir nepieciešams",
    labelDateOfIncident: "Incidenta datums",
    labelAdditionalComments: "Papildu komentāri",
    tooltipAdditionalComments: "Papildu komentāri",
    validationAdditionalComments: "Nepieciešami papildu komentāri",
    labelUnableToNotifyWithinTimeLimit: "Nevar paziņot 60 dienu laikā no",
    labelReasonForNotificationOutsideTimeLimit: "Paziņojums ārpus termiņa paskaidrojums",
    validationReasonForNotificationOutsideTimeLimit: "Ir nepieciešams paziņojuma iemesls pēc 60 dienām",
    labelRefundReasonFiles: "Augšupielādēt pierādījumus",
    validationRefundReasonFiles: "Pievienojiet vismaz vienu failu vai noklikšķiniet uz Saglabāt un atgriezties vēlāk, kad jums ir dokumenti",
    validationTextScript: "Jāatbilst latīņu alfabēta rakstībai",
    optionTypeOfBooking1: "Notikums",
    optionTypeOfBooking2: "Tour",
    optionTypeOfBooking3: "Lidojums",
    optionTypeOfBooking4: "Izmitināšanu",
    optionTypeOfBooking5: "Pārsūtīšanas",
    optionTypeOfBooking6: "Autostāvvietu",
    optionTypeOfBooking7: "Citi ceļojumi",
    labelEventTypeDate: "Notikuma datums",
    labelEventTypeTour: "Ekskursijas datums",
    labelEventTypeFlight: "Lidojuma datums",
    labelEventTypeCheckIn: "Reģistrācijas datums",
    labelEventTypeTransfer: "Nodošanas datums",
    labelEventTypeParking: "Autostāvvietas datums",
    labelEventTypeOtherTravel: "Ceļojuma datums",
    optionRefundReason11: "Nelabvēlīgi laika apstākļi",
    optionRefundReason10: "Bruņotie spēki un avārijas dienesti atsauc",
    optionRefundReason15: "Izmaiņas pārbaudes datumos",
    optionRefundReason18: "Tiesas pavēste",
    optionRefundReason17: "COVID-19 ceļošanas ierobežojumi",
    optionRefundReason19: "Covid-19 pozitīvs tests",
    optionRefundReason20: "Operatora/organizatora atceltu rezervāciju",
    optionRefundReason21: "Rezervējumu pārplānojis operators/organizators",
    optionRefundReason22: "Rezervēts nepareizs datums",
    optionRefundReason23: "Rezervētas nepareizas biļetes",
    optionRefundReason24: "Rezervēts pārāk daudz biļešu",
    optionRefundReason4: "Nāve",
    optionRefundReason16: "Ārkārtas apstākļi",
    optionRefundReason14: "Valdības ceļošanas aizliegums",
    optionRefundReason9: "Ārkārtas situācija mājās",
    optionRefundReason1: "Slimība/trauma/nelaimes gadījums",
    optionRefundReason8: "Žūrijas dienests",
    optionRefundReason7: "Mehāniskais sadalījums",
    optionRefundReason2: "Iepriekšējais medicīniskais stāvoklis",
    optionRefundReason3: "Grūtniecības komplikācijas",
    optionRefundReason5: "Sabiedriskā transporta atteice",
    optionRefundReason6: "Lidojuma traucējumi",
    optionRefundReason13: "Dokumenta(-u) zādzība",
    optionRefundReason12: "Darba pārvietošana",
    optionRefundReason25: "Atlaišana no darba vietas",
    optionRefundReason26: "Testa opcija",
    optionRefundReasonConfirm1: "Nē",
    optionRefundReasonConfirm2: "Jā",
    optionRefundReasonArmedForces1: "Armija",
    optionRefundReasonArmedForces2: "Navy",
    optionRefundReasonArmedForces3: "Gaisa spēki",
    optionRefundReasonArmedForces4: "Īpašie spēki",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Uguns",
    optionRefundReasonArmedForces7: "Ātrās palīdzības",
    optionRefundReasonArmedForces8: "Ārsts/medmāsa",
    optionRefundReasonArmedForces9: "Zobārsts",
    optionRefundReasonArmedForces10: "Otrs",
    optionRefundReasonCourtSummons1: "Kriminālvajāšana",
    optionRefundReasonCourtSummons2: "Civillieta",
    optionRefundReasonCourtSummons3: "Liecinieks apsūdzībā",
    optionRefundReasonCourtSummons4: "Liecinieks civillietā",
    optionRefundReasonCourtSummons5: "Prasītājs",
    optionRefundReasonCourtSummons6: "Atbildētāja",
    optionRefundReasonCourtSummons7: "Otrs",
    optionRefundReasonDeath1: "Vīrs",
    optionRefundReasonDeath2: "Sieva",
    optionRefundReasonDeath3: "Skaņa",
    optionRefundReasonDeath4: "Meita",
    optionRefundReasonDeath5: "Tēvs",
    optionRefundReasonDeath6: "Māte",
    optionRefundReasonDeath7: "Brālis",
    optionRefundReasonDeath8: "Māsa",
    optionRefundReasonDeath9: "Vectēvs",
    optionRefundReasonDeath10: "Vecmāmiņa",
    optionRefundReasonDeath11: "Otrs",
    optionRefundReasonHomeEmergency1: "Uguns",
    optionRefundReasonHomeEmergency2: "Plūdi",
    optionRefundReasonHomeEmergency3: "Laupīšana",
    optionRefundReasonHomeEmergency4: "Vandālisms",
    optionRefundReasonHomeEmergency5: "Vardarbība ģimenē",
    optionRefundReasonHomeEmergency6: "Nolaupīt",
    optionRefundReasonHomeEmergency7: "Otrs",
    optionRefundReasonCOVID19Person1: "Sevi",
    optionRefundReasonCOVID19Person2: "Vēl viena persona rezervācijā",
    optionRefundReasonCOVID19Person3: "Persona manā mājsaimniecībā",
    optionRefundReasonCOVID19Person4: "Otrs",
    optionRefundReasonCOVID19TestType1: "PCR tests",
    optionRefundReasonCOVID19TestType2: "RAT tests",
    optionRefundReasonCOVID19TestType3: "Sānu plūsmas tests",
    optionRefundReasonCOVID19TestType4: "Pašvadīts tests",
    optionRefundReasonCOVID19TestType5: "Nav testa, bet man ir simptomi",
    optionRefundReasonCOVID19TestType6: "Otrs",
    optionRefundReasonBreakdown1: "Sadalījums",
    optionRefundReasonBreakdown2: "Negadījumu",
    optionRefundReasonBreakdown3: "Uguns",
    optionRefundReasonBreakdown4: "Zādzība",
    optionRefundReasonPublicTransportType1: "Vilciens",
    optionRefundReasonPublicTransportType2: "Autobuss",
    optionRefundReasonPublicTransportType3: "Tramvajs",
    optionRefundReasonPublicTransportType4: "Laiva",
    optionRefundReasonDocumentType1: "Pase",
    optionRefundReasonDocumentType2: "Ceļošanas atļauja",
    optionRefundReasonDocumentType3: "Nacionālais ID",
    optionRefundReasonDocumentType4: "Biļetes",
    optionRefundReasonWorkRelocationType1: "Pagaidu",
    optionRefundReasonWorkRelocationType2: "Pastāvīgs",
    optionRefundReasonWorkRelocationTemporary1: "0-3 mēneši",
    optionRefundReasonWorkRelocationTemporary2: "3-6 mēneši",
    optionRefundReasonWorkRelocationTemporary3: "Vairāk nekā 6 mēneši",
    optionRefundReasonBookingRelationshipType1: "Kāds rezervācijā",
    optionRefundReasonBookingRelationshipType2: "Kādas personas, kas piedalās rezervācijā, tuvākais radinieks",
    optionEmploymentType1: "Pilna laika",
    optionEmploymentType2: "Nepilna laika",
    optionEmploymentType3: "Līgums",
    optionEmploymentLengthType1: "0-1 gadi",
    optionEmploymentLengthType2: "1-2 gadi",
    optionEmploymentLengthType3: "Vairāk nekā 2 gadi",
    textRefundEvidenceHeading: "Lūdzu, sniedziet šādus pierādījumus:",
    textRefundEvidence1: "Aviokompānijas apstiprinājums",
    textRefundEvidence2: "Visi atbilstošie apstiprinošie pierādījumi",
    textRefundEvidence3: "Avārijas dienesta izsaukuma apstiprinājums",
    textRefundEvidence4: "Miršanas apliecība",
    textRefundEvidence5: "Apstiprinājuma atcelšana no aviokompānijas",
    textRefundEvidence6: "Laika ziņu atskaite",
    textRefundEvidence7: "Sarakste no rezervēšanas aģenta, kas apstiprina, ka nav iespējas atkārtoti izsniegt biļetes",
    textRefundEvidence8: "Tiesas vēstule",
    textRefundEvidence9: "Medicīniskā izziņa / ārsta piezīme",
    textRefundEvidenceDescription9: "Apstiprinot informāciju par slimību vai traumu, datumu, kad tā pirmo reizi notikusi, ka tā neļauj Jums apmeklēt Rezervējumu.",
    textRefundEvidence10: "Darba devēja vēstules apstiprinājums",
    textRefundEvidence11: "Apdrošināšanas prasību sarakste",
    textRefundEvidence12: "Darba devēja vēstule",
    textRefundEvidence13: "Nacionālās valdības tīmekļa vietnes apstiprinājums",
    textRefundEvidence14: "Paziņojums par pārbaudes izmaiņām",
    textRefundEvidence15: "Policijas ziņojums",
    textRefundEvidence16: "Policijas ziņojums un atsauces numurs",
    textRefundEvidence17: "Transporta pakalpojumu sniedzēja apstiprinājums",
    textRefundEvidence18: "Ceļojumu brīdinājums",
    textRefundEvidence20: "COVID-19 testa sertifikāts",
    textRefundEvidence24: "Valdības paziņojums par izolāciju",
    textRefundEvidence25: "Komandiera vēstule",
    textRefundEvidence26: "Apdrošinātāju vēstule",
    textRefundEvidence27: "Adreses apliecinājums",
    textRefundEvidence28: "Pierādījums par radniecību ar mantojuma atstājēju",
    textRefundEvidence29: "Pierādījums par saistību ar cietušo/slimo personu",
    textRefundEvidence30: "Algas lapiņa",
    tooltipBookingReference: "Lai identificētu jūsu rezervācijas atsauci, pārbaudiet rezervācijas apstiprinājumu un skatiet sadaļu par mūsu paplašināto pakalpojumu.",
    tooltipCustomerName: "Vārds un uzvārds, kas tika izmantots, lai veiktu rezervāciju.",
    tooltipTypeOfBooking: "Rezervācijas veids, par kuru vēlaties saņemt atmaksu.",
    tooltipEventName: "Pasākuma nosaukums.",
    tooltipVenueName: "Norises vietas nosaukums.",
    tooltipFlightNumber: "Lidojuma numurs.",
    tooltipOriginAirport: "Izlidošanas lidosta.",
    tooltipHotelName: "Naktsmītnes nosaukums.",
    tooltipHotelLocation: "Naktsmītnes atrašanās vieta.",
    tooltipCurrency: "Valūta, kas tika izmantota, lai samaksātu par rezervāciju.",
    tooltipBookingValue: "Kopējā cena, ko samaksājāt par rezervāciju.",
    tooltipDateOfPurchase: "Rezervācijas veikšanas datums.",
    tooltipEmailAddress: "Lūdzu, izmantojiet derīgu e-pasta adresi. Mēs izmantojam šo e-pastu sarakstei atmaksas pieteikuma laikā.",
    tooltipConfirmEmailAddress: "Izmantojiet to pašu e-pasta adresi, kas norādīta iepriekš, tā ir paredzēta validācijai.",
    tooltipHomeCountry: "Tas palīdzēs mums sniegt pareizu bankas informāciju par pēdējo soli.",
    tooltipContactNumberCountryCode: "Kontakttālruņa valsts kods.",
    tooltipContactNumber: "Lūdzu, izmantojiet derīgu numuru. Mēs to izmantojam sarakstei atmaksas pieteikuma laikā.",
    tooltipAddressLine1: "Lūdzu, ņemiet vērā, ka jūsu adrese tiks izmantota, apstiprinot jūsu bankas kontu.",
    tooltipAddressLine2: "Otrā adreses līnija.",
    tooltipCity: "Jūsu dzimtā pilsēta, informāciju var izmantot, lai apstiprinātu bankas datus.",
    tooltipZipCode: "Pasta indekss / pasta indekss.",
    tooltipRegion: "Jūsu dzimtās pilsētas reģions, informāciju var izmantot, lai apstiprinātu bankas informāciju.",
    tooltipNumberOfTicketsToRefund: "Biļešu skaits, par kuru vēlaties saņemt atmaksu.",
    tooltipTotalRefundAmount: "Tas nevar būt lielāks par kopējo rezervācijas vērtību, jūs nevarēsiet iesniegt pieteikumu, ja tas notiks.",
    tooltipBookingConfirmationFiles: "Pilna rezervācijas apstiprinājuma ekrānuzņēmums/pielikums, ko parasti var atrast jūsu e-pastos.",
    tooltipRefundReasonCategory: "Lūdzu, izvēlieties atmaksas iemeslu.",
    tooltipDateOfIncident: "Datums, kad notika incidents, kas ietekmēja jūsu rezervāciju.",
    tooltipRefundExplanation: "Lūdzu, norādiet pēc iespējas sīkāku informāciju, kas pamato atmaksas pieprasījumu.",
    tooltipUnableToNotifyWithinTimeLimit: "Nevar paziņot 60 dienu laikā.",
    tooltipReasonForNotificationOutsideTimeLimit: "Paziņojums ārpus termiņa paskaidrojuma.",
    tooltipRefundReasonFiles: "Lūdzu, augšupielādējiet kādu no nepieciešamajiem dokumentiem.",
    tooltipEventTypeDate: "Faktiskā notikuma / rezervācijas datums.",
    tooltipEventTypeTour: "Faktiskās ekskursijas datums.",
    tooltipEventTypeFlight: "Faktiskā lidojuma datums.",
    tooltipEventTypeCheckIn: "Faktiskās reģistrācijas datums.",
    tooltipEventTypeTransfer: "Faktiskā pārskaitījuma datums.",
    tooltipEventTypeParking: "Faktiskās autostāvvietas datums.",
    tooltipEventTypeOtherTravel: "Faktiskā ceļojuma sākuma datums.",
};