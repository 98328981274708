export default {
    labelSavedApplicationReference: "Gespeicherte Anwendung Ref:",
    labelLanguage: "Sprache",
    messageWarningBookingHeading1: "Buchung storniert:",
    messageWarningBookingHeading2: "Buchung verschoben / verschoben:",
    messageWarningBookingBody1: "Wenn Ihre Buchung vom Veranstalter oder der Fluggesellschaft storniert wurde, kann Ihnen dieses Team keine Rückerstattung gewähren. Sie müssen sich direkt an Ihr Kundendienstteam wenden. Kontaktdaten finden Sie in Ihrer Buchungsbestätigung oder auf Ihrer Website.",
    messageWarningBookingBody2: "Wenn Ihre Buchung verschoben wurde, werden Ihre verlängerten erstattbaren Bedingungen auf das neue Datum übertragen. Wenn Sie einen gültigen Grund für die Rückerstattung gemäß unseren Bedingungen haben, füllen Sie bitte das Formular mit den korrekten Belegen aus.",
    messageWarningBookingBody3: "Bitte beachten Sie: Dieser Service kann nur Rückerstattungen aus den Gründen gewähren, die in unserem Wortlaut enthalten sind, der in Ihrer Buchungsbestätigungs-E-Mail verfügbar ist.",
    messageWarningBookingBody4: "Wenn Sie die Buchung aus Gründen außerhalb unserer Bedingungen nicht vornehmen können, können wir Ihnen nicht helfen. Sie müssen sich direkt an Ihr Kundendienstteam wenden, da es Ihnen möglicherweise weiterhelfen kann. Die Kontaktdaten finden Sie in Ihrer Buchungsbestätigung oder auf der Website.",
    linkWarningBooking: "Hier",
    formSectionNavigatorStep1: "Schritt 1",
    formSectionNavigatorStep2: "Schritt 2",
    formSectionNavigatorStep3: "Schritt 3",
    formSectionNavigatorStep1Text: "Kontakt- und Buchungsdetails",
    formSectionNavigatorStep2Text: "Rückerstattungsgrund",
    formSectionNavigatorStep3Text: "Zahlungsdetails",
    messageBookingMatchedAndFound: "Wenn Ihre Buchungsreferenz mit einer bestehenden Bewerbung verknüpft ist, senden wir Ihnen eine E-Mail mit einem Link zum Zugriff auf Ihre Bewerbung",
    messageBookingMatchedAndFoundCompleted: "Ihr Rückerstattungsantrag wurde bereits genehmigt! Wenn Sie Probleme mit Ihrer Zahlung haben, wenden Sie sich bitte an:",
    applicationDeclinedMessage: "Ihre Bewerbung wurde abgelehnt und ist nun geschlossen.",
    messageBookingNotFound1: "Wir können keine Buchung mit dieser Referenz finden. Bitte überprüfen Sie Ihre Buchungsbestätigungs-E-Mail und geben Sie sie erneut ein.",
    messageBookingNotFound2: "Keine Sorge, wenn Ihre Buchungsreferenz nicht funktioniert, können Sie 'Buchung suchen' auswählen und Ihre Daten eingeben. Wenn Sie das Formular ausfüllen, laden Sie bitte eine Kopie Ihrer Buchungsbestätigungs-E-Mail hoch, damit wir Ihre Buchungsdetails finden können.",
    messageLocatingBooking: "Finden Sie Ihre Buchung ...",
    messageLoadingBank: "Bankinformationen laden ...",
    messageFailCurrencyLookup: "Fehler beim Abrufen der Währung",
    messageSavingApplication: "Speichern Sie Ihre Anwendung ...",
    messageSavedApplication: "Rückerstattungsantrag erfolgreich gespeichert",
    messageFailSavedApplication: "Rückerstattungsantrag konnte nicht gespeichert werden",
    messageSendingEmailReminder: "Wird bearbeitet...",
    messageErrorLocationDetails: "Fehler beim Abrufen der Standortdetails",
    messageFailFormSubmission: "Formularübermittlung fehlgeschlagen",
    messageApplicationCannotBeFound: "Anwendung konnte nicht gefunden werden",
    messageApplicationRetrievalError: "Fehler beim Abrufen der Anwendung",
    messageSubmittingApplication: "Bewerbung einreichen …",
    messageEventDateLimitError: "Grund für die Benachrichtigung außerhalb des Zeitlimits",
    messageAllTicketsAssigned: "Alle Tickets wurden einem anderen Erstattungsantrag zugeordnet.",
    selectPrefix: "Wählen",
    selectPostfix: "",
    fileUpload: "Klicken Sie hier zum Hochladen",
    fileUploadAcceptedFilesMessage: "Wir akzeptieren nur PDFs und Bildformate von .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 Dateien ausgewählt ",
    fileUploadPreviouslyUploaded: "Zuletzt hochgeladen:",
    fileUploadSelectedUploaded: "Die ausgewählten wurden bereits hochgeladen:",
    buttonNext: "Nächster",
    buttonPrevious: "Bisherige",
    buttonSave: "sparen",
    buttonCancel: "Stornieren",
    buttonSubmit: "einreichen",
    buttonConfirm: "Bestätigen",
    buttonYes: "Ja",
    buttonNo: "Nein",
    buttonClose: "Schließen",
    linkPrivacyStatement: "Privatsphäre Erklärung",
    linkComplaintsPolicy: "Beschwerdepolitik",
    validationRequired: "Erforderlich",
    validationInvalid: "Ungültig",
    labelUploadPrefix: "Hochladen",
    labelUploadPostfix: "",
    labelChange: "Veränderung",
    labelView: "Ansehen",
    browserNavigationMessage: "Nicht gespeicherte Änderungen gehen verloren"
};