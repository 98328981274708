export default {
    labelSavedApplicationReference: "مرجع التطبيق المحفوظ:",
    labelLanguage: "لغة",
    messageWarningBookingHeading1: "تم إلغاء الحجز:",
    messageWarningBookingHeading2: "تأجيل / إعادة جدولة الحجز:",
    messageWarningBookingBody1: "إذا تم إلغاء حجزك من قبل المنظم أو شركة الطيران ، فلن يتمكن هذا الفريق من مساعدتك في استرداد الأموال. ستحتاج إلى الاتصال بفريق خدمة العملاء مباشرة ؛ راجع تأكيد الحجز أو موقع الويب للحصول على تفاصيل الاتصال.",
    messageWarningBookingBody2: "إذا تم تأجيل حجزك ، فسيتم ترحيل شروطك القابلة للاسترداد الممتدة إلى التاريخ الجديد. إذا كان لديك سبب وجيه لاسترداد الأموال بموجب شروطنا ، فيرجى إكمال النموذج بالأدلة الداعمة الصحيحة.",
    messageWarningBookingBody3: "يرجى ملاحظة ما يلي: يمكن لهذه الخدمة أن توفر فقط المبالغ المستردة للأسباب المدرجة في صياغتنا ، والمتوفرة في البريد الإلكتروني لتأكيد الحجز الخاص بك.",
    messageWarningBookingBody4: "إذا لم تتمكن من إجراء الحجز لأسباب خارجة عن شروطنا ، فلن نتمكن من مساعدتك. ستحتاج إلى الاتصال بفريق خدمة العملاء الخاص بك مباشرةً حيث قد يتمكنون من المساعدة ؛ راجع تأكيد الحجز أو موقع الويب للحصول على تفاصيل الاتصال.",
    linkWarningBooking: "هذا الرابط",
    formSectionNavigatorStep1: "الخطوة ١",
    formSectionNavigatorStep2: "الخطوة ٢",
    formSectionNavigatorStep3: "الخطوه ٣",
    formSectionNavigatorStep1Text: "تفاصيل الاتصال والحجز",
    formSectionNavigatorStep2Text: "سبب استرداد",
    formSectionNavigatorStep3Text: "تفاصيل الدفع",
    messageBookingMatchedAndFound: "إذا كان مرجع الحجز الخاص بك مرتبطًا بتطبيق موجود، فسنرسل إليك بريدًا إلكترونيًا يحتوي على رابط للوصول إلى طلبك",
    messageBookingMatchedAndFoundCompleted: "تم بالفعل الموافقة على طلب استرداد الخاص بك! إذا كانت لديك أي مشاكل في الدفع ، فيرجى الاتصال بـ:",
    applicationDeclinedMessage: "تم رفض طلبك وأغلق الآن.",
    messageBookingNotFound1: "لا يمكننا العثور على حجز بهذا المرجع. يرجى التحقق من البريد الإلكتروني الخاص بتأكيد الحجز وإعادة الدخول.لا يمكننا العثور على حجزك باستخدام المرجع الذي قدمته. يرجى التحقق من البريد الإلكتروني الخاص بتأكيد الحجز وإعادة الدخول.",
    messageBookingNotFound2: "لا تقلق ، إذا لم يعمل مرجع الحجز الخاص بك ، يمكنك تحديد البحث عن الحجزلا تقلق ، إذا لم يعمل مرجع الحجز الخاص بك ، يمكنك تحديد البحث عن الحجز وإدخال التفاصيل الخاصة بك. عند إكمال النموذج ، يرجى تحميل نسخة من البريد الإلكتروني لتأكيد الحجز لمساعدتنا في تحديد تفاصيل حجزك.",
    messageLocatingBooking: "تحديد موقع الحجز الخاص بك ...",
    messageLoadingBank: "جارٍ تحميل المعلومات المصرفية ...",
    messageFailCurrencyLookup: "خطأ في استرداد العملة",
    messageSavingApplication: "جاري حفظ التطبيق الخاص بك ...",
    messageSavedApplication: "تم حفظ تطبيق الاسترداد بنجاح",
    messageFailSavedApplication: "فشل حفظ تطبيق استرداد الأموال",
    messageSendingEmailReminder: "يعالج...",
    messageErrorLocationDetails: "خطأ في استرداد تفاصيل الموقع",
    messageFailFormSubmission: "فشل إرسال النموذج",
    messageApplicationCannotBeFound: "لا يمكن العثور على التطبيق",
    messageApplicationRetrievalError: "خطأ في استرداد التطبيق",
    messageSubmittingApplication: "تقديم الطلب ...",
    messageEventDateLimitError: "سبب الإخطار خارج المهلة المحددة",
    messageAllTicketsAssigned: "تم تخصيص جميع التذاكر لتطبيق استرداد آخر.",
    selectPrefix: "يختار",
    selectPostfix: "",
    fileUpload: "انقر هنا للتحميل",
    fileUploadAcceptedFilesMessage: "نحن نقبل فقط ملفات PDF وتنسيقات الصور من .png ، .jpeg ، .heic ، .webp",
    fileUploadNoFiles: "۰ ملفات محددة",
    fileUploadPreviouslyUploaded: "حملت سابقا:",
    fileUploadSelectedUploaded: "تم بالفعل تحميل المحدد:",
    buttonNext: "التالي",
    buttonPrevious: "سابق",
    buttonSave: "يحفظ",
    buttonCancel: "يلغي",
    buttonSubmit: "يقدم",
    buttonConfirm: "يتأكد",
    buttonYes: "نعم",
    buttonNo: "لا",
    buttonClose: "قريب",
    linkPrivacyStatement: "بيان الخصوصية",
    linkComplaintsPolicy: "سياسة الشكاوى",
    validationRequired: "مطلوب",
    validationInvalid: "غير صالح",
    labelUploadPrefix: "تحميل",
    labelUploadPostfix: "",
    labelChange: "تغيير",
    labelView: "منظر",
    browserNavigationMessage: "ستفقد التغييرات غير المحفوظة"
};