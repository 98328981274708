export default {
    messageQuestionService: "Bu hizmet yalnızca başvuru formunu doldururken sorun yaşıyorsanız kullanım içindir, burada başka soruları yanıtlayamayız.",
    labelName: "İsim",
    labelReference: "Rezervasyon Referansı",
    labelPhone: "Telefon numarası",
    labelEmail: "E-posta adresi",
    labelQuery: "Senin sorun",
    labelAskQuestion: "Bize buradan bir soru gönderin ...",
    buttonOpenModal: "Bir soru sor",
    title: "Bir soru sor",
    modalTitle: "4. Formla ilgili teknik bir sorununuz mu var?",
    toastSuccess: "Sorunuzu aldık ve 24 saat içinde sizinle iletişime geçeceğiz",
    toastError: "İsteğinizle ilgili bir sorun oluştu, lütfen tekrar deneyin",
    nameValidation: "İsim gerekli",
    referenceValidation: "Referans gerekli",
    questionValidation: "Sorunuz gerekli"
}