export default {
    labelSavedApplicationReference: "Ref. do aplicativo salvo:",
    labelLanguage: "Língua",
    messageWarningBookingHeading1: "Reserva cancelada:",
    messageWarningBookingHeading2: "Reserva adiada / reprogramada:",
    messageWarningBookingBody1: "Se sua reserva foi cancelada pelo organizador ou pela companhia aérea, esta equipe não poderá ajudá-lo com o reembolso. Você precisará entrar em contato diretamente com a equipe de atendimento ao cliente; consulte a confirmação da reserva ou o site para obter os detalhes de contato.",
    messageWarningBookingBody2: "Se sua reserva foi adiada, seus termos de reembolso estendidos serão transferidos para a nova data. Se você tiver um motivo válido para o reembolso de acordo com nossos termos, preencha o formulário com as evidências corretas.",
    messageWarningBookingBody3: "Atenção: Este serviço só pode fornecer reembolsos pelos motivos incluídos em nosso texto, disponível em seu e-mail de confirmação de reserva.",
    messageWarningBookingBody4: "Se você não puder fazer a reserva por motivos fora de nossos termos, não poderemos ajudar. Você precisará entrar em contato diretamente com sua equipe de atendimento ao cliente, pois eles podem ajudar; veja sua confirmação de reserva ou site para detalhes de contato.",
    linkWarningBooking: "aqui",
    formSectionNavigatorStep1: "Etapa 1",
    formSectionNavigatorStep2: "Etapa 2",
    formSectionNavigatorStep3: "Etapa 3",
    formSectionNavigatorStep1Text: "Detalhes de contato e reserva",
    formSectionNavigatorStep2Text: "Motivo do reembolso",
    formSectionNavigatorStep3Text: "Detalhes de pagamento",
    messageBookingMatchedAndFound: "Se a sua referência de reserva estiver associada a uma candidatura existente, enviar-lhe-emos um e-mail contendo um link para aceder à sua candidatura.",
    messageBookingMatchedAndFoundCompleted: "Seu pedido de reembolso já foi aprovado! Se você tiver qualquer problema com o seu pagamento, entre em contato com:",
    applicationDeclinedMessage: "Seu aplicativo foi recusado e agora está fechado.",
    messageBookingNotFound1: "Não conseguimos encontrar uma reserva com essa referência. Por favor, verifique seu e-mail de confirmação de reserva e insira-o novamente.",
    messageBookingNotFound2: "Não se preocupe, se a sua referência de reserva não funcionar, você pode selecionar 'Encontrar reserva' e inserir seus dados. Ao preencher o formulário, carregue uma cópia do seu e-mail de confirmação da reserva para nos ajudar a localizar os detalhes da sua reserva.",
    messageLocatingBooking: "Localizando sua reserva... ",
    messageLoadingBank: "Carregando dados bancários...",
    messageFailCurrencyLookup: "Erro ao recuperar moeda",
    messageSavingApplication: "Salvando seu aplicativo ...",
    messageSavedApplication: "Solicitação de reembolso salva com sucesso",
    messageFailSavedApplication: "Falha ao salvar o pedido de reembolso",
    messageSendingEmailReminder: "Em processamento...",
    messageErrorLocationDetails: "Erro ao recuperar detalhes do local",
    messageFailFormSubmission: "Falha no envio do formulário",
    messageApplicationCannotBeFound: "O aplicativo não foi encontrado",
    messageApplicationRetrievalError: "Erro ao recuperar o aplicativo",
    messageSubmittingApplication: "Enviando inscrição ...",
    messageEventDateLimitError: "Motivo da notificação fora do limite de tempo",
    messageAllTicketsAssigned: "Todos os bilhetes foram atribuídos a outro pedido de reembolso.",
    selectPrefix: "Selecione",
    selectPostfix: "",
    fileUpload: "clique aqui para fazer o upload",
    fileUploadAcceptedFilesMessage: "Aceitamos apenas PDFs e formatos de imagem de .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 arquivos selecionados",
    fileUploadPreviouslyUploaded: "Carregado anteriormente:",
    fileUploadSelectedUploaded: "Os selecionados já foram carregados:",
    buttonNext: "Próximo",
    buttonPrevious: "Anterior",
    buttonSave: "Salve",
    buttonCancel: "Cancelar",
    buttonSubmit: "Enviar",
    buttonConfirm: "Confirmar",
    buttonYes: "Sim",
    buttonNo: "Não",
    buttonClose: "Fechar",
    linkPrivacyStatement: "Declaração de privacidade",
    linkComplaintsPolicy: "Política de reclamações",
    validationRequired: "Obrigatória",
    validationInvalid: "Inválida",
    labelUploadPrefix: "Envio",
    labelUploadPostfix: "",
    labelChange: "Mudar",
    labelView: "Vista",
    browserNavigationMessage: "Alterações não salvas serão perdidas"
};