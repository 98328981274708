export default {
    messageQuestionService: "仅当您在填写申请表时遇到问题时，才可以使用该服务，我们无法在此处回答任何其他问题。",
    labelName: "姓名",
    labelReference: "预订参考",
    labelPhone: "电话号码",
    labelEmail: "电子邮件",
    labelQuery: "你的问题",
    labelAskQuestion: "在这里给我们发一个问题...",
    buttonOpenModal: "问一个问题",
    title: "问一个问题",
    modalTitle: "4.表格有技术问题吗？",
    toastSuccess: "我们已收到您的问题，我们将在24小时内与您联系",
    toastError: "您的请求出了点问题，请重试",
    nameValidation: "名称为必填项",
    referenceValidation: "需要参考",
    questionValidation: "您的问题是必需的"
}