export default {
    messageQuestionService: "Questo servizio è utilizzabile solo se hai problemi con la compilazione del modulo di domanda, non possiamo rispondere ad altre domande qui.",
    labelName: "Nome",
    labelReference: "Riferimento prenotazione",
    labelPhone: "Numero di telefono",
    labelEmail: "E-mail",
    labelQuery: "La tua domanda",
    labelAskQuestion: "Inviaci una domanda qui ...",
    buttonOpenModal: "Fai una domanda",
    title: "Fai una domanda",
    modalTitle: "4. Hai un problema tecnico con il modulo?",
    toastSuccess: "Abbiamo ricevuto la tua domanda e ti contatteremo entro 24 ore",
    toastError: "Qualcosa è andato storto con la tua richiesta, riprova",
    nameValidation: "Il nome è obbligatorio",
    referenceValidation: "È richiesta la referenza",
    questionValidation: "La tua domanda è obbligatoria"
}