export default {
    messageQuestionService: "This service is only for use if you are having a problem with completing the application form, we cannot reply to any other questions here.",
    labelName: "Name",
    labelReference: "Booking Reference",
    labelPhone: "Phone Number",
    labelEmail: "Email",
    labelQuery: "Your question",
    labelAskQuestion: "Send us a question here...",
    buttonOpenModal: "Ask a question",
    title: "Ask a question",
    modalTitle: "4. Got a technical problem with the form?",
    toastSuccess: "We have received your question and we will contact you within 24 hours",
    toastError: "Something went wrong with your request, please try again",
    nameValidation:"Name is required",
    referenceValidation:"Reference is required",
    questionValidation: "Your question is required"
}