import * as React from 'react';
import {
    TextField,
    InputAdornment
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {OpenInNew} from '@mui/icons-material';
import {
    Field,
    FieldProps
} from 'formik';
import {
    BorderRadiusInputField,
    MinWidthInputField
} from '../utils/constants-styling';
import {IInputProps} from './IInputProps';
import {isInvalid} from '../utils/validationHelpers';
import {useState} from 'react';
import {FormLabelHolder} from '../ui/FormLabelHolder';
import {IFormValues} from '../Interfaces/IFormValues';

interface TextInputProps extends IInputProps {
    customErrorMessage?: string;
    externalLink?: string;
    externalLinkText?: string;
    multiLine?: boolean;
    placeholder: string;
}

export const TextInput = (props: TextInputProps) => {

    const {
        controlId,
        customErrorMessage = null,
        disabled,
        externalLink = null,
        externalLinkText = null,
        hidden,
        label,
        maxLength,
        multiLine,
        onChange,
        placeholder,
        required,
        tooltip
    } = props;

    const [textInputValue, setTextInputValue] = useState(null);
    const [textCountValue, setTextCountValue] = useState(0);

    const handleOnBlur = (fieldProps: FieldProps<IFormValues> | FieldProps<IQueryNote>) => (event: React.FocusEvent<HTMLInputElement>) => {
        void fieldProps.form.setFieldValue(controlId, event.target.value);
        void fieldProps.form.setTouched({...fieldProps.form.touched, [controlId]: true}, false);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextInputValue(event.target.value);
        setTextCountValue(event.target.value.length);

        if (onChange && typeof (onChange) === 'function') {
            onChange(event);
        }
    }

    const errorMessage = (fieldProps: FieldProps<IFormValues> | FieldProps<IQueryNote>) => {
        if (fieldProps.meta.touched && fieldProps.meta.error && customErrorMessage) {
            return customErrorMessage;
        }

        if (fieldProps.meta.touched) {
            return fieldProps.meta.error;
        }
    }

    const ExternalLinkAdornment = () => {
        return (
            <>
                {externalLink !== null &&
                    <InputAdornment position='end'>
                        <a href={externalLink} target='_blank'>{externalLinkText}<OpenInNew/></a>
                    </InputAdornment>
                }
                {externalLink === null &&
                    null
                }
            </>
        );
    }

    return (
        <>
            {!hidden &&
                <Field name={controlId}>
                    {(fieldProps: FieldProps<IFormValues> | FieldProps<IQueryNote>) => (
                        <Grid container alignItems='center' columns={{xs: 1, sm: 12, md: 12}} pb={2}>
                            <FormLabelHolder
                                label={label}
                                required={required}
                                tooltip={tooltip}
                            />
                            <Grid size={{xs: 6, md: 5}}>
                                <TextField
                                    name={controlId}
                                    multiline={multiLine}
                                    variant='outlined'
                                    type='text'
                                    size='small'
                                    placeholder={placeholder}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur(fieldProps)}
                                    value={textInputValue ?? fieldProps.form.getFieldProps(controlId).value}
                                    error={isInvalid(fieldProps)}
                                    fullWidth
                                    rows={multiLine ? 6 : 0}
                                    helperText={errorMessage(fieldProps)}
                                    disabled={disabled}
                                    sx={{
                                        'fieldset': {
                                            borderRadius: BorderRadiusInputField,
                                            minWidth: MinWidthInputField
                                        }
                                    }}
                                    slotProps={{
                                        input: {
                                            endAdornment: <ExternalLinkAdornment/>
                                        },
                                        htmlInput: {
                                            maxLength: maxLength
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Field>
            }
        </>
    );
}