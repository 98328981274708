export default {
    messageQuestionService: "Acest serviciu este utilizat numai dacă aveți o problemă cu completarea formularului de cerere, nu putem răspunde la alte întrebări aici.",
    labelName: "Nume",
    labelReference: "Referință de rezervare",
    labelPhone: "Număr de telefon",
    labelEmail: "E-mail",
    labelQuery: "Întrebarea ta",
    labelAskQuestion: "Trimite-ne o întrebare aici...",
    buttonOpenModal: "Pune o intrebare",
    title: "Pune o intrebare",
    modalTitle: "4. Ai o problemă tehnică cu formularul?",
    toastSuccess: "Am primit întrebarea dvs. și vă vom contacta în termen de 24 de ore",
    toastError: "A apărut o eroare la solicitarea dvs., vă rugăm să încercați din nou",
    nameValidation:"Numele este obligatoriu",
    referenceValidation:"Este necesară referința",
    questionValidation: "Întrebarea dvs. este obligatorie"
}