export default {
    messageQuestionService: "Layanan ini hanya untuk digunakan jika Anda mengalami masalah dalam mengisi formulir aplikasi, kami tidak dapat menjawab pertanyaan lain di sini.",
    labelName: "Nama",
    labelReference: "Referensi pemesanan",
    labelPhone: "Nomor telepon",
    labelEmail: "Surel",
    labelQuery: "Pertanyaanmu",
    labelAskQuestion: "Kirimkan pertanyaan kepada kami di sini ...",
    buttonOpenModal: "Berikan pertanyaan",
    title: "Berikan pertanyaan",
    modalTitle: "4. Ada masalah teknis dengan formulir?",
    toastSuccess: "Kami telah menerima pertanyaan Anda dan kami akan menghubungi Anda dalam waktu 24 jam",
    toastError: "Ada yang tidak beres dengan permintaan Anda, coba lagi",
    nameValidation: "Nama harus diisi",
    referenceValidation: "Referensi diperlukan",
    questionValidation: "Pertanyaan Anda diperlukan",
}