export default {
    labelSavedApplicationReference: "Aplicație salvată Ref:",
    labelLanguage: "Limba",
    messageWarningBookingHeading1: "Rezervare anulată:",
    messageWarningBookingHeading2: "Rezervarea amânată/reprogramată:",
    messageWarningBookingBody1: "Dacă rezervarea dvs. a fost anulată de către organizator sau compania aeriană, această echipă nu vă va putea ajuta cu o rambursare. Va trebui să contactați direct echipa de service pentru clienți; consultați confirmarea rezervării sau site-ul web pentru detalii de contact.",
    messageWarningBookingBody2: "Dacă rezervarea dvs. a fost amânată, termenii rambursabil extins se vor reporta la noua dată. Dacă aveți un motiv valid pentru rambursare în conformitate cu termenii noștri, vă rugăm să completați formularul cu dovezile justificative corecte.",
    messageWarningBookingBody3: "Vă rugăm să rețineți: acest serviciu poate oferi rambursări numai pentru motivele incluse în formularea noastră, disponibile în e-mailul de confirmare a rezervării.",
    messageWarningBookingBody4: "Dacă nu puteți face rezervarea din motive în afara termenilor noștri, nu vă vom putea ajuta. Va trebui să contactați direct echipa de asistență pentru clienți, deoarece acestea vă pot ajuta; consultați confirmarea rezervării sau site-ul web pentru detalii de contact.",
    linkWarningBooking: "aici",
    formSectionNavigatorStep1: "Pasul 1",
    formSectionNavigatorStep2: "Pasul 2",
    formSectionNavigatorStep3: "Pasul 3",
    formSectionNavigatorStep1Text: "Detalii de contact și rezervare",
    formSectionNavigatorStep2Text: "Motivul rambursării",
    formSectionNavigatorStep3Text: "Informații de plată",
    messageBookingMatchedAndFound: "Dacă referința rezervării dvs. este asociată cu o aplicație existentă, vă vom trimite un e-mail care conține un link pentru a accesa aplicația dvs",
    messageBookingMatchedAndFoundCompleted: "Cererea dvs. de rambursare a fost deja aprobată! Dacă aveți probleme cu plata, vă rugăm să contactați:",
    applicationDeclinedMessage: "Aplicația dvs. a fost respinsă și acum este închisă.",
    messageBookingNotFound1: "Nu putem găsi o rezervare cu această referință. Vă rugăm să verificați e-mailul de confirmare a rezervării și să reintroduceți.",
    messageBookingNotFound2: "Nu vă faceți griji, dacă referința rezervării dvs. nu funcționează, puteți selecta „Găsiți rezervarea” și introduceți detaliile dvs. Când completați formularul, vă rugăm să încărcați o copie a e-mailului de confirmare a rezervării pentru a ne ajuta să găsim detaliile rezervării.",
    messageLocatingBooking: "Se localizează rezervarea...",
    messageLoadingBank: "Se încarcă informațiile bancare...",
    messageFailCurrencyLookup: "Eroare la preluarea monedei",
    messageSavingApplication: "Se salvează aplicația...",
    messageSavedApplication: "Cererea de rambursare a fost salvată",
    messageFailSavedApplication: "Cererea de rambursare nu a putut fi salvată",
    messageSendingEmailReminder: "Prelucrare...",
    messageErrorLocationDetails: "Eroare la preluarea detaliilor locației",
    messageFailFormSubmission: "Trimiterea formularului a eșuat",
    messageApplicationCannotBeFound: "Aplicația nu a putut fi găsită",
    messageApplicationRetrievalError: "Eroare la preluarea aplicației",
    messageSubmittingApplication: "Se depun cererea...",
    messageEventDateLimitError: "Motivul notificării în afara termenului limită",
    messageAllTicketsAssigned: "Toate biletele au fost atribuite unei alte cereri de rambursare.",
    selectPrefix: "Selectați",
    selectPostfix: "",
    fileUpload: "faceți clic aici pentru a încărca",
    fileUploadAcceptedFilesMessage: "Acceptăm numai PDF-uri și formate de imagine .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 fișiere selectate",
    fileUploadPreviouslyUploaded: "Încărcat Anterior:",
    fileUploadSelectedUploaded: "Cele selectate au fost deja încărcate:",
    buttonNext: "Următorul",
    buttonPrevious: "Anterior",
    buttonSave: "Salvați",
    buttonCancel: "Anulare",
    buttonSubmit: "Trimite",
    buttonConfirm: "A confirma",
    buttonYes: "da",
    buttonNo: "Nu",
    buttonClose: "Închide",
    linkPrivacyStatement: "Declarație de confidențialitate",
    linkComplaintsPolicy: "Politica de reclamații",
    validationRequired: "Necesar",
    validationInvalid: "Invalid",
    labelUploadPrefix: "Încărcați",
    labelUploadPostfix: "",
    labelChange: "Schimbare",
    labelView: "Vedere",
    browserNavigationMessage: "Modificările nesalvate se vor pierde"
};