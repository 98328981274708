export default {
    labelBookingCancelledPostponedHeading: "Ar jūsų užsakymas buvo atšauktas arba atidėtas? Skaityti daugiau",
    labelBookingCancelledPostponedText1: "Spustelėti",
    labelBookingCancelledPostponedText2: "daugiau informacijos.",
    labelNewRefundHeading: "Naujas prašymas grąžinti pinigus?",
    labelNewRefundText: "Įveskite savo užsakymo nuorodą čia...",
    placeholderBookingReference: "Užsakymo nuoroda",
    validationBookingReference: "Užsakymo nuoroda yra būtina",
    validationEmailAsBookingReference: "El. pašto adresas nepriimamas kaip užsakymo nuoroda",
    buttonFindBooking: "Rasti užsakymą",
    labelEmailHeading: "Jau pradėjote?",
    labelEmailText: "Siųskite priminimą el. paštu, kad gautumėte savo paraišką.",
    placeholderEmail: "El paštas",
    buttonEmailReminder: "El. pašto priminimas",
    validationCustomerName: "Kliento vardas yra būtinas",
    labelTypeOfBooking: "Užsakymo tipas",
    validationTypeOfBooking: "Užsakymo tipas yra būtinas",
    labelEventName: "Įvykio pavadinimas",
    labelVenueName: "Vietos pavadinimas",
    labelFlightNumber: "Skrydžio numeris",
    labelOriginAirport: "Kilmės oro uostas",
    labelHotelName: "Apgyvendinimo įstaigos pavadinimas",
    labelHotelLocation: "Apgyvendinimo vieta",
    labelCurrency: "Valiuta",
    validationCurrency: "Reikalinga valiuta",
    validationCountryCurrency: "Pasirinkta valiuta turi būti jūsų gyvenamosios šalies valiuta",
    validationOfflinePayment: "Mes negalime apdoroti lėšų grąžinimo už jūsų pasirinktą valiutą ir šalį, pasirinkite alternatyvas",
    labelBookingValue: "Užsakymo vertė",
    validationBookingValue: "Užsakymo vertė yra būtina",
    validationBookingValueAmount: "Turi būti didesnis už 0",
    labelDateOfPurchase: "Pirkimo data",
    validationDateOfPurchase: "Būtina pirkimo data",
    labelEmailAddress: "El. pašto adresas",
    validationEmailAddress: "El. pašto adresas yra būtinas",
    validationEmailAddressFormat: "Netinkamas el. pašto formatas",
    labelConfirmEmailAddress: "Patvirtinkite el. pašto adresą",
    validationConfirmEmailAddressFormat: "Netinkamas el. pašto formatas",
    validationConfirmEmailAddress: "Patvirtinimo el. pašto adresas yra būtinas",
    validationConfirmEmailAddressMatch: "El. pašto adresai nesutampa",
    validationEmailDomain: "Šis el. pašto paslaugų teikėjas nepriimamas, naudokite alternatyvą",
    validationDateOfEvent: "Būtina įvykio data",
    labelHomeCountry: "Gimtoji šalis",
    validationHomeCountry: "Gimtoji šalis yra būtina",
    labelContactNumberCountryCode: "Kontaktinio numerio šalies kodas",
    validationContactNumberCountryCode: "Būtinas šalies rinkimo kodas",
    labelContactNumber: "Kontaktinis numeris",
    validationContactNumber: "Būtinas kontaktinis numeris",
    labelAddressLine1: "1 adreso eilutė",
    labelAddressLine2: "2 adreso eilutė",
    labelCity: "Miestas",
    labelZipCode: "Pašto indeksas",
    labelRegion: "Regionas",
    validationRegion: "Regionas yra būtinas",
    labelNumberOfTicketsToRefund: "Grąžinamų bilietų skaičius",
    validationNumberOfTicketsToRefund: "Reikalingas grąžinamų bilietų skaičius",
    validationNumberOfTicketsToRefundValue: "Turi būti didesnis už 0",
    labelCostPerTicket: "Kiekvieno bilieto kaina",
    validationCostPerTicket: "Kaina už bilietą yra būtina",
    validationCostPerTicketValue: "Turi būti didesnis už 0",
    labelTotalRefundAmount: "Bendra grąžinamosios išmokos suma",
    validationTotalRefundAmount: "Būtina bendra grąžinamos sumos suma",
    validationTotalRefundAmountValue: "Turi būti didesnis už 0",
    validationTotalRefundGreaterThanBookingValue: "Bendra grąžinamos sumos suma negali būti didesnė už aukščiau nurodytą Užsakymo vertę",
    labelBookingConfirmationFiles: "Įkelti užsakymo patvirtinimą",
    validationBookingConfirmationFiles: "Pridėkite bent vieną failą arba spustelėkite Įrašyti ir grįžkite vėliau, kai turėsite dokumentus",
    validationEvidenceFilesPopup: "Prieš pateikiant įrodymus reikia juos įkelti. Jei šiuo metu neturite įrodymų, išsaugokite paraišką ir grįžkite, kai tik turėsite. Mes priimame elektronines kopijas, skaitytuvų kopijas ir nuotraukas.",
    validationBookingConfirmationFilesPopup: "Prieš pateikiant reikia įkelti užsakymo patvirtinimą. Jei šiuo metu neturite užsakymo patvirtinimo, išsaugokite paraišką ir grįžkite, kai tik turėsite. Mes priimame elektronines kopijas, skaitytuvų kopijas ir nuotraukas.",
    alertUploadDocuments: "Įkelkite atitinkamus dokumentus, išsamiai aprašytus el. laiške, ir pereikite prie 3 veiksmo.",
    labelRefundReasonCategory: "Grąžinimo priežastis",
    validationRefundReasonCategory: "Būtina grąžinimo priežastis",
    labelDateOfIncident: "Incidento data",
    labelAdditionalComments: "Papildomos pastabos",
    tooltipAdditionalComments: "Papildomos pastabos",
    validationAdditionalComments: "Reikalingi papildomi komentarai",
    labelUnableToNotifyWithinTimeLimit: "Nepavyko pranešti per 60 dienų nuo",
    labelReasonForNotificationOutsideTimeLimit: "Paaiškinimas apie pranešimą pasibaigus nustatytam terminui",
    validationReasonForNotificationOutsideTimeLimit: "A Pranešimo priežastis po 60 dienų",
    labelRefundReasonFiles: "Įkelti įrodymus",
    validationRefundReasonFiles: "Pridėkite bent vieną failą arba spustelėkite Įrašyti ir grįžkite vėliau, kai turėsite dokumentus",
    validationTextScript: "Turi atitikti lotyniškus rašmenis",
    optionTypeOfBooking1: "Įvykis",
    optionTypeOfBooking2: "Kelionių",
    optionTypeOfBooking3: "Skrydis",
    optionTypeOfBooking4: "Apgyvendinimas",
    optionTypeOfBooking5: "Perduoti",
    optionTypeOfBooking6: "Automobilių stovėjimo aikštelė",
    optionTypeOfBooking7: "Kitos kelionės",
    labelEventTypeDate: "Įvykio data",
    labelEventTypeTour: "Ekskursijos data",
    labelEventTypeFlight: "Skrydžio data",
    labelEventTypeCheckIn: "Įsiregistravimo data",
    labelEventTypeTransfer: "Perkėlimo data",
    labelEventTypeParking: "Stovėjimo data",
    labelEventTypeOtherTravel: "Kelionės data",
    optionRefundReason11: "Nepalankūs orai",
    optionRefundReason10: "Ginkluotųjų pajėgų ir pagalbos tarnybų atšaukimas",
    optionRefundReason15: "Egzaminų datų pakeitimai",
    optionRefundReason18: "Teismo šaukimas",
    optionRefundReason17: "COVID-19 kelionių apribojimai",
    optionRefundReason19: "Teigiamas COVID-19 testas",
    optionRefundReason20: "Operatoriaus / organizatoriaus atšauktas užsakymas",
    optionRefundReason21: "Operatoriaus / organizatoriaus užsakymas perplanuotas",
    optionRefundReason22: "Užsakyta neteisinga data",
    optionRefundReason23: "Užsakyti neteisingi bilietai",
    optionRefundReason24: "Užsisakė per daug bilietų",
    optionRefundReason4: "Mirtis",
    optionRefundReason16: "Avarinės aplinkybės",
    optionRefundReason14: "Vyriausybės draudimas keliauti",
    optionRefundReason9: "Namų avarinė situacija",
    optionRefundReason1: "Liga / sužalojimas / nelaimingas atsitikimas",
    optionRefundReason8: "Prisiekusiųjų tarnyba",
    optionRefundReason7: "Mechaninis gedimas",
    optionRefundReason2: "Ankstesnė sveikatos būklė",
    optionRefundReason3: "Nėštumo komplikacija",
    optionRefundReason5: "Viešojo transporto gedimas",
    optionRefundReason6: "Skrydžio sutrikimas",
    optionRefundReason13: "Dokumento (-ų) vagystė",
    optionRefundReason12: "Darbo perkėlimas",
    optionRefundReason25: "Atleidimas iš darbo vietos",
    optionRefundReason26: "Bandymo parinktis",
    optionRefundReasonConfirm1: "Ne",
    optionRefundReasonConfirm2: "Taip",
    optionRefundReasonArmedForces1: "Armija",
    optionRefundReasonArmedForces2: "Laivynas",
    optionRefundReasonArmedForces3: "Karinės oro pajėgos",
    optionRefundReasonArmedForces4: "Specialiosios pajėgos",
    optionRefundReasonArmedForces5: "Marines",
    optionRefundReasonArmedForces6: "Ugnis",
    optionRefundReasonArmedForces7: "Greitosios pagalbos",
    optionRefundReasonArmedForces8: "Gydytojas / slaugytoja",
    optionRefundReasonArmedForces9: "Dantų gydytojas",
    optionRefundReasonArmedForces10: "Kitas",
    optionRefundReasonCourtSummons1: "Baudžiamasis persekiojimas",
    optionRefundReasonCourtSummons2: "Civilinė byla",
    optionRefundReasonCourtSummons3: "Liudytojas baudžiamojoje byloje",
    optionRefundReasonCourtSummons4: "Liudytojas civilinėje byloje",
    optionRefundReasonCourtSummons5: "Ieškovas",
    optionRefundReasonCourtSummons6: "Atsakovė",
    optionRefundReasonCourtSummons7: "Kitas",
    optionRefundReasonDeath1: "Vyras",
    optionRefundReasonDeath2: "Pati",
    optionRefundReasonDeath3: "Skambėti",
    optionRefundReasonDeath4: "Duktė",
    optionRefundReasonDeath5: "Tėvas",
    optionRefundReasonDeath6: "Motina",
    optionRefundReasonDeath7: "Brolis",
    optionRefundReasonDeath8: "Sesuo",
    optionRefundReasonDeath9: "Senelis",
    optionRefundReasonDeath10: "Močiutė",
    optionRefundReasonDeath11: "Kitas",
    optionRefundReasonHomeEmergency1: "Ugnis",
    optionRefundReasonHomeEmergency2: "Potvynis",
    optionRefundReasonHomeEmergency3: "Apiplėšimas",
    optionRefundReasonHomeEmergency4: "Vandalizmas",
    optionRefundReasonHomeEmergency5: "Smurtas šeimoje",
    optionRefundReasonHomeEmergency6: "Pagrobti",
    optionRefundReasonHomeEmergency7: "Kitas",
    optionRefundReasonCOVID19Person1: "Save",
    optionRefundReasonCOVID19Person2: "Kitas užsakyme dalyvaujantis asmuo",
    optionRefundReasonCOVID19Person3: "Asmuo mano namų ūkyje",
    optionRefundReasonCOVID19Person4: "Kitas",
    optionRefundReasonCOVID19TestType1: "PGR testas",
    optionRefundReasonCOVID19TestType2: "RAT testas",
    optionRefundReasonCOVID19TestType3: "Šoninio srauto bandymas",
    optionRefundReasonCOVID19TestType4: "Savarankiškai atliktas testas",
    optionRefundReasonCOVID19TestType5: "Nėra testo, bet turiu simptomų",
    optionRefundReasonCOVID19TestType6: "Kitas",
    optionRefundReasonBreakdown1: "Suskirstymas",
    optionRefundReasonBreakdown2: "Nelaimingas atsitikimas",
    optionRefundReasonBreakdown3: "Ugnis",
    optionRefundReasonBreakdown4: "Vagystė",
    optionRefundReasonPublicTransportType1: "Traukinys",
    optionRefundReasonPublicTransportType2: "Autobusas",
    optionRefundReasonPublicTransportType3: "Tramvajus",
    optionRefundReasonPublicTransportType4: "Valtis",
    optionRefundReasonDocumentType1: "Pasas",
    optionRefundReasonDocumentType2: "Kelionės leidimas",
    optionRefundReasonDocumentType3: "Nacionalinis asmens tapatybės dokumentas",
    optionRefundReasonDocumentType4: "Bilietus",
    optionRefundReasonWorkRelocationType1: "Laikinas",
    optionRefundReasonWorkRelocationType2: "Nuolatinis",
    optionRefundReasonWorkRelocationTemporary1: "0-3 mėnesiai",
    optionRefundReasonWorkRelocationTemporary2: "3-6 mėnesiai",
    optionRefundReasonWorkRelocationTemporary3: "Per 6 mėn.",
    optionRefundReasonBookingRelationshipType1: "Kažkas užsakyme",
    optionRefundReasonBookingRelationshipType2: "Tiesioginis kieno nors giminaitis užsakyme",
    optionEmploymentType1: "Visą darbo dieną",
    optionEmploymentType2: "Ne visą darbo dieną",
    optionEmploymentType3: "Sutartis",
    optionEmploymentLengthType1: "0-1 metai",
    optionEmploymentLengthType2: "1-2 metai",
    optionEmploymentLengthType3: "Daugiau nei 2 metai",
    textRefundEvidenceHeading: "Pateikite šiuos įrodymus:",
    textRefundEvidence1: "Aviakompanijos patvirtinimas",
    textRefundEvidence2: "Visi tinkami patvirtinamieji įrodymai",
    textRefundEvidence3: "Gedimo paslaugos iškvietimo patvirtinimas",
    textRefundEvidence4: "Mirties liudijimas",
    textRefundEvidence5: "Aviakompanijos patvirtinimo atšaukimas",
    textRefundEvidence6: "Orų ataskaita",
    textRefundEvidence7: "Užsakymo agento korespondencija, patvirtinanti, kad nėra galimybės iš naujo išduoti bilietų",
    textRefundEvidence8: "Teismo raštas",
    textRefundEvidence9: "Medicininė pažyma / gydytojo pastaba",
    textRefundEvidenceDescription9: "Patvirtinant išsamią informaciją apie ligą ar sužalojimą, datą, kada jis įvyko pirmą kartą, kad dėl to negalite dalyvauti užsakyme.",
    textRefundEvidence10: "Darbdavio rašto patvirtinimas",
    textRefundEvidence11: "Draudimo išmokų korespondencija",
    textRefundEvidence12: "Darbdavio raštas",
    textRefundEvidence13: "Nacionalinės vyriausybės interneto svetainės patvirtinimas",
    textRefundEvidence14: "Pranešimas apie egzamino pakeitimą",
    textRefundEvidence15: "Policijos ataskaita",
    textRefundEvidence16: "Policijos ataskaita ir nuorodos numeris",
    textRefundEvidence17: "Transporto paslaugų teikėjo patvirtinimas",
    textRefundEvidence18: "Įspėjimas apie kelionę",
    textRefundEvidence20: "COVID-19 tyrimo pažymėjimas",
    textRefundEvidence24: "Vyriausybės izoliacijos pranešimas",
    textRefundEvidence25: "Vadovaujančio pareigūno laiškas",
    textRefundEvidence26: "Draudikų raštas",
    textRefundEvidence27: "Adreso įrodymas",
    textRefundEvidence28: "Giminystės ryšio su mirusiuoju įrodymas",
    textRefundEvidence29: "Ryšio su nukentėjusiu / sergančiu asmeniu įrodymas",
    textRefundEvidence30: "Algalapis",
    tooltipBookingReference: "Norėdami nustatyti savo užsakymo nuorodą, patikrinkite savo užsakymo patvirtinimą ir peržiūrėkite skyrių apie mūsų išplėstinę paslaugą.",
    tooltipCustomerName: "Vardas ir pavardė, kurie buvo naudojami užsakymui atlikti.",
    tooltipTypeOfBooking: "Užsakymo tipas, už kurį norėtumėte susigrąžinti pinigus.",
    tooltipEventName: "Renginio pavadinimas.",
    tooltipVenueName: "Vietos pavadinimas.",
    tooltipFlightNumber: "Skrydžio numeris.",
    tooltipOriginAirport: "Išvykimo oro uostas.",
    tooltipHotelName: "Apgyvendinimo įstaigos pavadinimas.",
    tooltipHotelLocation: "Apgyvendinimo vieta.",
    tooltipCurrency: "Valiuta, kuria buvo mokama už užsakymą.",
    tooltipBookingValue: "Visa kaina, kurią sumokėjote už užsakymą.",
    tooltipDateOfPurchase: "Užsakymo data.",
    tooltipEmailAddress: "Naudokite galiojantį el. pašto adresą. Šį el. laišką naudojame korespondencijai prašymo grąžinti pinigus metu.",
    tooltipConfirmEmailAddress: "Naudokite tą patį el. pašto adresą, kaip nurodyta aukščiau, jis skirtas patvirtinimui.",
    tooltipHomeCountry: "Tai padės mums paskutiniame etape pateikti teisingą banko informaciją.",
    tooltipContactNumberCountryCode: "Kontaktinis numeris, šalies kodas.",
    tooltipContactNumber: "Naudokite galiojantį numerį. Mes naudojame tai korespondencijai prašymo grąžinti pinigus metu.",
    tooltipAddressLine1: "Atminkite, kad jūsų adresas bus naudojamas tvirtinant jūsų banko sąskaitą.",
    tooltipAddressLine2: "Antroji adreso eilutė.",
    tooltipCity: "Jūsų gimtasis miestas, duomenys gali būti naudojami banko duomenims patvirtinti.",
    tooltipZipCode: "Pašto indeksas / pašto kodas.",
    tooltipRegion: "Jūsų gimtojo miesto regionas, išsami informacija gali būti naudojama banko duomenims patvirtinti.",
    tooltipNumberOfTicketsToRefund: "Bilietų, kuriuos norėtumėte grąžinti, skaičius.",
    tooltipTotalRefundAmount: "Tai negali būti didesnė už bendrą užsakymo vertę, negalėsite pateikti paraiškos, jei taip.",
    tooltipBookingConfirmationFiles: "Viso užsakymo patvirtinimo ekrano kopija / priedas, kurį paprastai galite rasti savo el. laiškuose.",
    tooltipRefundReasonCategory: "Pasirinkite pinigų grąžinimo priežastį.",
    tooltipDateOfIncident: "Data, kada įvyko incidentas, turėjęs įtakos jūsų užsakymui.",
    tooltipRefundExplanation: "Pateikite kuo daugiau informacijos, pagrindžiančios prašymą grąžinti pinigus.",
    tooltipUnableToNotifyWithinTimeLimit: "Nepavyksta pranešti per 60 dienų terminą.",
    tooltipReasonForNotificationOutsideTimeLimit: "Pranešimas už termino ribų paaiškinimas.",
    tooltipRefundReasonFiles: "Įkelkite visus reikiamus dokumentus.",
    tooltipEventTypeDate: "Faktinio įvykio / užsakymo data.",
    tooltipEventTypeTour: "Faktinės ekskursijos data.",
    tooltipEventTypeFlight: "Faktinio skrydžio data.",
    tooltipEventTypeCheckIn: "Faktinio įsiregistravimo data.",
    tooltipEventTypeTransfer: "Faktinio perdavimo data.",
    tooltipEventTypeParking: "Faktinio stovėjimo data.",
    tooltipEventTypeOtherTravel: "Faktinės kelionės pradžios data.",
};