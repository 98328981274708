export default {
    messageQuestionService: "Бұл қызмет тек өтініш формасын толтыруда қиындық туындаса ғана пайдалануға арналған, біз мұнда басқа сұрақтарға жауап бере алмаймыз..",
    labelName: "Аты",
    labelReference: "Брондау анықтамасы",
    labelPhone: "Телефон нөмірі",
    labelEmail: "Электрондық пошта",
    labelQuery: "Сіздің сұрағыңыз",
    labelAskQuestion: "Мұнда бізге сұрақ жіберіңіз...",
    buttonOpenModal: "Сұрақ қою",
    title: "Сұрақ қою",
    modalTitle: "4. Пішінде техникалық мәселе туындады?",
    toastSuccess: "Біз сіздің сұрағыңызды алдық және біз сізбен 24 сағат ішінде хабарласамыз",
    toastError: "Сұрауыңызда бірдеңе дұрыс болмады, әрекетті қайталаңыз",
    nameValidation:"Аты қажет",
    referenceValidation:"Анықтама қажет",
    questionValidation: "Сіздің сұрағыңыз қажет"
}