export default {
    labelSavedApplicationReference: "Salvestatud rakenduse viide:",
    labelLanguage: "Keel",
    messageWarningBookingHeading1: "Broneering tühistatud:",
    messageWarningBookingHeading2: "Broneering edasi lükatud / ümber planeeritud:",
    messageWarningBookingBody1: "Kui korraldaja või lennufirma on teie broneeringu tühistanud, ei saa see meeskond teid raha tagastamisel aidata. Peate otse ühendust võtma oma klienditeeninduse meeskonnaga; Vaadake oma broneeringu kinnitust või veebisaiti, et saada kontaktandmeid.",
    messageWarningBookingBody2: "Kui teie broneering on edasi lükatud, kantakse teie pikendatud tagastatavad tingimused üle uuele kuupäevale. Kui teil on meie tingimuste kohaselt mõjuv põhjus tagasimakse tegemiseks, täitke vorm õigete tõendavate dokumentidega.",
    messageWarningBookingBody3: "Pange tähele: See teenus võib pakkuda tagasimakseid ainult meie sõnastuses sisalduvatel põhjustel, mis on saadaval teie broneeringu kinnituse e-kirjas.",
    messageWarningBookingBody4: "Kui te ei saa broneeringut teha põhjustel, mis ei ole seotud meie tingimustega, ei saa me teid aidata. Peate võtma otse ühendust oma klienditeeninduse meeskonnaga, kuna nad võivad aidata; Vaadake oma broneeringu kinnitust või veebisaiti, et saada kontaktandmeid.",
    linkWarningBooking: "Siin",
    formSectionNavigatorStep1: "1. samm",
    formSectionNavigatorStep2: "2. samm",
    formSectionNavigatorStep3: "3. samm",
    formSectionNavigatorStep1Text: "Kontaktandmed ja broneeringu üksikasjad",
    formSectionNavigatorStep2Text: "Tagastamise põhjus",
    formSectionNavigatorStep3Text: "Makse üksikasjad",
    messageBookingMatchedAndFound: "Kui teie broneeringu number on seotud olemasoleva rakendusega, saadame teile e-kirja, mis sisaldab linki teie taotlusele juurdepääsuks",
    messageBookingMatchedAndFoundCompleted: "Teie tagasimakse taotlus on juba heaks kiidetud! Kui teil on maksega probleeme, võtke palun ühendust:",
    applicationDeclinedMessage: "Teie taotlus on tagasi lükatud ja on nüüd suletud.",
    messageBookingNotFound1: "Me ei leia selle viitega broneeringut. Palun kontrollige oma broneeringu kinnituse e-posti aadressi ja sisestage uuesti.",
    messageBookingNotFound2: "Ärge muretsege, kui teie broneeringu viitenumber ei tööta, võite valida Leia broneering ja sisestada oma andmed. Vormi täites laadige üles koopia oma broneeringut kinnitavast e-kirjast, et aidata meil leida teie broneeringu andmed.",
    messageLocatingBooking: "Teie broneeringu leidmine ...",
    messageLoadingBank: "Pangainfo laadimine...",
    messageFailCurrencyLookup: "Viga valuuta hankimisel",
    messageSavingApplication: "Rakenduse salvestamine ...",
    messageSavedApplication: "Tagasimaksetaotlus on edukalt salvestatud",
    messageFailSavedApplication: "Tagasimaksetaotlust ei õnnestunud salvestada",
    messageSendingEmailReminder: "Töötlemine...",
    messageErrorLocationDetails: "Viga asukoha üksikasjade toomisel",
    messageFailFormSubmission: "Vormi esitamine nurjus",
    messageApplicationCannotBeFound: "Rakendust ei leitud",
    messageApplicationRetrievalError: "Viga rakenduse hankimisel",
    messageSubmittingApplication: "Taotluse esitamine...",
    messageEventDateLimitError: "Tähtaja möödumisest teatamise põhjus",
    messageAllTicketsAssigned: "Kõik piletid on määratud teisele tagasimaksetaotlusele.",
    selectPrefix: "Valima",
    selectPostfix: "",
    fileUpload: "Üleslaadimiseks klõpsake siin",
    fileUploadAcceptedFilesMessage: "Aktsepteerime ainult .png, .jpeg, .heic, .webp PDF-e ja pildivorminguid",
    fileUploadNoFiles: "0 Valitud failid",
    fileUploadPreviouslyUploaded: "Varem üles laaditud:",
    fileUploadSelectedUploaded: "Valitud on juba üles laaditud:",
    buttonNext: "Järgmine",
    buttonPrevious: "Eelmine",
    buttonSave: "Salvestama",
    buttonCancel: "Tühistama",
    buttonSubmit: "Esitada",
    buttonConfirm: "Kinnitama",
    buttonYes: "Jah",
    buttonNo: "Ei",
    buttonClose: "Sulgema",
    linkPrivacyStatement: "Isikuandmete kaitse põhimõtted",
    linkComplaintsPolicy: "Pretensioonide esitamise kord",
    validationRequired: "Nõutav",
    validationInvalid: "Sobimatu",
    labelUploadPrefix: "Laadida",
    labelUploadPostfix: "",
    labelChange: "Muutuma",
    labelView: "Vaade",
    browserNavigationMessage: "Salvestamata muudatused lähevad kaotsi",
};