export default {
    labelSavedApplicationReference:" סיום יישום שמור: ",
    labelLanguage: "שפה",
    messageWarningBookingHeading1: "ההזמנה בוטלה:",
    messageWarningBookingHeading2: "הזמנה נדחתה / נקבעה מראש:",
    messageWarningBookingBody1: "אם ההזמנה שלך בוטלה על ידי המארגן או חברת התעופה, צוות זה לא יוכל לסייע לך בהחזר כספי. יהיה עליך ליצור קשר ישירות עם צוות שירות הלקוחות שלך; לפרטים ליצירת קשר, עיין באישור ההזמנה או באתר האינטרנט שלך.",
    messageWarningBookingBody2: "אם הזמנתך נדחתה, תנאי ההחזר המורחבים שלך יועברו לתאריך החדש. אם יש לך סיבה תקפה להחזר בתנאים שלנו, מלא את הטופס עם הראיות התומכות הנכונות.",
    messageWarningBookingBody3: "שימו לב: שירות זה יכול לספק החזרים רק מהסיבות הכלולות בנוסח שלנו, הזמינות בדוא\"ל אישור ההזמנה שלכם.",
    messageWarningBookingBody4: "אם אינך יכול לבצע את ההזמנה מסיבות מחוץ לתנאים שלנו, לא נוכל לסייע. תצטרך ליצור קשר ישירות עם צוות שירות הלקוחות שלך מכיוון שהם עשויים לעזור; ראה את אישור ההזמנה שלך או באתר האינטרנט שלך לפרטי יצירת קשר.",
    linkWarningBooking: "כאן",
    formSectionNavigatorStep1: "שלב 1",
    formSectionNavigatorStep2: "שלב 2",
    formSectionNavigatorStep3: "שלב 3",
    formSectionNavigatorStep1Text: "פרטי יצירת קשר והזמנה",
    formSectionNavigatorStep2Text: "סיבת ההחזר",
    formSectionNavigatorStep3Text: "פרטי תשלום",
    messageBookingMatchedAndFound: "אם הפניה להזמנה שלך משויכת לאפליקציה קיימת, נשלח לך דוא\"ל המכיל קישור לגישה ליישום שלך",
    messageBookingMatchedAndFoundCompleted: "בקשת ההחזר שלך כבר אושרה! אם יש לך בעיות בתשלום שלך, אנא צרו קשר עם:",
    applicationDeclinedMessage: "הבקשה שלך נדחתה והיא סגורה כעת.",
    messageBookingNotFound1: "איננו יכולים למצוא הזמנה עם הפניה זו. אנא בדוק את אימייל אישור ההזמנה והזן שוב.",
    messageBookingNotFound2: "אל תדאג, אם הפניה להזמנה שלך לא עובדת תוכל לבחור 'מצא הזמנה' ולהזין את הפרטים שלך. בעת מילוי הטופס אנא העלה עותק של הודעת אישור ההזמנה שלך כדי לעזור לנו לאתר את פרטי ההזמנה שלך.",
    messageLocatingBooking: "מיקום הזמנתך ...",
    messageLoadingBank: "טוען פרטי בנק ...",
    messageFailCurrencyLookup: "שגיאה באחזור מטבע",
    messageSavingApplication: "שמירת היישום שלך ...",
    messageSavedApplication: "בקשת ההחזר נשמרה בהצלחה",
    messageFailSavedApplication: "לא ניתן לשמור את בקשת ההחזר",
    messageSendingEmailReminder: "מעבד...",
    messageErrorLocationDetails: "שגיאה באחזור פרטי מיקום",
    messageFailFormSubmission: "הגשת הטופס נכשלה",
    messageApplicationCannotBeFound: "היישום לא נמצא",
    messageApplicationRetrievalError: "שגיאה באחזור היישום",
    messageSubmittingApplication: "מגיש בקשה ...",
    messageEventDateLimitError: "סיבת ההודעה מחוץ למגבלת הזמן",
    messageAllTicketsAssigned: "כל הכרטיסים הוקצו לאפליקציה אחרת להחזר כספי.",
    selectPrefix: "בחר",
    selectPostfix: "",
    fileUpload: "לחץ כאן להורדה",
    fileUploadAcceptedFilesMessage: "אנו מקבלים רק קובצי PDF ופורמטים של תמונות .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "נבחרו 0 קבצים",
    fileUploadPreviouslyUploaded: "הועלה בעבר:",
    fileUploadSelectedUploaded: "הנבחרים כבר הועלו:",
    buttonNext: "הבא",
    buttonPrevious:"קודם",
    buttonSave: "שמור",
    buttonCancel: "ביטול",
    buttonSubmit: "שלח",
    buttonConfirm: "אשר",
    buttonYes: "כן",
    buttonNo: "לא",
    buttonClose: "סגור",
    linkPrivacyStatement: "הצהרת פרטיות",
    linkComplaintsPolicy: "מדיניות תלונות",
    validationRequired: "נדרש",
    validationInvalid: "לא חוקי",
    labelUploadPrefix: "העלה",
    labelUploadPostfix: "",
    labelChange: "שינוי",
    labelView: "נוף",
    browserNavigationMessage: "שינויים שלא נשמרו יאבדו"
}