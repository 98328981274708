export default {
    labelSavedApplicationReference: "Tham chiếu ứng dụng đã lưu:",
    labelLanguage: "Ngôn ngữ",
    messageWarningBookingHeading1: "Đặt trước đã bị Hủy:",
    messageWarningBookingHeading2: "Đặt chỗ bị hoãn / lên lịch lại:",
    messageWarningBookingBody1: "Nếu đặt chỗ của bạn đã bị nhà tổ chức hoặc hãng hàng không hủy, nhóm này sẽ không thể hỗ trợ bạn hoàn lại tiền. Bạn sẽ cần liên hệ trực tiếp với nhóm dịch vụ khách hàng của mình; xem xác nhận đặt chỗ hoặc trang web của bạn để biết chi tiết liên hệ.",
    messageWarningBookingBody2: "Nếu việc đặt chỗ của bạn bị hoãn lại, các điều khoản hoàn tiền mở rộng của bạn sẽ được chuyển sang ngày mới. Nếu bạn có lý do hợp lệ để hoàn tiền theo các điều khoản của chúng tôi, vui lòng hoàn thành biểu mẫu với bằng chứng hỗ trợ chính xác.",
    messageWarningBookingBody3: "Xin lưu ý: Dịch vụ này chỉ có thể hoàn lại tiền vì những lý do có trong cách diễn đạt của chúng tôi, có trong email xác nhận đặt phòng của bạn.",
    messageWarningBookingBody4: "Nếu bạn không thể đặt phòng vì những lý do nằm ngoài các điều khoản của chúng tôi, chúng tôi sẽ không thể hỗ trợ. Bạn sẽ cần liên hệ trực tiếp với nhóm dịch vụ khách hàng của mình vì họ có thể giúp đỡ; xem xác nhận đặt phòng của bạn hoặc trang web để biết chi tiết liên lạc.",
    linkWarningBooking: "đây",
    formSectionNavigatorStep1: "Bước 1",
    formSectionNavigatorStep2: "Bước 2",
    formSectionNavigatorStep3: "Bước 3",
    formSectionNavigatorStep1Text: "Chi tiết liên hệ và đặt chỗ",
    formSectionNavigatorStep2Text: "Lý do hoàn tiền",
    formSectionNavigatorStep3Text: "Chi tiết thanh toán",
    messageBookingMatchedAndFound: "Nếu tham chiếu đặt chỗ của bạn được liên kết với một ứng dụng hiện có, chúng tôi sẽ gửi cho bạn một email có chứa liên kết để truy cập vào ứng dụng của bạn",
    messageBookingMatchedAndFoundCompleted: "Đơn xin hoàn lại tiền của bạn đã được chấp thuận! Nếu bạn có bất kỳ vấn đề nào với thanh toán của mình, vui lòng liên hệ:",
    applicationDeclinedMessage: "Đơn đăng ký của bạn đã bị từ chối và hiện đã bị đóng.",
    messageBookingNotFound1: "Chúng tôi không thể tìm thấy một đặt phòng với tài liệu tham khảo đó. Vui lòng kiểm tra email xác nhận đặt phòng của bạn và nhập lại.",
    messageBookingNotFound2: "Đừng lo lắng, nếu tài liệu tham khảo đặt phòng của bạn không hoạt động, bạn có thể chọn 'Tìm Đặt chỗ' và nhập thông tin chi tiết của bạn. Khi hoàn thành biểu mẫu, vui lòng tải lên một bản sao của email xác nhận đặt phòng của bạn để giúp chúng tôi xác định chi tiết đặt phòng của bạn.",
    messageLocatingBooking: "Vị trí đặt chỗ của bạn ...",
    messageLoadingBank: "Đang tải thông tin ngân hàng ...",
    messageFailCurrencyLookup: "Đã xảy ra lỗi khi truy xuất tiền tệ",
    messageSavingApplication: "Đang lưu ứng dụng của bạn ...",
    messageSavedApplication: "Đã lưu thành công yêu cầu hoàn lại tiền",
    messageFailSavedApplication: "Không thể lưu yêu cầu hoàn lại tiền",
    messageSendingEmailReminder: "Xử lý...",
    messageErrorLocationDetails: "Lỗi khi truy xuất chi tiết vị trí",
    messageFailFormSubmission: "Gửi biểu mẫu không thành công",
    messageApplicationCannotBeFound: "Không thể tìm thấy ứng dụng",
    messageApplicationRetrievalError: "Lỗi khi truy xuất ứng dụng",
    messageSubmittingApplication: "Đang gửi ứng dụng ...",
    messageEventDateLimitError: "Lý do khiến thông báo hết thời gian chờ",
    messageAllTicketsAssigned: "Tất cả các vé đã được chỉ định cho một ứng dụng hoàn tiền khác.",
    selectPrefix: "Chọn",
    selectPostfix: "",
    fileUpload: "bấm vào đây để tải xuống",
    fileUploadAcceptedFilesMessage: "Chúng tôi chỉ chấp nhận các tệp PDF và định dạng hình ảnh .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 tệp được chọn",
    fileUploadPreviouslyUploaded: "Đã tải lên trước đây:",
    fileUploadSelectedUploaded: "Những thứ được chọn đã được tải lên:",
    buttonNext: "Tiếp theo",
    buttonPrevious: "Trước",
    buttonSave: "Lưu",
    buttonCancel: "Hủy bỏ",
    buttonSubmit: "Gửi",
    buttonConfirm: "Xác nhận",
    buttonYes: "Đúng",
    buttonNo: "Không",
    buttonClose: "Đóng",
    linkPrivacyStatement: "Tuyên bố về Quyền riêng tư",
    linkComplaintsPolicy: "Chính sách khiếu nại",
    validationRequired: "Cần thiết",
    validationInvalid: "không hợp lệ",
    labelUploadPrefix: "Tải lên",
    labelUploadPostfix: "",
    labelChange: "Thay đổi",
    labelView: "Cảnh",
    browserNavigationMessage: "Các thay đổi chưa được lưu sẽ bị mất"
};