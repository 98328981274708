import * as React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useRecoilValue} from 'recoil';
import {AlertHolder} from './AlertHolder';
import {EvidenceResubmissionState} from '../../state/activity';

export const EvidenceResubmissionAlert = () => {

    const {t} = useTranslation();
    const evidenceResubmission = useRecoilValue(EvidenceResubmissionState);

    const UploadDocumentsMessage = () => {
        return (
            <Box>{t('common:alertUploadDocuments')}</Box>
        );
    }

    return (<>
            {evidenceResubmission &&
                <AlertHolder
                    severity='warning'
                    content={<UploadDocumentsMessage/>}
                />
            }
        </>
    );
}