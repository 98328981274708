export default {
    labelSavedApplicationReference: "የተቀመጠ መተግበሪያ ማጣቀሻ:",
    labelLanguage: "ቋንቋ",
    messageWarningBookingHeading1: "ቦታ ማስያዝ ተሰርዟል።:",
    messageWarningBookingHeading2: "ቦታ ማስያዝ ለሌላ ጊዜ ተላልፏል/እንደገና ተይዟል።:",
    messageWarningBookingBody1: "ቦታ ማስያዝዎ በአደራጁ ወይም በአየር መንገዱ ከተሰረዘ ይህ ቡድን ተመላሽ ለማድረግ ሊረዳዎ አይችልም። የደንበኛ አገልግሎት ቡድንዎን በቀጥታ ማነጋገር ያስፈልግዎታል; ለእውቂያ ዝርዝሮች የእርስዎን ቦታ ማስያዝ ማረጋገጫ ወይም ድር ጣቢያ ይመልከቱ.",
    messageWarningBookingBody2: "ቦታ ማስያዝዎ ለሌላ ጊዜ ከተራዘመ፣ የተራዘመው ገንዘብ ተመላሽ የሚደረጉ ውሎች እስከ አዲሱ ቀን ድረስ ያልፋሉ። በውላችን መሰረት ገንዘቡን ለመመለስ በቂ ምክንያት ካሎት፣ እባክዎ ቅጹን በትክክለኛው የድጋፍ ማስረጃ ይሙሉ.",
    messageWarningBookingBody3: "እባክዎን ያስተውሉ፡ ይህ አገልግሎት ተመላሽ ማድረግ የሚችለው በቃላችን ውስጥ ለተካተቱት ምክንያቶች ብቻ ነው፣ በቦታ ማስያዣ ማረጋገጫ ኢሜልዎ ውስጥ ይገኛል።",
    messageWarningBookingBody4: "ከውላችን ውጭ በሆኑ ምክንያቶች ቦታ ማስያዝ ካልቻሉ ልንረዳዎ አንችልም። እነሱ ሊረዱዎት ስለሚችሉ የደንበኛ አገልግሎት ቡድንዎን በቀጥታ ማነጋገር ያስፈልግዎታል; ለእውቂያ ዝርዝሮች የእርስዎን ቦታ ማስያዝ ማረጋገጫ ወይም ድር ጣቢያ ይመልከቱ።",
    linkWarningBooking: "እዚህ",
    formSectionNavigatorStep1: "ደረጃ",
    formSectionNavigatorStep2: "ደረጃ",
    formSectionNavigatorStep3: "ደረጃ",
    formSectionNavigatorStep1Text: "የእውቂያ እና የቦታ ማስያዝ ዝርዝሮች",
    formSectionNavigatorStep2Text: "የተመላሽ ገንዘብ ምክንያት",
    formSectionNavigatorStep3Text: "የክፍያ ዝርዝሮች",
    messageBookingMatchedAndFound: "የቦታ ማስያዣ ማጣቀሻዎ ካለ አንድ መተግበሪያ ጋር የተያያዘ ከሆነ ማመልከቻዎን ለመድረስ አገናኝ የያዘ ኢሜይል እንልክልዎታለን",
    messageBookingMatchedAndFoundCompleted: "የተመላሽ ገንዘብ ማመልከቻዎ አስቀድሞ ጸድቋል! በክፍያዎ ላይ ምንም አይነት ችግር ካጋጠመዎት እባክዎ ያነጋግሩ:",
    applicationDeclinedMessage: "ማመልከቻዎ ተቀባይነት አላገኘም እና አሁን ተዘግቷል።",
    messageBookingNotFound1: "ከዚያ ማጣቀሻ ጋር ቦታ ማስያዝ ልናገኝ አንችልም። እባክዎ የቦታ ማስያዣ ማረጋገጫ ኢሜይልዎን ያረጋግጡ እና እንደገና ያስገቡ.",
    messageBookingNotFound2: "አይጨነቁ፣ የቦታ ማስያዣ ማጣቀሻዎ የማይሰራ ከሆነ 'ቦታ ማስያዝን ይፈልጉ' ን ይምረጡ እና ዝርዝሮችዎን ያስገቡ። ቅጹን በሚሞሉበት ጊዜ እባክዎ የማስያዣ ዝርዝሮችዎን ለማግኘት እንዲረዳን የቦታ ማስያዣ ማረጋገጫ ኢሜልዎን ቅጂ ይስቀሉ።.",
    messageLocatingBooking: "ቦታ ማስያዝዎን በማግኘት ላይ...",
    messageLoadingBank: "የባንክ መረጃን በመጫን ላይ...",
    messageFailCurrencyLookup: "ምንዛሬን በማውጣት ላይ ስህተት",
    messageSavingApplication: "ማመልከቻዎን በማስቀመጥ ላይ...",
    messageSavedApplication: "የተመላሽ ገንዘብ ማመልከቻ በተሳካ ሁኔታ ተቀምጧል",
    messageFailSavedApplication: "የተመላሽ ገንዘብ ማመልከቻ ማስቀመጥ አልቻለም",
    messageSendingEmailReminder: "በማሰናዳት ላይ...",
    messageErrorLocationDetails: "የአካባቢ ዝርዝሮችን ሰርስሮ ማውጣት ላይ ስህተት",
    messageFailFormSubmission: "ቅጽ ማስገባት አልተሳካም።",
    messageApplicationCannotBeFound: "መተግበሪያ ሊገኝ አልቻለም",
    messageApplicationRetrievalError: "መተግበሪያን ሰርስሮ ማውጣት ላይ ስህተት",
    messageSubmittingApplication: "ማመልከቻ በማስገባት ላይ...",
    messageEventDateLimitError: "የጊዜ ገደብ ውጭ የማሳወቂያ ምክንያት",
    messageAllTicketsAssigned: "ሁሉም ትኬቶች ለሌላ ገንዘብ ተመላሽ ማመልከቻ ተመድበዋል።",
    selectPrefix: "ይምረጡ",
    selectPostfix: "",
    fileUpload: "ለመስቀል እዚህ ይጫኑ",
    fileUploadAcceptedFilesMessage: "ፒዲኤፎችን እና የ.png፣ .jpeg፣ .heic፣ .webp የምስል ቅርጸቶችን ብቻ ነው የምንቀበለው።",
    fileUploadNoFiles: "0 ፋይሎች ተመርጠዋል",
    fileUploadPreviouslyUploaded: "ከዚህ ቀደም ተጭኗል:",
    fileUploadSelectedUploaded: "የተመረጡት አስቀድመው ተሰቅለዋል፡-",
    buttonNext: "ቀጥሎ",
    buttonPrevious: "ቀዳሚ",
    buttonSave: "አስቀምጥ",
    buttonCancel: "ሰርዝ",
    buttonSubmit: "አስገባ",
    buttonConfirm: "አረጋግጥ",
    buttonYes: "አዎ",
    buttonNo: "አይ",
    buttonClose: "ገጠመ",
    linkPrivacyStatement: "የግላዊነት መግለጫ",
    linkComplaintsPolicy: "የቅሬታ ፖሊሲ",
    validationRequired: "ያስፈልጋል",
    validationInvalid: "ልክ ያልሆነ",
    labelUploadPrefix: "ስቀል",
    labelUploadPostfix: "",
    labelChange: "ለውጥ",
    labelView: "ይመልከቱ",
    browserNavigationMessage: "ያልተቀመጡ ለውጦች ይጠፋሉ"
};