export default {
    labelBookingCancelledPostponedHeading: "Onko varauksesi peruutettu tai siirretty? Lue lisää",
    labelBookingCancelledPostponedText1: "Klikkaa",
    labelBookingCancelledPostponedText2: "saadaksesi lisätietoja.",
    labelNewRefundHeading: "Uusi palautushakemus?",
    labelNewRefundText: "Kirjoita tunnistenumerosi tähän...",
    placeholderBookingReference: "Tunnistenumero",
    validationBookingReference: "Tunnistenumero vaaditaan",
    validationEmailAsBookingReference: "Sähköpostiosoitetta ei hyväksytä varausviitteenä",
    buttonFindBooking: "Hae tilaus",
    labelEmailHeading: "Oletko jo aloittanut hakemuksen tekemisen?",
    labelEmailText: "Tilaa kesken jäänyt hakemus sähköpostiisi.",
    placeholderEmail: "Sähköposti",
    buttonEmailReminder: "Sähköpostimuistutus",
    labelCustomerName : "Asiakkaan nimi",
    validationCustomerName: "Asiakkaan nimi vaaditaan",
    labelTypeOfBooking: "Varauksen tyyppi",
    validationTypeOfBooking: "Varaustyyppi vaaditaan",
    labelEventName:"Tapahtuman nimi",
    labelVenueName:"Tapahtumapaikan nimi",
    labelFlightNumber:"Lennon numero",
    labelOriginAirport: "Lähdön lentokenttä",
    labelHotelName: "Majoituspaikan nimi",
    labelHotelLocation: "Majoituspaikka",
    labelCurrency: "Valuutta",
    validationCurrency: "Valuutta vaaditaan",
    validationCountryCurrency: "Valitun valuutan on oltava kotimaasi valuutta",
    validationOfflinePayment: "Emme voi käsitellä valuutta- ja maavalintasi hyvityksiä, valitse vaihtoehtoja",
    labelBookingValue: "Varauksen hinta",
    validationBookingValue: "Varausarvo vaaditaan",
    validationBookingValueAmount: "On oltava suurempi kuin 0",
    labelDateOfPurchase: "Ostopäivä",
    validationDateOfPurchase: "Ostopäivä on pakollinen",
    labelEmailAddress: "Sähköpostiosoite",
    validationEmailAddress: "Sähköpostiosoite vaaditaan",
    validationEmailAddressFormat: "Virheellinen sähköpostimuoto",
    labelConfirmEmailAddress: "Vahvista sähköpostiosoite",
    validationConfirmEmailAddressFormat: "Virheellinen sähköpostimuoto",
    validationConfirmEmailAddress: "Vahvistussähköpostiosoite vaaditaan",
    validationConfirmEmailAddressMatch: "Sähköpostiosoitteet eivät täsmää",
    validationEmailDomain: "Tätä sähköpostipalveluntarjoajaa ei hyväksytä. Käytä vaihtoehtoa",
    validationDateOfEvent: "Tapahtuman päivämäärä vaaditaan",
    labelHomeCountry: "Kotimaa",
    validationHomeCountry: "Kotimaa vaaditaan",
    labelContactNumberCountryCode: "Puhelinnumeron Maakoodi",
    validationContactNumberCountryCode: "Maanumero vaaditaan",
    labelContactNumber: "Puhelinnumero",
    validationContactNumber: "Yhteysnumero vaaditaan",
    labelAddressLine1: "Osoiterivi 1",
    labelAddressLine2: "Osoiterivi 2",
    labelCity: "Kaupunki",
    labelZipCode: "Postinumero",
    labelRegion: "Alue",
    validationRegion: "Alue vaaditaan",
    labelNumberOfTicketsToRefund: "Hyvitettävien lippujen määrä",
    validationNumberOfTicketsToRefund: "Hyvitettävien lippujen määrä vaaditaan",
    validationNumberOfTicketsToRefundValue: "On oltava suurempi kuin 0",
    labelCostPerTicket: "Jokaisen lipun hinta",
    validationCostPerTicket: "Hinta per lippu vaaditaan",
    validationCostPerTicketValue: "On oltava suurempi kuin 0",
    labelTotalRefundAmount: "Hyvityksen kokonaissumma",
    validationTotalRefundAmount: "Kokonaishyvityssumma vaaditaan",
    validationTotalRefundAmountValue: "On oltava suurempi kuin 0",
    validationTotalRefundGreaterThanBookingValue: "Hyvityksen kokonaissumma ei voi olla suurempi kuin yllä oleva varausarvo",
    labelBookingConfirmationFiles: "Lataa varausvahvistus",
    validationBookingConfirmationFiles: "Liitä vähintään yksi tiedosto tai napsauta Tallenna ja palaa myöhemmin, kun sinulla on asiakirjat",
    validationEvidenceFilesPopup: "Todisteet on ladattava ennen lähettämistä. Jos sinulla ei ole todisteita juuri nyt, tallenna hakemus ja palauta kun olet saanut. Otamme vastaan ​​sähköisiä kopioita, skannerikopioita ja valokuvia.",
    validationBookingConfirmationFilesPopup: "Varausvahvistus on ladattava ennen lähettämistä. Jos sinulla ei ole varausvahvistusta juuri nyt, tallenna hakemus ja palauta heti kun olet saanut. Otamme vastaan ​​sähköisiä kopioita, skannerikopioita ja valokuvia.",
    alertUploadDocuments: "Lataa sähköpostissa kuvatut asiakirjat ja siirry sitten vaiheeseen 3.",
    labelRefundReasonCategory: "Hyvityksen syy",
    validationRefundReasonCategory: "Hyvityksen syy vaaditaan",
    labelDateOfIncident: "Tapahtuman päivämäärä",
    labelAdditionalComments: "Muita kommentteja",
    tooltipAdditionalComments: "Muita kommentteja",
    validationAdditionalComments: "Lisäkommentteja tarvitaan",
    labelUnableToNotifyWithinTimeLimit: "Ei voida ilmoittaa 60 päivän kuluessa",
    labelReasonForNotificationOutsideTimeLimit: "Ilmoitus määräajan ulkopuolella Selitys",
    validationReasonForNotificationOutsideTimeLimit: "Ilmoituksen syy vaaditaan 60 päivän kuluttua",
    labelRefundReasonFiles: "Lataa todisteet",
    validationRefundReasonFiles: "Liitä vähintään yksi tiedosto tai napsauta Tallenna ja palaa myöhemmin, kun sinulla on asiakirjas",
    validationTextScript: "On vastattava latinalaista kirjoitusta",
    optionTypeOfBooking1: "Tapahtuma",
    optionTypeOfBooking2: "Kiertue",
    optionTypeOfBooking3: "Lento",
    optionTypeOfBooking4: "Majoitus",
    optionTypeOfBooking5: "Kuljetus",
    optionTypeOfBooking6: "Pysäköinti",
    optionTypeOfBooking7: "Muut matkat",
    labelEventTypeDate: "Tapahtuman päivämäärä",
    labelEventTypeTour: "Kiertueen päivämäärä",
    labelEventTypeFlight: "Lennon päivämäärä",
    labelEventTypeCheckIn: "Sisäänkirjautumispäivä",
    labelEventTypeTransfer: "Siirron päivämäärä",
    labelEventTypeParking: "Pysäköintipäivä",
    labelEventTypeOtherTravel: "Matkan päivämäärä",
    optionRefundReason11: "Epäsuotuisa sää",
    optionRefundReason10: "Asevoimien ja pelastuspalveluiden palautus",
    optionRefundReason15: "Muutoksia koepäiviin",
    optionRefundReason18: "Oikeuden haaste",
    optionRefundReason17: "Covid-19 matkustusrajoitukset",
    optionRefundReason19: "Positiivinen COVID-19 tulos",
    optionRefundReason20: "Järjestäjä peruutti varauksen",
    optionRefundReason21: "Järjestäjä siirsi varausta",
    optionRefundReason22: "Varasin väärän päivämäärän",
    optionRefundReason23: "Väärin varatut liput",
    optionRefundReason24: "Varasin liian monta lippua",
    optionRefundReason4: "Kuolema",
    optionRefundReason16: "Hätätilanteet",
    optionRefundReason14: "Hallituksen matkustuskielto",
    optionRefundReason9: "Kodin hätätilanne",
    optionRefundReason1: "Sairaus/vamma/onnettomuus",
    optionRefundReason8: "Tuomariston palvelu",
    optionRefundReason7: "Mekaaninen rikkoutuminen",
    optionRefundReason2: "Aiempi sairaus",
    optionRefundReason3: "Raskauden komplikaatio",
    optionRefundReason5: "Julkisen liikenteen häiriöt",
    optionRefundReason6: "Lentohäiriöt",
    optionRefundReason13: "Lippujen varkaus",
    optionRefundReason12: "Työpaikan siirto",
    optionRefundReason25: "Työpaikan irtisanominen",
    optionRefundReason26: "Testivaihtoehto",
    optionRefundReasonConfirm1: "Ei",
    optionRefundReasonConfirm2: "Kyllä",
    optionRefundReasonArmedForces1: "Armeija",
    optionRefundReasonArmedForces2: "Laivasto",
    optionRefundReasonArmedForces3: "Ilmavoimat",
    optionRefundReasonArmedForces4: "Erikoisjoukkojen",
    optionRefundReasonArmedForces5: "Merijalkaväki",
    optionRefundReasonArmedForces6: "Tulipalo",
    optionRefundReasonArmedForces7: "Ambulanssi",
    optionRefundReasonArmedForces8: "Lääkäri/sairaanhoitaja",
    optionRefundReasonArmedForces9: "Hammaslääkäri",
    optionRefundReasonArmedForces10: "Muu",
    optionRefundReasonCourtSummons1: "Rikossyytteet",
    optionRefundReasonCourtSummons2: "Siviilioikeudellinen oikeusjuttu",
    optionRefundReasonCourtSummons3: "Todistaja syyttäjässä",
    optionRefundReasonCourtSummons4: "Todistaja siviiliasiassa",
    optionRefundReasonCourtSummons5: "Kantaja",
    optionRefundReasonCourtSummons6: "Vastaaja",
    optionRefundReasonCourtSummons7: "Muu",
    optionRefundReasonDeath1: "Aviomies",
    optionRefundReasonDeath2: "Vaimo",
    optionRefundReasonDeath3: "Poika",
    optionRefundReasonDeath4: "Tytär",
    optionRefundReasonDeath5: "Isä",
    optionRefundReasonDeath6: "Äiti",
    optionRefundReasonDeath7: "Veli",
    optionRefundReasonDeath8: "Sisko",
    optionRefundReasonDeath9: "Isoisä",
    optionRefundReasonDeath10: "Isoäiti",
    optionRefundReasonDeath11: "Muu",
    optionRefundReasonHomeEmergency1: "Tulipalo",
    optionRefundReasonHomeEmergency2: "Tulva",
    optionRefundReasonHomeEmergency3: "Ryöstö",
    optionRefundReasonHomeEmergency4: "Vandalismi",
    optionRefundReasonHomeEmergency5: "Kotiväkivalta",
    optionRefundReasonHomeEmergency6: "Siepata",
    optionRefundReasonHomeEmergency7: "Muu",
    optionRefundReasonCOVID19Person1: "Minulla",
    optionRefundReasonCOVID19Person2: "Toinen henkilö varauksessa",
    optionRefundReasonCOVID19Person3: "Henkilö kotitaloudessani",
    optionRefundReasonCOVID19Person4: "Muu",
    optionRefundReasonCOVID19TestType1: "PCR-testi",
    optionRefundReasonCOVID19TestType2: "RAT-testi",
    optionRefundReasonCOVID19TestType3: "Sivuttaisvirtaustesti",
    optionRefundReasonCOVID19TestType4: "Itseohjautuva testi",
    optionRefundReasonCOVID19TestType5: "Ei testiä, mutta minulla on oireita.",
    optionRefundReasonCOVID19TestType6: "Muu",
    optionRefundReasonBreakdown1: "Konerikko",
    optionRefundReasonBreakdown2: "Onnettomuus",
    optionRefundReasonBreakdown3: "Tulipalo",
    optionRefundReasonBreakdown4: "Varkaus",
    optionRefundReasonPublicTransportType1: "Juna",
    optionRefundReasonPublicTransportType2: "Bussi",
    optionRefundReasonPublicTransportType3: "Raitiovaunu",
    optionRefundReasonPublicTransportType4: "Laiva",
    optionRefundReasonDocumentType1: "Passi",
    optionRefundReasonDocumentType2: "Matkustuslupa",
    optionRefundReasonDocumentType3: "Kansallinen henkilötodistus",
    optionRefundReasonDocumentType4: "Liput",
    optionRefundReasonWorkRelocationType1: "Väliaikainen",
    optionRefundReasonWorkRelocationType2: "Vakituinen",
    optionRefundReasonWorkRelocationTemporary1: "0-3 kuukautta",
    optionRefundReasonWorkRelocationTemporary2: "3-6 kuukautta",
    optionRefundReasonWorkRelocationTemporary3: "Yli 6 kuukautta",
    optionRefundReasonBookingRelationshipType1: "Joku varauksessa",
    optionRefundReasonBookingRelationshipType2: "Varauksessa olevan henkilön välitön sukulainen",
    optionEmploymentType1: "Kokopäiväinen",
    optionEmploymentType2: "Osa-aikainen",
    optionEmploymentType3: "Sopimus",
    optionEmploymentLengthType1: "0-1 vuotta",
    optionEmploymentLengthType2: "1-2 vuotta",
    optionEmploymentLengthType3: "Yli 2 vuotta",
    textRefundEvidenceHeading: "Ole hyvä ja toimita seuraavat todisteet:",
    textRefundEvidence1: "Lentoyhtiön vahvistus",
    textRefundEvidence2: "Kaikki asianmukaiset todisteet",
    textRefundEvidence3: "Vikapalvelun kutsun vahvistus",
    textRefundEvidence4: "Kuolintodistus",
    textRefundEvidence5: "Vahvistus peruutus lentoyhtiöltä",
    textRefundEvidence6: "Sääennuste",
    textRefundEvidence7: "Varausasiamiehen kirjeenvaihto, joka vahvistaa, ettei lippuja voida myöntää uudelleen",
    textRefundEvidence8: "Tuomioistuimen kirje",
    textRefundEvidence9: "Lääkärintodistus / Lääkärin huomautus",
    textRefundEvidenceDescription9: "Vahvistamalla sairauden tai vamman tiedot, päivämäärä, jolloin se tapahtui ensimmäisen kerran, että se estää sinua osallistumasta Varaukseen.",
    textRefundEvidence10: "Työnantajan kirjeen vahvistus",
    textRefundEvidence11: "Vakuutuskorvausten kirjeenvaihto",
    textRefundEvidence12: "Työnantajan kirje",
    textRefundEvidence13: "Valtioneuvoston verkkosivujen vahvistus",
    textRefundEvidence14: "Tutkimuksen muutosilmoitus",
    textRefundEvidence15: "Poliisin raportti",
    textRefundEvidence16: "Poliisin raportti ja viitenumero",
    textRefundEvidence17: "Kuljetuksen tarjoajan vahvistus",
    textRefundEvidence18: "Matkustusvaroitus",
    textRefundEvidence20: "COVID-19-testisertifikaatti",
    textRefundEvidence24: "Hallituksen eristysilmoitus",
    textRefundEvidence25: "Komentavan upseerin kirje",
    textRefundEvidence26: "Vakuutuksenantajien kirje",
    textRefundEvidence27: "Todiste osoitteesta",
    textRefundEvidence28: "Todiste suhteesta vainajaan",
    textRefundEvidence29: "Todiste suhteesta loukkaantuneeseen/sairaaseen henkilöön",
    textRefundEvidence30: "Palkkatodistus",
    tooltipBookingReference: "Voit tunnistaa varausviitteen tarkistamalla varausvahvistuksen ja katsomalla laajennettua palveluamme käsittelevän osion.",
    tooltipCustomerName: "Etu- ja sukunimi, jota käytettiin varauksenteossa.",
    tooltipTypeOfBooking: "Varaustyyppi, josta haluat hyvityksen.",
    tooltipEventName: "Tapahtuman nimi.",
    tooltipVenueName: "Tapahtumapaikan nimi.",
    tooltipFlightNumber: "Lennon numero.",
    tooltipOriginAirport: "Lähdön lentokenttä.",
    tooltipHotelName: "Majoituspaikan nimi.",
    tooltipHotelLocation: "Majoituspaikan sijainti.",
    tooltipCurrency: "Varauksen maksamiseen käytetty valuutta.",
    tooltipBookingValue: "Varauksesta maksamasi kokonaishinta.",
    tooltipDateOfPurchase: "Päivämäärä, jolloin varaus tehtiin.",
    tooltipEmailAddress: "Käytä kelvollista sähköpostiosoitetta. Käytämme tätä sähköpostia kirjeenvaihtoon palautushakemuksen aikana.",
    tooltipConfirmEmailAddress: "Käytä samaa sähköpostiosoitetta kuin yllä, tämä on vahvistusta varten.",
    tooltipHomeCountry: "Tämä auttaa meitä tuomaan oikeat pankkitiedot viimeisessä vaiheessa.",
    tooltipContactNumberCountryCode: "Yhteysnumeron maakoodi.",
    tooltipContactNumber: "Käytä kelvollista numeroa. Käytämme tätä kirjeenvaihtoon palautushakemuksen aikana.",
    tooltipAddressLine1: "Huomaa, että osoitettasi käytetään pankkitilisi vahvistamisessa.",
    tooltipAddressLine2: "Toinen osoiterivi.",
    tooltipCity: "Kotikaupunkisi, tietoja voidaan käyttää pankkitietojen vahvistamiseen.",
    tooltipZipCode: "Postinumero / postinumero.",
    tooltipRegion: "Kotikaupunkisi alue, tietoja voidaan käyttää pankkitietojen vahvistamiseen.",
    tooltipNumberOfTicketsToRefund: "Lipun määrä, jonka haluat hyvitettävän.",
    tooltipTotalRefundAmount: "Tämä ei voi olla suurempi kuin varauksen kokonaisarvo. Et voi lähettää hakemusta, jos se on.",
    tooltipBookingConfirmationFiles: "Kuvakaappaus/liite täydellisestä varausvahvistuksestasi, joka löytyy yleensä sähköpostistasi.",
    tooltipRefundReasonCategory: "Valitse hyvityksen syy.",
    tooltipDateOfIncident: "Päivämäärä, jolloin varaukseesi vaikuttanut tapahtuma tapahtui.",
    tooltipRefundExplanation: "Anna mahdollisimman paljon tietoja, jotka tukevat palautusvaatimusta.",
    tooltipUnableToNotifyWithinTimeLimit: "Ei voida ilmoittaa 60 päivän kuluessa.",
    tooltipReasonForNotificationOutsideTimeLimit: "Ilmoitus määräajan ulkopuolella Selitys.",
    tooltipRefundReasonFiles: "Lataa jokin vaadituista asiakirjoista.",
    tooltipEventTypeDate: "Varsinaisen tapahtuman/varauksen päivämäärä.",
    tooltipEventTypeTour: "Varsinaisen kiertueen päivämäärä.",
    tooltipEventTypeFlight: "Varsinaisen lennon päivämäärä.",
    tooltipEventTypeCheckIn: "Varsinaisen sisäänkirjautumisen päivämäärä.",
    tooltipEventTypeTransfer: "Varsinaisen siirron päivämäärä.",
    tooltipEventTypeParking: "Varsinaisen pysäköinnin päivämäärä.",
    tooltipEventTypeOtherTravel: "Varsinaisen matkan alkamispäivä."
};