export default {
    messageQuestionService: "Deze service is alleen voor gebruik als u een probleem heeft met het invullen van het aanvraagformulier, we kunnen hier geen andere vragen beantwoorden.",
    labelName: "Naam",
    labelReference: "Boekingsreferentie",
    labelPhone: "Telefoonnummer",
    labelEmail: "E-mail",
    labelQuery: "Uw vraag",
    labelAskQuestion: "Stuur ons hier een vraag ...",
    buttonOpenModal: "Een vraag stellen",
    title: "Een vraag stellen",
    modalTitle: "4. Heeft u een technisch probleem met het formulier?",
    toastSuccess: "Wij hebben uw vraag ontvangen en nemen binnen 24 uur contact met u op",
    toastError: "Er is iets misgegaan met uw verzoek. Probeer het opnieuw",
    nameValidation: "Naam is vereist",
    referenceValidation: "Referentie is vereist",
    questionValidation: "Uw vraag is verplicht"
}