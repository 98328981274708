export default {
    messageQuestionService: "Denne service er kun til brug, hvis du har et problem med at udfylde ansøgningsskemaet, vi kan ikke svare på andre spørgsmål her.",
    labelName: "Navn",
    labelReference: "Bookingnummer",
    labelPhone: "Telefonnummer",
    labelEmail: "E-mail",
    labelQuery: "Dit spørgsmål",
    labelAskQuestion: "Send os et spørgsmål her ...",
    buttonOpenModal: "Stil et spørgsmål",
    title: "Stil et spørgsmål",
    modalTitle: "4. Har du et teknisk problem med formularen?",
    toastSuccess: "Vi har modtaget dit spørgsmål, og vi kontakter dig inden for 24 timer",
    toastError: "Noget gik galt med din anmodning, prøv igen",
    nameValidation: "Navn er påkrævet",
    referenceValidation: "Reference er påkrævet",
    questionValidation: "Dit spørgsmål er påkrævet"
}