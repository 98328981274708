import * as React from 'react';
import {
    Box,
    Button,
    ThemeProvider
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    useEffect,
    useState
} from 'react';
import {
    FormikErrors,
    FormikProps
} from 'formik';
import {
    getQueryParamValue,
    isAutoDeclineReason,
    isNullOrWhitespace,
    isRefundAmountLess
} from '../utils/helpers'
import {
    useRecoilState,
    useRecoilValue
} from 'recoil';
import {
    BookingConfirmationReminderState,
    EvidenceReminderState,
    RefundAmountConfirmationModalCounterState
} from '../../state/activity';
import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import {OptionNo} from '../utils/constants';
import {useQuestionContext} from '../providers/QuestionProvider';
import {CancelButton} from './CancelButton';
import {BorderRadiusButton} from '../utils/constants-styling';
import {ConfirmRefundAmountModal} from '../modals/ConfirmRefundAmountModal';
import {ButtonTextTheme} from '../themes/ButtonTextTheme';
import {BookingConfirmationReminderModal} from '../modals/BookingConfirmationReminderModal';
import {EvidenceReminderModal} from '../modals/EvidenceReminderModal';
import {useValidationContext} from '../providers/ValidationProvider';
import {buttonTheme} from '../themes/ButtonThemeProvider';

interface NavigationButtonsProps {
    formProps: FormikProps<IFormValues>;
    url: string;
}

export const NavigationButtons = (navigationButtonsProps: NavigationButtonsProps) => {

    const {
        formProps,
        url
    } = navigationButtonsProps;

    const {
        cancel,
        getNextSection,
        getPreviousSection,
        hidePaymentInformation,
        isExtendedTerms,
        isHiddenCallback,
        loadingBeneficiaryLabels,
        selectedSection,
        setSelectedSection
    } = useFormContext();

    const {
        openModal
    } = useQuestionContext();

    const {
        saveFocus,
        saveValidate,
        submitFocus,
        submitValidate
    } = useValidationContext();

    const {t} = useTranslation();

    const refundAmountConfirmationModalCounter = useRecoilValue(RefundAmountConfirmationModalCounterState);
    const [showBookingConfirmationReminder, setBookingConfirmationReminder] = useRecoilState(BookingConfirmationReminderState);
    const [showEvidenceReminder, setEvidenceReminder] = useRecoilState(EvidenceReminderState);
    const [showConfirmRefundAmountModal, setShowConfirmRefundAmountModal] = useState<boolean>(false);
    const [validateSave, setValidateSave] = useState<boolean>(false);
    const [validateSubmit, setValidateSubmit] = useState<boolean>(false);

    const isNextHidden = (formProps: FormikProps<IFormValues>) => {

        if (isAutoDeclineReason(formProps.values.refundReasonCategory, isExtendedTerms)
            && formProps.values.refundReasonConfirm === OptionNo) {
            return true;
        }

        return (hidePaymentInformation(formProps) && selectedSection === Section.RefundRequest) || selectedSection === Section.PaymentInformation;
    }

    const isSubmitHidden = (formProps: FormikProps<IFormValues>) => {

        if (isAutoDeclineReason(formProps.values.refundReasonCategory, isExtendedTerms)
            && formProps.values.refundReasonConfirm === OptionNo) {
            return true;
        }

        return !(isHiddenCallback(Section.BookingAndContactInformation) && (isHiddenCallback(Section.RefundRequest) || hidePaymentInformation(formProps)));
    }

    const nextStepHandler = (formProps: FormikProps<IFormValues>) => {

        const showModal = selectedSection === Section.BookingAndContactInformation
            && !formProps.values.isRefundAmountConfirmed
            && isRefundAmountLess(formProps.values, formProps.values.totalRefundAmount)
            && isNullOrWhitespace(getQueryParamValue('id', url))
            && refundAmountConfirmationModalCounter === 1;

        if (showModal) {
            setShowConfirmRefundAmountModal(true);
            return;
        }

        setSelectedSection(getNextSection(formProps));
    }

    const cancelHandler = () => {

        cancel(formProps);
    }

    const saveHandler = (formProps: FormikProps<IFormValues>) => {

        saveValidate(formProps);
        setValidateSave(true);
    }

    const submitHandler = (errors: FormikErrors<IFormValues>) => {

        submitValidate(errors);
        setValidateSubmit(true);
    }

    const closeConfirmTotalRefundAmountModalHandler = () => {

        setShowConfirmRefundAmountModal(false);
    }

    const closeBookingConfirmationReminderModalHandler = () => {

        setBookingConfirmationReminder(false);
    }

    const closeEvidenceReminderModalHandler = () => {

        setEvidenceReminder(false);
    }

    useEffect(() => {

        if (!validateSave) {
            return;
        }

        saveFocus(formProps);

        setValidateSave(false);
    }, [validateSave]);

    useEffect(() => {

        if (!validateSubmit) {
            return;
        }

        submitFocus(formProps.errors);

        setValidateSubmit(false);
    }, [validateSubmit]);

    return (
        <>
            <Grid size={{xs: 2, md: 4}}>
                <Box justifyContent={{xs: 'flex-start', md: 'flex-end'}} display='flex' ml={1}>
                    <CancelButton
                        buttonText={t('common:buttonCancel')}
                        handleOnClick={cancelHandler}
                    />

                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                border: '1px solid #9B9B9B',
                                color: 'black',
                                ml: '10px',
                            }}
                            onClick={openModal}
                            hidden={selectedSection !== Section.PaymentInformation}
                            disabled={loadingBeneficiaryLabels}
                            endIcon={<HelpOutlineIcon fontSize='small'/>}>
                            <ButtonTextTheme buttonText='Question'/>
                        </Button>
                    </ThemeProvider>
                </Box>
            </Grid>
            <Grid size={{xs: 10, md: 5}}>
                <Box justifyContent='flex-end' display='flex' mr={1}>
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                border: '1px solid #9B9B9B',
                                color: 'black',
                            }}
                            onClick={() => saveHandler(formProps)}
                            disabled={loadingBeneficiaryLabels}
                            hidden={!isHiddenCallback(Section.PaymentInformation)}
                            endIcon={<SaveOutlinedIcon fontSize='small'/>}>
                            <ButtonTextTheme buttonText={t('common:buttonSave')}/>
                        </Button>
                    </ThemeProvider>

                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            sx={{ml: '10px'}}
                            onClick={() => setSelectedSection(getPreviousSection(formProps))}
                            disabled={loadingBeneficiaryLabels}
                            hidden={selectedSection === Section.BookingAndContactInformation}
                            startIcon={<KeyboardArrowLeftIcon fontSize='small'/>}>
                            <ButtonTextTheme buttonText={t('common:buttonPrevious')}/>
                        </Button>
                    </ThemeProvider>

                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            sx={{ml: '10px'}}
                            onClick={() => nextStepHandler(formProps)}
                            disabled={loadingBeneficiaryLabels}
                            hidden={isNextHidden(formProps)}
                            endIcon={<KeyboardArrowRightIcon fontSize='small'/>}>
                            <ButtonTextTheme buttonText={t('common:buttonNext')}/>
                        </Button>
                    </ThemeProvider>

                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            type='submit'
                            sx={{ml: '10px'}}
                            onClick={() => submitHandler(formProps.errors)}
                            disabled={formProps.isSubmitting || loadingBeneficiaryLabels}
                            hidden={isSubmitHidden(formProps)}
                            endIcon={<KeyboardArrowRightIcon fontSize='small'/>}>
                            {t('common:buttonSubmit')}
                        </Button>
                    </ThemeProvider>
                </Box>
            </Grid>

            <ConfirmRefundAmountModal
                formValues={formProps.values}
                handleClose={closeConfirmTotalRefundAmountModalHandler}
                showModal={showConfirmRefundAmountModal}
            />

            <BookingConfirmationReminderModal
                close={closeBookingConfirmationReminderModalHandler}
                showBookingConfirmationReminder={showBookingConfirmationReminder}
            />

            <EvidenceReminderModal
                close={closeEvidenceReminderModalHandler}
                showEvidenceReminder={showEvidenceReminder}
            />
        </>
    );
}