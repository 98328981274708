export default {
    labelBookingCancelledPostponedHeading: "Adakah tempahan anda telah dibatalkan atau ditangguhkan? Baca lagi",
    labelBookingCancelledPostponedText1: "Klik",
    labelBookingCancelledPostponedText2: "untuk maklumat lebih lanjut.",
    labelNewRefundHeading: "Permohonan bayaran balik baru?",
    labelNewRefundText: "Masukkan rujukan tempahan anda di sini ...",
    placeholderBookingReference: "Rujukan tempahan",
    validationBookingReference: "Rujukan Tempahan diperlukan",
    validationEmailAsBookingReference: "Alamat e-mel tidak diterima sebagai rujukan tempahan",
    buttonFindBooking: "Cari Tempahan",
    labelEmailHeading: "Sudah bermula?",
    labelEmailText: "Hantar peringatan e-mel untuk mengambil aplikasi anda.",
    placeholderEmail: "Email",
    buttonEmailReminder: "Peringatan E-mel",
    labelCustomerName : "Nama Pelanggan",
    validationCustomerName: "Nama Pelanggan diperlukan",
    labelTypeOfBooking: "Jenis Tempahan",
    validationTypeOfBooking: "Jenis Tempahan diperlukan",
    labelEventName:"Nama acara",
    labelVenueName:"Nama Tempat",
    labelFlightNumber:"Nombor penerbangan",
    labelOriginAirport: "Lapangan Terbang Asal",
    labelHotelName: "Nama Penginapan",
    labelHotelLocation: "Lokasi Penginapan",
    labelCurrency: "Mata Wang",
    validationCurrency: "Mata wang diperlukan",
    validationCountryCurrency: "Mata Wang yang dipilih mestilah mata wang Negara Asal anda",
    validationOfflinePayment: "Kami tidak boleh memproses bayaran balik untuk pilihan mata wang dan negara anda, sila pilih alternatif",
    labelBookingValue: "Nilai Tempahan",
    validationBookingValue: "Nilai Tempahan diperlukan",
    validationBookingValueAmount: "Mesti lebih besar daripada 0",
    labelDateOfPurchase: "Tarikh pembelian",
    validationDateOfPurchase: "Tarikh Pembelian diperlukan",
    labelEmailAddress: "Alamat emel",
    validationEmailAddress: "Alamat E-mel diperlukan",
    validationEmailAddressFormat: "Format e-mel yang salah",
    labelConfirmEmailAddress: "Sahkan alamat emel",
    validationConfirmEmailAddressFormat: "Format e-mel yang salah",
    validationConfirmEmailAddress: "Alamat e-mel pengesahan diperlukan",
    validationConfirmEmailAddressMatch: "Alamat e-mel tidak sepadan",
    validationEmailDomain: "Pembekal e-mel ini tidak diterima, sila gunakan alternatif",
    validationDateOfEvent: "Tarikh acara diperlukan",
    labelHomeCountry: "Negara asal",
    validationHomeCountry: "Negara asal diperlukan",
    labelContactNumberCountryCode: "Nombor Perhubungan Kod Negara",
    validationContactNumberCountryCode: "Kod Dail Negara diperlukan",
    labelContactNumber: "Nombor telefon",
    validationContactNumber: "Nombor Perhubungan diperlukan",
    labelAddressLine1: "Alamat Baris 1",
    labelAddressLine2: "Alamat 2",
    labelCity: "Bandar",
    labelZipCode: "Poskod",
    labelRegion: "Wilayah",
    validationRegion: "Kawasan diperlukan",
    labelNumberOfTicketsToRefund: "Bilangan Tiket untuk Bayaran Balik",
    validationNumberOfTicketsToRefund: "Jumlah Tiket untuk Bayaran Balik diperlukan",
    validationNumberOfTicketsToRefundValue: "Mesti lebih besar daripada 0",
    labelCostPerTicket: "Kos setiap Tiket",
    validationCostPerTicket: "Kos per Tiket diperlukan",
    validationCostPerTicketValue: "Mesti lebih besar daripada 0",
    labelTotalRefundAmount: "Jumlah Bayaran Balik",
    validationTotalRefundAmount: "Jumlah Jumlah Bayaran Balik diperlukan",
    validationTotalRefundAmountValue: "Mesti lebih besar daripada 0",
    validationTotalRefundGreaterThanBookingValue: "Jumlah Amaun Bayaran Balik tidak boleh lebih besar daripada Nilai Tempahan di atas",
    labelBookingConfirmationFiles: "Muat naik Pengesahan Tempahan",
    validationBookingConfirmationFiles: "Lampirkan sekurang-kurangnya satu fail atau klik Simpan dan kembali kemudian apabila anda mempunyai dokumen",
    validationEvidenceFilesPopup: "Bukti perlu dimuat naik sebelum dihantar. Sekiranya anda tidak mempunyai bukti sekarang, sila simpan permohonan dan kembalikan setelah anda memilikinya. Kami menerima salinan elektronik, salinan pengimbas dan foto.",
    validationBookingConfirmationFilesPopup: "Pengesahan tempahan perlu dimuat naik sebelum dihantar. Jika anda tidak mempunyai pengesahan tempahan sekarang, sila simpan permohonan itu dan kembalikan setelah anda ada. Kami menerima salinan elektronik, salinan pengimbas dan foto.",
    alertUploadDocuments: "Sila muat naik dokumentasi berkaitan yang diperincikan dalam e-mel dan kemudian teruskan ke Langkah 3.",
    labelRefundReasonCategory: "Sebab Bayaran Balik",
    validationRefundReasonCategory: "Sebab Bayaran Balik diperlukan",
    labelDateOfIncident: "Tarikh Kejadian",
    labelAdditionalComments: "Komen tambahan",
    tooltipAdditionalComments: "Komen tambahan",
    validationAdditionalComments: "Komen tambahan diperlukan",
    labelUnableToNotifyWithinTimeLimit: "Tidak Dapat Memberitahu Dalam Masa 60 Hari Dari",
    labelReasonForNotificationOutsideTimeLimit: "Makluman Penjelasan Had Masa Luar",
    validationReasonForNotificationOutsideTimeLimit: "Sebab pemberitahuan selepas 60 hari diperlukan",
    labelRefundReasonFiles: "Muat naik Bukti",
    validationRefundReasonFiles: "Lampirkan sekurang-kurangnya satu fail atau klik Simpan dan kembali kemudian apabila anda mempunyai dokumen",
    validationTextScript: "Mesti sepadan dengan tulisan latin",
    optionTypeOfBooking1: "Acara",
    optionTypeOfBooking2: "Lawatan",
    optionTypeOfBooking3: "Penerbangan",
    optionTypeOfBooking4: "Penginapan",
    optionTypeOfBooking5: "Pindahkan",
    optionTypeOfBooking6: "Tempat letak kenderaan",
    optionTypeOfBooking7: "Perjalanan lain",
    labelEventTypeDate: "Tarikh Acara",
    labelEventTypeTour: "Tarikh Lawatan",
    labelEventTypeFlight: "Tarikh Penerbangan",
    labelEventTypeCheckIn: "Tarikh Daftar Masuk",
    labelEventTypeTransfer: "Tarikh Pindahan",
    labelEventTypeParking: "Tarikh Tempat Letak Kereta",
    labelEventTypeOtherTravel: "Tarikh Perjalanan",
    optionRefundReason11: "Cuaca buruk",
    optionRefundReason10: "Penarikan balik Angkatan Bersenjata & Perkhidmatan Kecemasan",
    optionRefundReason15: "Perubahan pada Tarikh Peperiksaan",
    optionRefundReason18: "Saman Mahkamah",
    optionRefundReason17: "Sekatan perjalanan Covid-19",
    optionRefundReason19: "Ujian Positif COVID-19",
    optionRefundReason20: "Tempahan Dibatalkan oleh Pengendali / Penganjur",
    optionRefundReason21: "Tempahan Dijadualkan Semula oleh Operator/Penganjur",
    optionRefundReason22: "Tempah Tarikh yang Salah",
    optionRefundReason23: "Tempah Tiket Salah",
    optionRefundReason24: "Tempah Terlalu Banyak Tiket",
    optionRefundReason4: "Kematian",
    optionRefundReason16: "Keadaan Kecemasan",
    optionRefundReason14: "Larangan Perjalanan Kerajaan",
    optionRefundReason9: "Kecemasan Rumah",
    optionRefundReason1: "Penyakit / Kecederaan / Kemalangan",
    optionRefundReason8: "Perkhidmatan Juri",
    optionRefundReason7: "Pecahan Mekanikal",
    optionRefundReason2: "Keadaan Perubatan yang sedia ada",
    optionRefundReason3: "Komplikasi Kehamilan",
    optionRefundReason5: "Kegagalan Pengangkutan Awam",
    optionRefundReason6: "Gangguan Penerbangan",
    optionRefundReason13: "Pencurian Dokumen",
    optionRefundReason12: "Penempatan Semula Kerja",
    optionRefundReason25: "Lebihan Tempat Kerja",
    optionRefundReason26: "Pilihan Ujian",
    optionRefundReasonConfirm1: "Tidak",
    optionRefundReasonConfirm2: "Ya",
    optionRefundReasonArmedForces1: "Tentera",
    optionRefundReasonArmedForces2: "Tentera laut",
    optionRefundReasonArmedForces3: "Tentera Udara",
    optionRefundReasonArmedForces4: "Pasukan Khas",
    optionRefundReasonArmedForces5: "Marin",
    optionRefundReasonArmedForces6: "Api",
    optionRefundReasonArmedForces7: "Ambulans",
    optionRefundReasonArmedForces8: "Doktor/Jururawat",
    optionRefundReasonArmedForces9: "Doktor gigi",
    optionRefundReasonArmedForces10: "Lain",
    optionRefundReasonCourtSummons1: "Pendakwaan Jenayah",
    optionRefundReasonCourtSummons2: "Kes Undang-undang Sivil",
    optionRefundReasonCourtSummons3: "Saksi dalam Pendakwaan",
    optionRefundReasonCourtSummons4: "Saksi dalam Kes Sivil",
    optionRefundReasonCourtSummons5: "Plaintif",
    optionRefundReasonCourtSummons6: "Defendan",
    optionRefundReasonCourtSummons7: "Lain",
    optionRefundReasonDeath1: "Suami",
    optionRefundReasonDeath2: "Isteri",
    optionRefundReasonDeath3: "Bunyi",
    optionRefundReasonDeath4: "Anak perempuan",
    optionRefundReasonDeath5: "Bapa",
    optionRefundReasonDeath6: "Ibu",
    optionRefundReasonDeath7: "Saudara",
    optionRefundReasonDeath8: "Kakak",
    optionRefundReasonDeath9: "Datuk",
    optionRefundReasonDeath10: "Nenek",
    optionRefundReasonDeath11: "Lain",
    optionRefundReasonHomeEmergency1: "Api",
    optionRefundReasonHomeEmergency2: "Banjir",
    optionRefundReasonHomeEmergency3: "Rompakan",
    optionRefundReasonHomeEmergency4: "Vandalisme",
    optionRefundReasonHomeEmergency5: "Penyalahgunaan Domestik",
    optionRefundReasonHomeEmergency6: "Kidnap",
    optionRefundReasonHomeEmergency7: "Lain",
    optionRefundReasonCOVID19Person1: "Sendiri",
    optionRefundReasonCOVID19Person2: "Orang lain dalam tempahan",
    optionRefundReasonCOVID19Person3: "Seseorang dalam Rumah Tangga saya",
    optionRefundReasonCOVID19Person4: "Lain",
    optionRefundReasonCOVID19TestType1: "Ujian PCR",
    optionRefundReasonCOVID19TestType2: "Ujian RAT",
    optionRefundReasonCOVID19TestType3: "Ujian Aliran Sisi",
    optionRefundReasonCOVID19TestType4: "Ujian Ditadbir Sendiri",
    optionRefundReasonCOVID19TestType5: "Tiada ujian tetapi saya mempunyai gejala",
    optionRefundReasonCOVID19TestType6: "Lain",
    optionRefundReasonBreakdown1: "Kerosakan",
    optionRefundReasonBreakdown2: "Kemalangan",
    optionRefundReasonBreakdown3: "Api",
    optionRefundReasonBreakdown4: "Kecurian",
    optionRefundReasonPublicTransportType1: "Kereta api",
    optionRefundReasonPublicTransportType2: "Bas",
    optionRefundReasonPublicTransportType3: "Trem",
    optionRefundReasonPublicTransportType4: "Bot",
    optionRefundReasonDocumentType1: "Pasport",
    optionRefundReasonDocumentType2: "Permit Perjalanan",
    optionRefundReasonDocumentType3: "ID Kebangsaan",
    optionRefundReasonDocumentType4: "Tiket",
    optionRefundReasonWorkRelocationType1: "Sementara",
    optionRefundReasonWorkRelocationType2: "Kekal",
    optionRefundReasonWorkRelocationTemporary1: "0-3 bulan",
    optionRefundReasonWorkRelocationTemporary2: "3-6 bulan",
    optionRefundReasonWorkRelocationTemporary3: "Lebih 6 bulan",
    optionRefundReasonBookingRelationshipType1: "Seseorang dalam tempahan",
    optionRefundReasonBookingRelationshipType2: "Saudara terdekat seseorang dalam tempahan",
    optionEmploymentType1: "Sepenuh Masa",
    optionEmploymentType2: "Separuh Masa",
    optionEmploymentType3: "Kontrak",
    optionEmploymentLengthType1: "0-1 Tahun",
    optionEmploymentLengthType2: "1-2 Tahun",
    optionEmploymentLengthType3: "Lebih daripada 2 tahun",
    textRefundEvidenceHeading: "Sila berikan bukti berikut:",
    textRefundEvidence1: "Pengesahan Syarikat Penerbangan",
    textRefundEvidence2: "Sebarang bukti sokongan yang sesuai",
    textRefundEvidence3: "Pengesahan panggilan keluar perkhidmatan kerosakan",
    textRefundEvidence4: "Sijil Kematian",
    textRefundEvidence5: "Pembatalan pengesahan daripada syarikat penerbangan",
    textRefundEvidence6: "Laporan Cuaca",
    textRefundEvidence7: "Surat-menyurat daripada ejen tempahan mengesahkan tiada keupayaan untuk mengeluarkan semula tiket",
    textRefundEvidence8: "Surat dari Mahkamah",
    textRefundEvidence9: "Sijil Perubatan / Nota Doktor",
    textRefundEvidenceDescription9: "Mengesahkan butiran penyakit atau kecederaan, tarikh ia mula-mula berlaku, bahawa ia menghalang Anda daripada Menghadiri Tempahan.",
    textRefundEvidence10: "Pengesahan surat majikan",
    textRefundEvidence11: "Surat-menyurat tuntutan insurans",
    textRefundEvidence12: "Surat daripada Majikan",
    textRefundEvidence13: "Pengesahan laman web Kerajaan Nasional",
    textRefundEvidence14: "Notis Perubahan Peperiksaan",
    textRefundEvidence15: "Laporan Polis",
    textRefundEvidence16: "Laporan polis dan nombor rujukan",
    textRefundEvidence17: "Pengesahan pembekal pengangkutan",
    textRefundEvidence18: "Amaran Perjalanan",
    textRefundEvidence20: "Sijil Ujian COVID-19",
    textRefundEvidence24: "Notis Pengasingan Kerajaan",
    textRefundEvidence25: "Surat daripada Pegawai Memerintah",
    textRefundEvidence26: "Surat daripada Penanggung Insurans",
    textRefundEvidence27: "Bukti Alamat",
    textRefundEvidence28: "Bukti hubungan dengan si mati",
    textRefundEvidence29: "Bukti hubungan dengan orang yang cedera / sakit",
    textRefundEvidence30: "Slip gaji",
    tooltipBookingReference: "Untuk mengenal pasti rujukan tempahan anda, semak pengesahan tempahan anda dan lihat bahagian tentang perkhidmatan lanjutan kami.",
    tooltipCustomerName: "Nama depan dan belakang yang digunakan untuk membuat tempahan.",
    tooltipTypeOfBooking: "Jenis tempahan yang anda mahu dikembalikan.",
    tooltipEventName: "Nama acara tersebut.",
    tooltipVenueName: "Tdia nama tempat.",
    tooltipFlightNumber: "Nombor penerbangan.",
    tooltipOriginAirport: "Lapangan terbang berlepas.",
    tooltipHotelName: "Nama tempat penginapan.",
    tooltipHotelLocation: "Lokasi tempat penginapan.",
    tooltipCurrency: "Mata wang yang digunakan untuk membayar tempahan.",
    tooltipBookingValue: "Jumlah harga yang anda bayar untuk tempahan.",
    tooltipDateOfPurchase: "Tarikh tempahan dibuat.",
    tooltipEmailAddress: "Sila gunakan alamat e-mel yang sah. Kami menggunakan e-mel ini untuk surat-menyurat semasa permohonan pembayaran balik.",
    tooltipConfirmEmailAddress: "Gunakan alamat e-mel yang sama seperti di atas, ini untuk pengesahan.",
    tooltipHomeCountry: "Ini akan membantu kita mengemukakan maklumat perbankan yang betul pada langkah terakhir.",
    tooltipContactNumberCountryCode: "Kod negara nombor hubungan.",
    tooltipContactNumber: "Sila gunakan nombor yang sah. Kami menggunakan ini untuk surat-menyurat semasa permohonan pembayaran balik.",
    tooltipAddressLine1: "Harap maklum bahawa alamat anda akan digunakan ketika mengesahkan akaun bank anda.",
    tooltipAddressLine2: "Talian alamat kedua.",
    tooltipCity: "Bandar asal anda, perincian dapat digunakan untuk mengesahkan perincian perbankan.",
    tooltipZipCode: "Poskod / Poskod.",
    tooltipRegion: "Kawasan bandar asal anda, perincian dapat digunakan untuk mengesahkan perincian perbankan.",
    tooltipNumberOfTicketsToRefund: "Jumlah tiket yang anda mahu dikembalikan.",
    tooltipTotalRefundAmount: "Jumlah ini tidak boleh melebihi nilai keseluruhan tempahan, anda tidak akan dapat mengemukakan permohonan sekiranya berlaku.",
    tooltipBookingConfirmationFiles: "Tangkapan skrin / lampiran pengesahan tempahan penuh anda yang biasanya terdapat di e-mel anda.",
    tooltipRefundReasonCategory: "Sila pilih alasan pembayaran balik anda.",
    tooltipDateOfIncident: "Tarikh kejadian yang mempengaruhi tempahan anda berlaku.",
    tooltipRefundExplanation: "Berikan seberapa banyak maklumat yang menyokong tuntutan pengembalian wang.",
    tooltipUnableToNotifyWithinTimeLimit: "Tidak dapat memberitahu dalam had masa 60 Hari.",
    tooltipReasonForNotificationOutsideTimeLimit: "Makluman Penjelasan Had Masa Luar.",
    tooltipRefundReasonFiles: "Sila muat naik mana-mana dokumen yang diperlukan.",
    tooltipEventTypeDate: "Tarikh acara / tempahan sebenar.",
    tooltipEventTypeTour: "Tarikh lawatan sebenar.",
    tooltipEventTypeFlight: "Tarikh penerbangan sebenar.",
    tooltipEventTypeCheckIn: "Tarikh daftar masuk sebenar.",
    tooltipEventTypeTransfer: "Tarikh pemindahan sebenar.",
    tooltipEventTypeParking: "Tarikh tempat letak kenderaan sebenar.",
    tooltipEventTypeOtherTravel: "Tarikh mula perjalanan sebenar."
};