export default {
    labelSavedApplicationReference:"已保存的應用參考:",
    labelLanguage: "語言",
    messageWarningBookingHeading1: "預訂已取消：",
    messageWarningBookingHeading2: "預訂推遲/重新安排：",
    messageWarningBookingBody1: "如果組織者或航空公司取消了您的預訂，此團隊將無法協助您退款。 您將需要直接與您的客戶服務團隊聯繫； 請參閱您的預訂確認信或網站以獲取聯繫方式。",
    messageWarningBookingBody2: "如果您的預訂已被推遲，則您延長的可退款條款將延續到新日期。如果您有根據我們的條款退款的正當理由，請填寫表格並提供正確的支持證據。",
    messageWarningBookingBody3: "請注意：此服務只能根據我們的措辭中包含的原因提供退款，可在您的預訂確認電子郵件中找到。",
    messageWarningBookingBody4: "如果您因我們條款以外的原因無法預訂，我們將無法提供幫助。您需要直接聯繫您的客戶服務團隊，因為他們可能會提供幫助；請查看您的預訂確認信或網站了解詳細聯繫信息。",
    linkWarningBooking: "這裡",
    formSectionNavigatorStep1:"步驟1",
    formSectionNavigatorStep2:"第2步",
    formSectionNavigatorStep3:"第3步",
    formSectionNavigatorStep1Text: "聯絡方式及預訂詳情",
    formSectionNavigatorStep2Text: "退款原因",
    formSectionNavigatorStep3Text: "付款詳情",
    messageBookingMatchedAndFound: "如果您的預訂參考號碼與現有申請相關聯，我們將向您發送一封電子郵件，其中包含訪問您的申請的鏈接",
    messageBookingMatchedAndFoundCompleted: "您的退款申請已被批准！如果您的付款有任何問題，請聯繫：",
    applicationDeclinedMessage: "您的申請已被拒絕，現已關閉。",
    messageBookingNotFound1: "我們找不到帶有該參考的預訂。請檢查您的預訂確認電子郵件並重新輸入。",
    messageBookingNotFound2: "別擔心，如果您的預訂編號不起作用，您可以選擇“查找預訂”並輸入您的詳細信息。填寫表格時，請上傳您的預訂確認電子郵件的副本，以幫助我們找到您的預訂詳細信息。",
    messageLocatingBooking:"您的預訂位置...",
    messageLoadingBank:"正在加載銀行信息...",
    messageFailCurrencyLookup:"檢索貨幣時出錯",
    messageSavingApplication:"正在保存您的應用程序...",
    messageSavedApplication:"退款請求已成功保存",
    messageFailSavedApplication:"無法保存退款請求",
    messageSendingEmailReminder:"加工...",
    messageErrorLocationDetails:"檢索位置詳細信息時出錯",
    messageFailFormSubmission:"表單提交失敗",
    messageApplicationCannotBeFound:"找不到該應用程序",
    messageApplicationRetrievalError:"檢索應用程序時出錯",
    messageSubmittingApplication:"正在發送應用程序...",
    messageEventDateLimitError:"原因導致通知超時",
    messageAllTicketsAssigned: "所有機票都已分配給另一個退款申請。",
    selectPrefix:"選擇",
    selectPostfix: "",
    fileUpload:"單擊此處下載",
    fileUploadAcceptedFilesMessage: "我們只接受 PDF 和 .png、.jpeg、.heic、.webp 的圖像格式",
    fileUploadNoFiles:"選擇了0個文件",
    fileUploadPreviouslyUploaded:"先前已上傳:",
    fileUploadSelectedUploaded: "所選內容已上傳：",
    buttonNext:"下一步",
    buttonPrevious:"上一個",
    buttonSave:"保存",
    buttonCancel:"取消",
    buttonSubmit:"提交",
    buttonConfirm: "確認",
    buttonYes: "是的",
    buttonNo: "不",
    buttonClose: "關閉",
    linkPrivacyStatement: "隱私聲明",
    linkComplaintsPolicy: "申訴政策",
    validationRequired: "必需的",
    validationInvalid: "無效的",
    labelUploadPrefix: "上傳",
    labelUploadPostfix: "",
    labelChange: "改變",
    labelView: "視圖",
    browserNavigationMessage: "未保存的更改將丟失"
};