export default {
    labelSavedApplicationReference: "Ref. aplicación guardada:",
    labelLanguage: "Idioma",
    messageWarningBookingHeading1: "Reserva cancelada:",
    messageWarningBookingHeading2: "Reserva aplazada / reprogramada:",
    messageWarningBookingBody1: "Si su reserva ha sido cancelada por el organizador o la aerolínea, este equipo no podrá ayudarlo con un reembolso. Deberá ponerse en contacto directamente con su equipo de servicio al cliente; consulte la confirmación de su reserva o el sitio web para obtener los detalles de contacto.",
    messageWarningBookingBody2: "Si su reserva ha sido pospuesta, sus términos reembolsables extendidos se trasladarán a la nueva fecha. Si tiene una razón válida para el reembolso según nuestros términos, complete el formulario con la evidencia de respaldo correcta.",
    messageWarningBookingBody3: "Tenga en cuenta: este servicio solo puede proporcionar reembolsos por los motivos incluidos en nuestra redacción, disponible en el correo electrónico de confirmación de su reserva.",
    messageWarningBookingBody4: "Si no puede realizar la reserva por motivos ajenos a nuestros términos, no podremos ayudarle. Deberá comunicarse directamente con su equipo de servicio al cliente, ya que pueden ayudarlo; Consulte la confirmación de su reserva o el sitio web para obtener información de contacto.",
    linkWarningBooking: "aquí",
    formSectionNavigatorStep1: "Paso 1",
    formSectionNavigatorStep2: "Paso 2",
    formSectionNavigatorStep3: "Paso 3",
    formSectionNavigatorStep1Text: "Detalles de contacto y reserva",
    formSectionNavigatorStep2Text: "Motivo del reembolso",
    formSectionNavigatorStep3Text: "Detalles de pago",
    messageBookingMatchedAndFound: "Si su referencia de reserva está asociada con una aplicación existente, le enviaremos un correo electrónico con un enlace para acceder a su aplicación.",
    messageBookingMatchedAndFoundCompleted: "¡Su solicitud de reembolso ya ha sido aprobada! Si tiene algún problema con su pago, comuníquese con:",
    applicationDeclinedMessage: "Su solicitud ha sido rechazada y ahora está cerrada.",
    messageBookingNotFound1: "No podemos encontrar una reserva con esa referencia. Por favor, revise el correo electrónico de confirmación de su reserva y vuelva a ingresar.",
    messageBookingNotFound2: "No se preocupe, si su referencia de reserva no funciona, puede seleccionar 'Buscar reserva' e ingresar sus datos. Al completar el formulario, cargue una copia de su correo electrónico de confirmación de reserva para ayudarnos a localizar los detalles de su reserva.",
    messageLocatingBooking: "Localizando su reserva ... ",
    messageLoadingBank: "Cargando información bancaria ...",
    messageFailCurrencyLookup: "Error al recuperar la moneda",
    messageSavingApplication: "Guardando su aplicación ...",
    messageSavedApplication: "Solicitud de reembolso guardada correctamente",
    messageFailSavedApplication: "No se pudo guardar la solicitud de reembolso",
    messageSendingEmailReminder: "Procesando...",
    messageErrorLocationDetails: "Error al recuperar los detalles de la ubicación",
    messageFailFormSubmission: "Error al enviar el formulario",
    messageApplicationCannotBeFound: "No se pudo encontrar la aplicación",
    messageApplicationRetrievalError: "Error al recuperar la aplicación",
    messageSubmittingApplication: "Enviando solicitud ...",
    messageEventDateLimitError: "Motivo de la notificación fuera del límite de tiempo",
    messageAllTicketsAssigned: "Todos los boletos han sido asignados a otra aplicación de reembolso.",
    selectPrefix: "Seleccione",
    selectPostfix: "",
    fileUpload: "haga clic aquí para subir",
    fileUploadAcceptedFilesMessage: "Solo aceptamos archivos PDF y formatos de imagen de .png, .jpeg, .heic, .webp",
    fileUploadNoFiles: "0 archivos seleccionados",
    fileUploadPreviouslyUploaded: "Subido previamente:",
    fileUploadSelectedUploaded: "Ya se han subido los seleccionados:",
    buttonNext: "Próximo",
    buttonPrevious: "Anterior",
    buttonSave: "Salvar",
    buttonCancel: "Cancelar",
    buttonSubmit: "Enviar",
    buttonConfirm: "Confirmar",
    buttonYes: "Sí",
    buttonNo: "No",
    buttonClose: "Cerrar",
    linkPrivacyStatement: "Declaración de privacidad",
    linkComplaintsPolicy: "Política de quejas",    
    validationRequired: "Requerida",
    validationInvalid: "Inválido",
    labelUploadPrefix: "Subir",
    labelUploadPostfix: "",
    labelChange: "Cambio",
    labelView: "Vista",
    browserNavigationMessage: "Los cambios no guardados se perderán"
};