import * as React from 'react';
import {
    AppBar,
    Box
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LanguageSelector} from '../../../languages/LanguageSelector';
import {ApplicationReference} from '../ui/ApplicationReference';

export const Header = () => {

    return (
        <AppBar elevation={0}
                sx={{
                    position: 'relative',
                    pt: {
                        xs: '10px',
                        sm: '20px',
                        md: '20px'
                    },
                    backgroundColor: 'white',
                    color: 'black'
                }}>
            <Grid container columnSpacing={1}
                  justifyContent='center'
                  sx={{pb: '10px'}}>
                <Grid size={{xs: 6, md: 4}}>
                    <Box pt={1} ml={2}>
                        <b>REFUNDABLE.ME</b>
                    </Box>
                </Grid>
                <Grid size={{xs: 6, md: 4}}>
                    <Box justifyContent='flex-end' display='flex' mr={2}>
                        <LanguageSelector/>
                    </Box>
                </Grid>
            </Grid>

            <ApplicationReference/>

        </AppBar>
    );
}