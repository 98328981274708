export default {
    messageQuestionService: "See teenus on mõeldud kasutamiseks ainult siis, kui teil on taotlusvormi täitmisega probleeme, me ei saa siin muudele küsimustele vastata.",
    labelName: "Nimi",
    labelReference: "Broneeringu viitenumber",
    labelPhone: "Telefoninumber",
    labelEmail: "Meil",
    labelQuery: "Teie küsimus",
    labelAskQuestion: "Saada meile küsimus siia...",
    buttonOpenModal: "Esitage küsimus",
    title: "Esitage küsimus",
    modalTitle: "4. Kas teil on vormiga tehniline probleem?",
    toastSuccess: "Oleme teie küsimuse kätte saanud ja võtame teiega ühendust 24 tunni jooksul",
    toastError: "Teie taotlusega läks midagi valesti, proovige uuesti",
    nameValidation: "Nimi on kohustuslik",
    referenceValidation: "Viide on vajalik",
    questionValidation: "Teie küsimus on vajalik",
};