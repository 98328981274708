export default {
    labelBookingCancelledPostponedHeading: "Czy Twoja rezerwacja została anulowana lub przełożona? Przeczytaj więcej",
    labelBookingCancelledPostponedText1: "Kliknij",
    labelBookingCancelledPostponedText2: "po więcej informacji.",
    labelNewRefundHeading: "Nowy wniosek o zwrot?",
    labelNewRefundText: "Tutaj wprowadź numer rezerwacji ...",
    placeholderBookingReference: "Numer rezerwacji",
    validationBookingReference: "Numer rezerwacji jest wymagany",
    validationEmailAsBookingReference: "Adres e-mail nie jest akceptowany jako numer referencyjny rezerwacji",
    buttonFindBooking: "Znajdź rezerwację",
    labelEmailHeading: "Już zacząłem?",
    labelEmailText: "Wyślij e-mail z przypomnieniem, aby pobrać aplikację.",
    placeholderEmail: "E-mail",
    buttonEmailReminder: "Przypomnienie e-mail",
    labelCustomerName : "Nazwa klienta",
    validationCustomerName: "Nazwa klienta jest wymagana",
    labelTypeOfBooking: "Rodzaj rezerwacji",
    validationTypeOfBooking: "Wymagany jest rodzaj rezerwacji",
    labelEventName:"Nazwa wydarzenia",
    labelVenueName:"Nazwa miejsca",
    labelFlightNumber:"Numer lotu",
    labelOriginAirport: "Lotnisko wylotu",
    labelHotelName: "Nazwa zakwaterowania",
    labelHotelLocation: "Lokalizacja zakwaterowania",
    labelCurrency: "Waluta",
    validationCurrency: "Waluta jest wymagana",
    validationCountryCurrency: "Wybrana waluta musi być walutą Twojego kraju zamieszkania",
    validationOfflinePayment: "Nie możemy dokonać zwrotu pieniędzy za wybraną walutę i kraj. Wybierz alternatywy",
    labelBookingValue: "Wartość rezerwacji",
    validationBookingValue: "Wartość rezerwacji jest wymagana",
    validationBookingValueAmount: "Musi być większa niż 0",
    labelDateOfPurchase: "Data zakupu",
    validationDateOfPurchase: "Data zakupu jest wymagana",
    labelEmailAddress: "Adres e-mail",
    validationEmailAddress: "Adres e-mail jest wymagany",
    validationEmailAddressFormat: "Nieprawidłowy format wiadomości e-mail",
    labelConfirmEmailAddress: "Potwierdź adres email",
    validationConfirmEmailAddressFormat: "Nieprawidłowy format wiadomości e-mail",
    validationConfirmEmailAddress: "Adres e-mail potwierdzenia jest wymagany",
    validationConfirmEmailAddressMatch: "adresy e-mail nie pasują",
    validationDateOfEvent: "Data wydarzenia jest wymagana",
    validationEmailDomain: "Ten dostawca poczty e-mail nie jest akceptowany, użyj alternatywy",
    labelHomeCountry: "Ojczyzna",
    validationHomeCountry: "Home Country is required",
    labelContactNumberCountryCode: "Numer kontaktowy Kod kraju",
    validationContactNumberCountryCode: "Numer kierunkowy kraju jest wymagany",
    labelContactNumber: "Numer kontaktowy",
    validationContactNumber: "Numer kontaktowy jest wymagany",
    labelAddressLine1: "Wiersz adresu 1",
    labelAddressLine2: "Adres, wiersz 2",
    labelCity: "Miasto",
    labelZipCode: "Kod pocztowy",
    labelRegion: "Region",
    validationRegion: "Region jest wymagany",
    labelNumberOfTicketsToRefund: "Liczba biletów do zwrotu",
    validationNumberOfTicketsToRefund: "Liczba biletów do zwrotu jest wymagana",
    validationNumberOfTicketsToRefundValue: "Musi być większa niż 0",
    labelCostPerTicket: "Koszt każdego biletu",
    validationCostPerTicket: "Wymagana jest opłata za bilet",
    validationCostPerTicketValue: " Musi być większa niż 0",
    labelTotalRefundAmount: "Całkowita kwota zwrotu",
    validationTotalRefundAmount: "Całkowita kwota zwrotu jest wymagana",
    validationTotalRefundAmountValue: "Musi być większa niż 0",
    validationTotalRefundGreaterThanBookingValue: "Całkowita kwota zwrotu nie może być większa niż powyższa wartość rezerwacji",
    labelBookingConfirmationFiles: "Prześlij potwierdzenie rezerwacji",
    validationBookingConfirmationFiles: "Załącz co najmniej jeden plik lub kliknij Zapisz i wróć później, gdy będziesz mieć dokumenty",
    validationEvidenceFilesPopup: "Przed przesłaniem należy przesłać dowód. Jeśli nie masz teraz dowodów, zapisz wniosek i odeślij go, gdy już będziesz miał. Przyjmujemy kopie elektroniczne, kopie ze skanera i zdjęcia.",
    validationBookingConfirmationFilesPopup: "Przed przesłaniem wymagane jest przesłanie potwierdzenia rezerwacji. Jeśli nie masz w tej chwili potwierdzenia rezerwacji, zapisz wniosek i wróć, gdy już go masz. Przyjmujemy kopie elektroniczne, kopie ze skanera oraz zdjęcia.",
    alertUploadDocuments: "Prześlij odpowiednią dokumentację wyszczególnioną w wiadomości e-mail, a następnie przejdź do kroku 3.",
    labelRefundReasonCategory: "Powód zwrotu pieniędzy",
    validationRefundReasonCategory: "Wymagany jest powód zwrotu",
    labelDateOfIncident: "Data zdarzenia",
    labelAdditionalComments: "Dodatkowe komentarze",
    tooltipAdditionalComments: "Dodatkowe komentarze",
    validationAdditionalComments: "Wymagane są dodatkowe komentarze",
    labelUnableToNotifyWithinTimeLimit: "Nie można powiadomić w ciągu 60 dni od",
    labelReasonForNotificationOutsideTimeLimit: "Objaśnienie terminu powiadomienia poza terminem",
    validationReasonForNotificationOutsideTimeLimit: "Wymagany jest powód powiadomienia po 60 dniach",
    labelRefundReasonFiles: "Prześlij dowody",
    validationRefundReasonFiles: "Załącz co najmniej jeden plik lub kliknij Zapisz i wróć później, gdy będziesz mieć dokumenty",
    validationTextScript:"Musi pasować do alfabetu łacińskiego",
    optionTypeOfBooking1: "Zdarzenie",
    optionTypeOfBooking2: "Wycieczka",
    optionTypeOfBooking3: "Lot",
    optionTypeOfBooking4: "Nocleg",
    optionTypeOfBooking5: "Transfer",
    optionTypeOfBooking6: "Parking",
    optionTypeOfBooking7: "Inne podróże",
    labelEventTypeDate: "Data wydarzenia",
    labelEventTypeTour: "Data wycieczki",
    labelEventTypeFlight: "Data lotu",
    labelEventTypeCheckIn: "Data zameldowania",
    labelEventTypeTransfer: "Data przeniesienia",
    labelEventTypeParking: " Data postoju",
    labelEventTypeOtherTravel: "Data podróży",
    optionRefundReason11: "Zła pogoda",
    optionRefundReason10: "Przypomnienie sił zbrojnych i służb ratowniczych",
    optionRefundReason15: "Zmiany terminów egzaminów",
    optionRefundReason18: "Wezwania sądowe",
    optionRefundReason17: "COVID-19 Ograniczenia podróży",
    optionRefundReason19: "Pozytywny test na COVID-19",
    optionRefundReason20: "Rezerwacja anulowana przez Operatora/Organizatora",
    optionRefundReason21: "Rezerwacja przesunięta przez operatora/organizatora",
    optionRefundReason22: "Zarezerwowano niewłaściwą datę",
    optionRefundReason23: "Zarezerwowałem złe bilety",
    optionRefundReason24: "Zarezerwowano zbyt wiele biletów",
    optionRefundReason4: "Śmierć",
    optionRefundReason16: "Okoliczności nadzwyczajne",
    optionRefundReason14: "Rządowy zakaz podróżowania",
    optionRefundReason9: "Nagły wypadek w domu",
    optionRefundReason1: "Choroba / uraz / wypadek",
    optionRefundReason8: "Jury Service",
    optionRefundReason7: "Awaria mechaniczna",
    optionRefundReason2: "Wcześniej istniejący stan zdrowia",
    optionRefundReason3: "Powikłanie ciąży",
    optionRefundReason5: "Awaria transportu publicznego",
    optionRefundReason6: "Zakłócenie lotu",
    optionRefundReason13: "Kradzież dokumentów",
    optionRefundReason12: "Przeniesienie pracy",
    optionRefundReason25: "Redundancja w miejscu pracy",
    optionRefundReason26: "Opcja testowa",
    optionRefundReasonConfirm1: "Nie",
    optionRefundReasonConfirm2: "Tak",
    optionRefundReasonArmedForces1: "Armia",
    optionRefundReasonArmedForces2: "Marynarka wojenna",
    optionRefundReasonArmedForces3: "Siły powietrzne",
    optionRefundReasonArmedForces4: "Siły Specjalne",
    optionRefundReasonArmedForces5: "Wojskowy",
    optionRefundReasonArmedForces6: "Pożar",
    optionRefundReasonArmedForces7: "Ambulans",
    optionRefundReasonArmedForces8: "Lekarz/Pielęgniarka",
    optionRefundReasonArmedForces9: "Dentysta",
    optionRefundReasonArmedForces10: "Inny",
    optionRefundReasonCourtSummons1: "Postępowanie karne",
    optionRefundReasonCourtSummons2: "Sprawa cywilna",
    optionRefundReasonCourtSummons3: "Świadek w postępowaniu karnym",
    optionRefundReasonCourtSummons4: "Świadek w sprawie cywilnej",
    optionRefundReasonCourtSummons5: "Powód",
    optionRefundReasonCourtSummons6: "Oskarżony",
    optionRefundReasonCourtSummons7: "Inny",
    optionRefundReasonDeath1: "Mąż",
    optionRefundReasonDeath2: "Żona",
    optionRefundReasonDeath3: "Dźwięk",
    optionRefundReasonDeath4: "Córka",
    optionRefundReasonDeath5: "Ojciec",
    optionRefundReasonDeath6: "Matka",
    optionRefundReasonDeath7: "Brat",
    optionRefundReasonDeath8: "Siostra",
    optionRefundReasonDeath9: "Dziadek",
    optionRefundReasonDeath10: "Babcia",
    optionRefundReasonDeath11: "Inny",
    optionRefundReasonHomeEmergency1: "Pożar",
    optionRefundReasonHomeEmergency2: "Powódź",
    optionRefundReasonHomeEmergency3: "Rabunek",
    optionRefundReasonHomeEmergency4: "Wandalizm",
    optionRefundReasonHomeEmergency5: "Przemoc domowa",
    optionRefundReasonHomeEmergency6: "Porwać",
    optionRefundReasonHomeEmergency7: "Inny",
    optionRefundReasonCOVID19Person1: "Siebie",
    optionRefundReasonCOVID19Person2: "Inna osoba w rezerwacji",
    optionRefundReasonCOVID19Person3: "Osoba w moim gospodarstwie domowym",
    optionRefundReasonCOVID19Person4: "Inny",
    optionRefundReasonCOVID19TestType1: "PCR Test",
    optionRefundReasonCOVID19TestType2: "RAT Test",
    optionRefundReasonCOVID19TestType3: "Test przepływu poprzecznego",
    optionRefundReasonCOVID19TestType4: "Samodzielny test",
    optionRefundReasonCOVID19TestType5: "Brak testu, ale mam objawy",
    optionRefundReasonCOVID19TestType6: "Inny",
    optionRefundReasonBreakdown1: "Awaria",
    optionRefundReasonBreakdown2: "Wypadek",
    optionRefundReasonBreakdown3: "Pożar",
    optionRefundReasonBreakdown4: "Kradzież",
    optionRefundReasonPublicTransportType1: "Pociąg",
    optionRefundReasonPublicTransportType2: "Autobus",
    optionRefundReasonPublicTransportType3: "Tramwaj",
    optionRefundReasonPublicTransportType4: "Łódź",
    optionRefundReasonDocumentType1: "Paszport",
    optionRefundReasonDocumentType2: "Zezwolenie na podróż",
    optionRefundReasonDocumentType3: "Dowód osobisty",
    optionRefundReasonDocumentType4: "Bilety",
    optionRefundReasonWorkRelocationType1: "Tymczasowy",
    optionRefundReasonWorkRelocationType2: "Stały",
    optionRefundReasonWorkRelocationTemporary1: "0-3 miesiące",
    optionRefundReasonWorkRelocationTemporary2: "3-6 miesięcy",
    optionRefundReasonWorkRelocationTemporary3: "Ponad 6 miesięcy",
    optionRefundReasonBookingRelationshipType1: "Ktoś w rezerwacji",
    optionRefundReasonBookingRelationshipType2: "Bezpośredni krewny osoby w rezerwacji",
    optionEmploymentType1: "Pełny etat",
    optionEmploymentType2: "W niepełnym wymiarze godzin",
    optionEmploymentType3: "Kontrakt",
    optionEmploymentLengthType1: "0-1 lat",
    optionEmploymentLengthType2: "1-2 lata",
    optionEmploymentLengthType3: "Ponad 2 lata",
    textRefundEvidenceHeading: "Proszę podać następujące dowody:",
    textRefundEvidence1: "Potwierdzenie linii lotniczych",
    textRefundEvidence2: "Wszelkie odpowiednie dowody potwierdzające",
    textRefundEvidence3: "Potwierdzenie wezwania pomocy drogowej",
    textRefundEvidence4: "Karta zgonu",
    textRefundEvidence5: "Potwierdzenie anulowania rezerwacji od linii lotniczej",
    textRefundEvidence6: "Prognoza pogody",
    textRefundEvidence7: "Korespondencja od agenta rezerwacji potwierdzająca brak możliwości ponownego wystawienia biletów",
    textRefundEvidence8: "Pismo sądu",
    textRefundEvidence9: "Zaświadczenie lekarskie / Notatka lekarska",
    textRefundEvidenceDescription9: "Potwierdzenie szczegółów choroby lub urazu, daty jej wystąpienia, że uniemożliwia to uczestnictwo w rezerwacji.",
    textRefundEvidence10: "Potwierdzenie listu pracodawcy",
    textRefundEvidence11: "Korespondencja z roszczeniami ubezpieczeniowymi",
    textRefundEvidence12: "List od Pracodawcy",
    textRefundEvidence13: "Potwierdzenie strony internetowej rządu krajowego",
    textRefundEvidence14: "Powiadomienie o zmianie egzaminu",
    textRefundEvidence15: "Raport policyjny",
    textRefundEvidence16: "Raport policyjny i numer referencyjny",
    textRefundEvidence17: "Potwierdzenie dostawcy usług transportowych",
    textRefundEvidence18: "Ostrzeżenie dotyczące podróży",
    textRefundEvidence20: "Certyfikat testu COVID-19",
    textRefundEvidence24: "Rządowe zawiadomienie o izolacji",
    textRefundEvidence25: "List od dowódcy",
    textRefundEvidence26: "List od ubezpieczycieli",
    textRefundEvidence27: "Potwierdzenie adresu",
    textRefundEvidence28: "Dowód pokrewieństwa ze zmarłym",
    textRefundEvidence29: "Dowód pokrewieństwa z osobą poszkodowaną/chorą",
    textRefundEvidence30: "Odcinek wypłaty",
    tooltipBookingReference: "Aby zidentyfikować numer rezerwacji, sprawdź potwierdzenie rezerwacji i przejrzyj sekcję dotyczącą naszej rozszerzonej usługi.",
    tooltipCustomerName: "Imię i nazwisko użyte do dokonania rezerwacji.",
    tooltipTypeOfBooking: "Rodzaj rezerwacji, za którą chcesz otrzymać zwrot pieniędzy.",
    tooltipEventName: "Nazwa wydarzenia.",
    tooltipVenueName: "Nazwa miejsca.",
    tooltipFlightNumber: "Numer lotu.",
    tooltipOriginAirport: "Lotnisko wylotu.",
    tooltipHotelName: "Nazwa miejsca zakwaterowania.",
    tooltipHotelLocation: "Lokalizacja zakwaterowania.",
    tooltipCurrency: "Waluta, w której dokonano płatności za rezerwację.",
    tooltipBookingValue: "Całkowita cena zapłacona za rezerwację.",
    tooltipDateOfPurchase: "Data dokonania rezerwacji.",
    tooltipEmailAddress: "Użyj prawidłowego adresu e-mail. Używamy tego e-maila do korespondencji podczas składania wniosku o zwrot pieniędzy.",
    tooltipConfirmEmailAddress: "Użyj tego samego adresu e-mail, co powyżej, do weryfikacji.",
    tooltipHomeCountry: "Pomoże nam to podać prawidłowe informacje bankowe na ostatnim etapie.",
    tooltipContactNumberCountryCode: "Numer kierunkowy kraju numeru kontaktowego.",
    tooltipContactNumber: "Użyj prawidłowego numeru. Używamy tego do korespondencji podczas składania wniosku o zwrot pieniędzy.",
    tooltipAddressLine1: "Pamiętaj, że Twój adres zostanie użyty podczas weryfikacji konta bankowego.",
    tooltipAddressLine2: "Druga linia adresu.",
    tooltipCity: "Twoje miasto rodzinne, dane mogą posłużyć do weryfikacji danych bankowych.",
    tooltipZipCode: "Kod pocztowy / kod pocztowy.",
    tooltipRegion: "Region Twojego miasta rodzinnego, dane mogą posłużyć do weryfikacji danych bankowych.",
    tooltipNumberOfTicketsToRefund: "Liczba biletów, za które chcesz otrzymać zwrot pieniędzy.",
    tooltipTotalRefundAmount: "Nie może to być więcej niż całkowita wartość rezerwacji, jeśli tak, nie będziesz mógł złożyć wniosku.",
    tooltipBookingConfirmationFiles: "Zrzut ekranu / załącznik pełnego potwierdzenia rezerwacji, które zwykle można znaleźć w wiadomościach e-mail.",
    tooltipRefundReasonCategory: "Wybierz powód zwrotu.",
    tooltipDateOfIncident: "Data zdarzenia, które miało wpływ na Twoją rezerwację.",
    tooltipRefundExplanation: "Podaj jak najwięcej szczegółów na poparcie wniosku o zwrot pieniędzy.",
    tooltipUnableToNotifyWithinTimeLimit: "Nie można powiadomić w ciągu 60 dni.",
    tooltipReasonForNotificationOutsideTimeLimit: "Objaśnienie terminu powiadomienia poza terminem.",
    tooltipRefundReasonFiles: "Prześlij dowolny z wymaganych dokumentów.",
    tooltipEventTypeDate: "Data faktycznego wydarzenia / rezerwacji.",
    tooltipEventTypeTour: "Data faktycznej wycieczki.",
    tooltipEventTypeFlight: "Data faktycznego lotu.",
    tooltipEventTypeCheckIn: "Data faktycznego zameldowania.",
    tooltipEventTypeTransfer: "Data faktycznego przelewu.",
    tooltipEventTypeParking: "Data faktycznego parkowania.",
    tooltipEventTypeOtherTravel: "Data rozpoczęcia faktycznej podróży."
};