import * as React from "react";
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {IFormValues} from '../Interfaces/IFormValues';
import {FormikProps} from 'formik';
import {useFormContext} from '../providers/FormProvider';
import {nameof} from 'ts-simple-nameof';
import {useTranslation} from 'react-i18next';
import {AccountCaptureFieldsConfirmControl} from '../controls/AccountCaptureFieldsConfirmControl';
import {TextInput} from '../formInputs';
import usePaymentLabels from '../../hooks/usePaymentLabels';
import {useRecoilValue} from 'recoil';
import {BankNameState} from '../../state/activity';
import {CloseButton} from '../buttons/CloseButton';
import {ConfirmButton} from '../buttons/ConfirmButton';
import {ModalHolder} from './ModalHolder';

interface BankDetailsConfirmationModalProps {
    formProps: FormikProps<IFormValues>;
}

export const BankDetailsConfirmationModal = (props: BankDetailsConfirmationModalProps) => {

    const {
        formProps
    } = props;

    const {
        handleConfirm,
        paymentResponseFields,
        setShowBankModal,
        showBankModal
    } = useFormContext();

    const {t} = useTranslation();
    const paymentLabels = usePaymentLabels(formProps.values.currency);

    const bankName = useRecoilValue(BankNameState);

    const closeModal = () => {
        setShowBankModal(false);
    }

    const confirmHandler = () => {
        handleConfirm(formProps);
    }

    const getField = (code: string) => paymentResponseFields.find(x => x.code === code);
    const isFieldHidden = (code: string): boolean => getField(code) === undefined;

    const Content = () => {
        return (<>
                <Box pb={1}>

                    <Box pb={2}>
                        <b>{t('payment:modalLabelPleaseConfirmYourDetails')}</b>
                    </Box>

                    <Box pb={2} hidden={isFieldHidden(nameof<IFormValues>(x => x.bankNameSelect))}>
                        <Grid container alignItems="center" columns={{xs: 1, sm: 12, md: 12}}>
                            <Grid size={{xs: 6, md: 5}}>
                                <Box sx={{pr: 2}} justifyContent="flex-end" display={{sm: 'flex', md: 'flex'}}>
                                    {t('payment:labelBankName')}
                                </Box>
                            </Grid>
                            <Grid size={{xs: 6, md: 4}}>
                                {bankName}
                            </Grid>
                        </Grid>
                    </Box>

                    <AccountCaptureFieldsConfirmControl
                        formProps={formProps}
                        isFieldHidden={isFieldHidden}
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.bankSwiftBicConfirm)}
                        placeholder={paymentLabels.labelBeneficiarySWIFTBIC}
                        label={paymentLabels.labelBeneficiarySWIFTBIC}
                        controlId={nameof<IFormValues>(x => x.bankSwiftBicConfirm)}
                        hidden={isFieldHidden(nameof<IFormValues>(x => x.bankSwiftBic))}
                        tooltip={paymentLabels.tooltipBeneficiarySWIFTBIC}
                    />

                    <Box hidden={isFieldHidden(nameof<IFormValues>(x => x.bankSwiftBicSelect))}>
                        <Grid container alignItems="center" columns={{xs: 1, sm: 12, md: 12}}>
                            <Grid size={{xs: 6, md: 5}}>
                                <Box sx={{pr: 2}} justifyContent="flex-end" display={{sm: 'flex', md: 'flex'}}>
                                    {t('payment:labelBeneficiarySWIFTBIC')}
                                </Box>
                            </Grid>
                            <Grid size={{xs: 6, md: 4}}>
                                {formProps.values.bankSwiftBicSelect}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box justifyContent='flex-end' display='flex'>
                    <CloseButton
                        buttonText={t('common:buttonCancel')}
                        handleOnClick={closeModal}
                    />

                    <ConfirmButton
                        buttonText={t('common:buttonConfirm')}
                        handleOnClick={confirmHandler}
                        isSubmitButton
                    />
                </Box>
            </>
        );
    }

    return (
        <ModalHolder
            showModal={showBankModal}
            content={<Content/>}
            handleClose={closeModal}
            width='md'
        />
    );
}