import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { IFormValues } from '../Interfaces/IFormValues';
import {
    OptionArmedForcesOther,
    OptionCourtSummonsOther,
    OptionCOVID19PositiveTestAnotherPerson,
    OptionCOVID19PositiveTestHouseholdPerson,
    OptionCOVID19PositiveTestOther,
    OptionCOVID19PositiveTestTypeOther,
    OptionDeathOther,
    OptionHomeEmergencyOther,
    OptionImmediateRelativeOfSomeoneInTheBooking,
    OptionWorkRelocationTemporary,
    OptionYes,
    RefundReasonAdverseWeather,
    RefundReasonArmedForcesAndEmergencyServicesRecall,
    RefundReasonChangesToExaminationDates,
    RefundReasonCourtSummons,
    RefundReasonCOVID19PositiveTest,
    RefundReasonDeath,
    RefundReasonEmergencyCircumstances,
    RefundReasonHomeEmergency,
    RefundReasonIllnessInjury,
    RefundReasonJuryService,
    RefundReasonMechanicalBreakdown,
    RefundReasonPregnancyComplication,
    RefundReasonPublicTransportFailure,
    RefundReasonWorkplaceRedundancy,
    RefundReasonScheduledAirlineFailure,
    RefundReasonTheftOfDocuments,
    RefundReasonWorkRelocation,
    refundBlanketDeclineReasons
} from '../utils/constants';
import {useFormContext} from '../providers/FormProvider';

const disallowedEmailDomains = (value: string) => /t-online.de/.test(value);
const emailRegex = (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);

export const formSchemaMain = (t: TFunction,
                           isOffline: boolean,
                           hasPreviousBookingFiles: boolean,
                           hasPreviousRefundReasonFiles: boolean,
                           hasPreviousRefundReasonFilesAdditional: boolean,
                           ignoreLatinValidation: boolean,
                           countryCurrencyValue: string,
                           bookingConfirmationRequired: boolean,
                           evidenceRequired: boolean,
                           paymentTypeSelectHidden: boolean,
                           bankNameSelectHidden: boolean,
                           branchCodeRegEx: string, branchCodeHidden: boolean, branchCodeSelectHidden: boolean,
                           bankAccountNumberHidden: boolean,  bankAccountNumberRegEx: string, bankAccountTypeHidden: boolean,
                           cardNumberHidden: boolean,
                           sortCodeRegEx: string, sortCodeHidden: boolean,
                           swiftCodeRegEx: string, swiftCodeHidden: boolean, swiftBicSelectHidden: boolean,
                           regionValidation: boolean,
                           remittanceLine3SelectHidden: boolean, remittanceLine3Hidden: boolean, remittanceLine3RegEx: string,
                           remittanceLine4SelectHidden: boolean, remittanceLine4Hidden: boolean, remittanceLine4RegEx: string,
                           dateOfBirthHidden: boolean, isExtended?: boolean): any => Yup.object<IFormValues>().shape({
    bookingReference: Yup.string().trim()
        .test('emailValidate', t('form:validationEmailAsBookingReference'), (value) => !emailRegex(value))
        .required(t('form:validationBookingReference')),
    customerName: Yup.string().required(t('form:validationCustomerName')),
    emailAddress: Yup.string().trim()
        .test('emailDisallow', t('form:validationEmailDomain'), (value) => !disallowedEmailDomains(value))
        .email(t('form:validationEmailAddressFormat'))
        .required(t('form:validationEmailAddress')),
    confirmEmailAddress: Yup.string().trim()
        .test('emailDisallow', t('form:validationEmailDomain'), (value) => !disallowedEmailDomains(value))
        .email(t('form:validationConfirmEmailAddressFormat'))
        .required(t('form:validationConfirmEmailAddress'))
        .equals([Yup.ref('emailAddress')], t('form:validationConfirmEmailAddressMatch')),
    contactNumberCountryCode: Yup.string().required(t('form:validationContactNumberCountryCode')).notOneOf([''], t('form:validationContactNumberCountryCode')),
    contactNumber: Yup.number().required(t('form:validationContactNumber')),
    typeOfBooking: Yup.number().required(t('form:validationTypeOfBooking')).moreThan(0, t('form:validationTypeOfBooking')),
    bookingConfirmationFiles: Yup.array().concat(!hasPreviousBookingFiles && bookingConfirmationRequired ?
        Yup.array().min(1, t('form:validationRefundReasonFiles'))
        : null),
    dateOfPurchase: Yup.string().required(t('form:validationDateOfPurchase')).nullable(),
    dateOfEvent: Yup.string().required(t('form:validationDateOfEvent')).nullable(),
    currency: Yup.string().concat(!countryCurrencyValue ? Yup.string().required(t('form:validationCurrency')).notOneOf([''], t('form:validationCurrency')) : Yup.string().equals([Yup.ref(countryCurrencyValue)], t('form:validationCountryCurrency'))),
    homeCountry: Yup.string().required(t('form:validationHomeCountry')).notOneOf([''], t('form:validationHomeCountry')),
    bookingValue: Yup.number().required(t('form:validationBookingValue')).min(0.01, t('form:validationBookingValueAmount')),

    totalRefundAmount: Yup.number()
        .when('adjustmentAmount',
            { is: (adjustmentAmount) => adjustmentAmount === 0
                ,then: Yup.number().required(t('form:validationTotalRefundAmount')).min(0.01, t('form:validationTotalRefundAmountValue'))
                    .max(Yup.ref('bookingValue'), t('form:validationTotalRefundGreaterThanBookingValue')) }),

    refundReasonCategory: Yup.number().moreThan(0, t('form:validationRefundReasonCategory')),

    refundReasonConfirm: Yup.number()
        .when('refundReasonCategory',
            { is: (refundReasonCategory) =>
                    (refundReasonCategory > 0 ||
                        (refundReasonCategory === RefundReasonCOVID19PositiveTest && isExtended !== false))
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonBespokeText1: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonAdverseWeather ||
                        refundReasonCategory === RefundReasonIllnessInjury ||
                        refundReasonCategory === RefundReasonPregnancyComplication ||
                        refundReasonCategory === RefundReasonScheduledAirlineFailure ||
                        refundReasonCategory === RefundReasonEmergencyCircumstances) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeSelect1: Yup.number()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonArmedForcesAndEmergencyServicesRecall ||
                        refundReasonCategory === RefundReasonCourtSummons ||
                        refundReasonCategory === RefundReasonDeath ||
                        refundReasonCategory === RefundReasonHomeEmergency ||
                        (refundReasonCategory === RefundReasonCOVID19PositiveTest && isExtended !== false) ||
                        refundReasonCategory === RefundReasonMechanicalBreakdown ||
                        refundReasonCategory === RefundReasonPublicTransportFailure ||
                        refundReasonCategory === RefundReasonTheftOfDocuments ||
                        refundReasonCategory === RefundReasonWorkRelocation ||
                        refundReasonCategory === RefundReasonWorkplaceRedundancy) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonBespokeSelectText1: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm', 'refundReasonBespokeSelect1'],
            {is: (refundReasonCategory, refundReasonConfirm, refundReasonBespokeSelect1) =>
                    ((refundReasonCategory === RefundReasonCOVID19PositiveTest && refundReasonConfirm === OptionYes &&
                            (refundReasonBespokeSelect1 === OptionCOVID19PositiveTestAnotherPerson
                                || refundReasonBespokeSelect1 === OptionCOVID19PositiveTestHouseholdPerson
                                || refundReasonBespokeSelect1 === OptionCOVID19PositiveTestOther))
                        || (refundReasonCategory === RefundReasonDeath && refundReasonConfirm === OptionYes
                            && refundReasonBespokeSelect1 === OptionDeathOther)
                        || (refundReasonCategory === RefundReasonArmedForcesAndEmergencyServicesRecall && refundReasonConfirm === OptionYes &&
                            refundReasonBespokeSelect1 === OptionArmedForcesOther)
                        || (refundReasonCategory === RefundReasonCourtSummons && refundReasonConfirm === OptionYes &&
                            refundReasonBespokeSelect1 === OptionCourtSummonsOther)
                        || (refundReasonCategory === RefundReasonHomeEmergency && refundReasonConfirm === OptionYes &&
                            refundReasonBespokeSelect1 === OptionHomeEmergencyOther))
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeDate1: Yup.date()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    refundReasonCategory === RefundReasonChangesToExaminationDates &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.date().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeText2: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    refundReasonCategory === RefundReasonScheduledAirlineFailure &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeSelect2: Yup.number()
        .when(['refundReasonCategory', 'refundReasonConfirm', 'refundReasonBespokeSelect1'],
            {is: (refundReasonCategory, refundReasonConfirm, refundReasonBespokeSelect1) =>
                    (((refundReasonCategory === RefundReasonCOVID19PositiveTest && isExtended !== false) &&
                            refundReasonConfirm === OptionYes) ||
                        (refundReasonCategory === RefundReasonWorkRelocation &&
                            refundReasonConfirm === OptionYes &&
                            refundReasonBespokeSelect1 === OptionWorkRelocationTemporary) ||
                        (refundReasonCategory === RefundReasonWorkplaceRedundancy &&
                            refundReasonConfirm === OptionYes ))
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonBespokeSelectText2: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm', 'refundReasonBespokeSelect2'],
            {is: (refundReasonCategory, refundReasonConfirm, refundReasonBespokeSelect2) =>
                    refundReasonCategory === RefundReasonCOVID19PositiveTest &&
                    refundReasonConfirm === OptionYes &&
                    refundReasonBespokeSelect2 === OptionCOVID19PositiveTestTypeOther
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeDate2: Yup.date()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonAdverseWeather ||
                        refundReasonCategory === RefundReasonArmedForcesAndEmergencyServicesRecall ||
                        refundReasonCategory === RefundReasonChangesToExaminationDates ||
                        refundReasonCategory === RefundReasonCourtSummons ||
                        refundReasonCategory === RefundReasonDeath ||
                        refundReasonCategory === RefundReasonHomeEmergency ||
                        refundReasonCategory === RefundReasonIllnessInjury ||
                        refundReasonCategory === RefundReasonMechanicalBreakdown ||
                        refundReasonCategory === RefundReasonPregnancyComplication ||
                        refundReasonCategory === RefundReasonPublicTransportFailure ||
                        refundReasonCategory === RefundReasonTheftOfDocuments ||
                        refundReasonCategory === RefundReasonEmergencyCircumstances) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.date().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeText3: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonChangesToExaminationDates ||
                        refundReasonCategory === RefundReasonDeath ||
                        refundReasonCategory === RefundReasonHomeEmergency ||
                        refundReasonCategory === RefundReasonMechanicalBreakdown ||
                        refundReasonCategory === RefundReasonPublicTransportFailure ||
                        refundReasonCategory === RefundReasonScheduledAirlineFailure ||
                        refundReasonCategory === RefundReasonWorkRelocation ||
                        refundReasonCategory === RefundReasonEmergencyCircumstances) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeSelect3: Yup.number()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonIllnessInjury ||
                        refundReasonCategory === RefundReasonPregnancyComplication ||
                        refundReasonCategory === RefundReasonTheftOfDocuments ||
                        refundReasonCategory === RefundReasonWorkplaceRedundancy) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonBespokeDate3: Yup.date()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonCOVID19PositiveTest && isExtended !== false) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.date().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeDate4: Yup.date()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonWorkplaceRedundancy) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.date().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeText4: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonPregnancyComplication ||
                        refundReasonCategory === RefundReasonPublicTransportFailure ||
                        refundReasonCategory === RefundReasonScheduledAirlineFailure ||
                        refundReasonCategory === RefundReasonWorkRelocation) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeSelect4: Yup.number()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonChangesToExaminationDates ||
                        refundReasonCategory === RefundReasonIllnessInjury ||
                        refundReasonCategory === RefundReasonEmergencyCircumstances) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonBespokeDate5: Yup.date()
        .when(['refundReasonCategory', 'refundReasonConfirm', 'refundReasonBespokeSelect4'],
            {is: (refundReasonCategory, refundReasonConfirm, refundReasonBespokeSelect4) =>
                    (refundReasonCategory === RefundReasonWorkRelocation &&
                        refundReasonConfirm === OptionYes) ||
                    (refundReasonCategory === RefundReasonIllnessInjury &&
                        refundReasonConfirm === OptionYes && refundReasonBespokeSelect4 === OptionYes) ||
                    (refundReasonCategory === RefundReasonScheduledAirlineFailure &&
                        refundReasonConfirm === OptionYes)
                ,then: Yup.date().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeText6: Yup.string()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    (refundReasonCategory === RefundReasonIllnessInjury &&
                        refundReasonConfirm === OptionYes) ||
                    (refundReasonCategory === RefundReasonScheduledAirlineFailure &&
                        refundReasonConfirm === OptionYes)
                ,then: Yup.string().required(t('common:validationRequired')) })
        .nullable(),

    refundReasonBespokeSelect7: Yup.number()
        .when(['refundReasonCategory', 'refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    refundReasonCategory === RefundReasonIllnessInjury &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.number().moreThan(0, t('common:validationRequired')) }),

    refundReasonFiles: Yup.array()
        .when(['refundReasonCategory','refundReasonConfirm'],
            {is: (refundReasonCategory, refundReasonConfirm) =>
                    ((refundReasonCategory === RefundReasonCOVID19PositiveTest && isExtended !== false) ||
                        refundReasonCategory === RefundReasonAdverseWeather ||
                        refundReasonCategory === RefundReasonArmedForcesAndEmergencyServicesRecall ||
                        refundReasonCategory === RefundReasonChangesToExaminationDates ||
                        refundReasonCategory === RefundReasonCourtSummons ||
                        refundReasonCategory === RefundReasonDeath ||
                        refundReasonCategory === RefundReasonEmergencyCircumstances ||
                        refundReasonCategory === RefundReasonHomeEmergency ||
                        refundReasonCategory === RefundReasonIllnessInjury ||
                        refundReasonCategory === RefundReasonJuryService ||
                        refundReasonCategory === RefundReasonMechanicalBreakdown ||
                        refundReasonCategory === RefundReasonPregnancyComplication ||
                        refundReasonCategory === RefundReasonPublicTransportFailure ||
                        refundReasonCategory === RefundReasonScheduledAirlineFailure ||
                        refundReasonCategory === RefundReasonTheftOfDocuments ||
                        refundReasonCategory === RefundReasonWorkRelocation ||
                        refundReasonCategory === RefundReasonWorkplaceRedundancy) &&
                    refundReasonConfirm === OptionYes
                ,then: Yup.array().concat(!hasPreviousRefundReasonFiles && evidenceRequired ?
                    Yup.array().min(1, t('form:validationRefundReasonFiles')) :
                    null) }),

    refundReasonFilesSecondary: Yup.array()
        .when(['refundReasonCategory','refundReasonConfirm', 'refundReasonBespokeSelect7'],
            {is: (refundReasonCategory, refundReasonConfirm, refundReasonBespokeSelect7) =>
                    ((refundReasonCategory === RefundReasonAdverseWeather
                    || refundReasonCategory === RefundReasonDeath)
                        && refundReasonConfirm === OptionYes)
                    || (refundReasonCategory === RefundReasonIllnessInjury &&
                        refundReasonBespokeSelect7 === OptionImmediateRelativeOfSomeoneInTheBooking &&
                        refundReasonConfirm === OptionYes)
                ,then: Yup.array().concat(!hasPreviousRefundReasonFilesAdditional && evidenceRequired ?
                    Yup.array().min(1, t('form:validationRefundReasonFiles')) :
                    null) }),

    additionalComments: Yup.string().nullable()
        .when('refundReasonCategory',
            { is: (refundReasonCategory) =>
                    refundBlanketDeclineReasons.map(x => x.id).includes(refundReasonCategory)
                ,then: Yup.string().nullable().required(t('form:validationAdditionalComments')) }),

    reasonForNotificationOutsideTimeLimit: Yup.string()
        .when('refundReasonConfirm',
            {is: (refundReasonConfirm) =>
                    refundReasonConfirm === OptionYes
                ,then: Yup.string().when('unableToNotifyWithinTimeLimit', { is: true, then: Yup.string().required(t('form:validationReasonForNotificationOutsideTimeLimit')) }) }),

    beneficiaryAddress1: Yup.string().concat(!isOffline && !ignoreLatinValidation ? Yup.string().required(t('common:validationRequired')).matches(/[A-Za-z0-9\\p{InBasic_Latin}]|[ -~゠-ヿ　-〿¥￥！-ﾟ゙-゜]/, t('form:validationTextScript')) : null),
    beneficiaryAddress2: Yup.string().concat(!isOffline && !ignoreLatinValidation ? Yup.string().matches(/[A-Za-z0-9\\p{InBasic_Latin}]|[ -~゠-ヿ　-〿¥￥！-ﾟ゙-゜]/, t('form:validationTextScript')) : null),
    beneficiaryCity: Yup.string().concat(!isOffline && !ignoreLatinValidation ? Yup.string().required(t('common:validationRequired')).matches(/[A-Za-z0-9\\p{InBasic_Latin}]|[ -~゠-ヿ　-〿¥￥！-ﾟ゙-゜]/, t('form:validationTextScript')) : null),
    beneficiaryPostCode: Yup.string().concat(!isOffline && !ignoreLatinValidation ? Yup.string().required(t('common:validationRequired')).matches(/[A-Za-z0-9\\p{InBasic_Latin}]|[ -~゠-ヿ　-〿¥￥！-ﾟ゙-゜]/, t('form:validationTextScript')) : null),
    beneficiaryRegion: Yup.string().concat(!isOffline && regionValidation ? Yup.string().required(t('form:validationRegion')).notOneOf([''], t('form:validationRegion')) : null),
    bankAccountName: Yup.string().concat(!isOffline && !ignoreLatinValidation ? Yup.string().required(t('payment:validationBankAccountName')).matches(/[A-Za-z0-9\\p{InBasic_Latin}]|[ -~゠-ヿ　-〿¥￥！-ﾟ゙-゜]/, t('form:validationTextScript')) : null),
    paymentType: Yup.string().concat(!isOffline && !paymentTypeSelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    bankNameSelect: Yup.string().concat(!isOffline && !bankNameSelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    branchCodeSelect: Yup.string().concat(!isOffline && !branchCodeSelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    branchCode: Yup.string().concat(!isOffline && !branchCodeHidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(branchCodeRegEx), (t('common:validationInvalid'))) : null),
    bankAccountNumber: Yup.string().concat(!isOffline && !bankAccountNumberHidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(bankAccountNumberRegEx), (t('common:validationInvalid'))) : null),
    cardNumber: Yup.string().concat(!isOffline && !cardNumberHidden ? Yup.string().required(t('common:validationRequired')) : null),
    bankAccountType: Yup.string().concat(!isOffline && !bankAccountTypeHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    sortCode: Yup.string().concat(!isOffline && !sortCodeHidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(sortCodeRegEx), (t('common:validationInvalid'))) : null),
    bankSwiftBic: Yup.string().concat(!isOffline && !swiftCodeHidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(swiftCodeRegEx), (t('common:validationInvalid'))) : null),
    bankSwiftBicSelect: Yup.string().concat(!isOffline && !swiftBicSelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    remittanceLine3Select: Yup.string().concat(!isOffline && !remittanceLine3SelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    remittanceLine3: Yup.string().concat(!isOffline && !remittanceLine3Hidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(remittanceLine3RegEx), (t('common:validationInvalid'))) : null),
    remittanceLine4Select: Yup.string().concat(!isOffline && !remittanceLine4SelectHidden ? Yup.string().required(t('common:validationRequired')).notOneOf([''], t('common:validationRequired')) : null),
    remittanceLine4: Yup.string().concat(!isOffline && !remittanceLine4Hidden ? Yup.string().required(t('common:validationRequired')).matches(new RegExp(remittanceLine4RegEx), (t('common:validationInvalid'))) : null),
    dateOfBirth: Yup.string().concat(!isOffline && !dateOfBirthHidden ? Yup.string().required(t('common:validationRequired')) : null),
    hasAgreedTermsAndConditions: Yup.boolean().oneOf([true], t('payment:validationTermsAndConditions'))
}).defined();