import {atom} from 'recoil';

export const AutoSaveMinutesState = atom({
    key: 'autosaveminutesstate',
    default: 0
});

export const BankNameState = atom({
    key: 'banknamestate',
    default: ''
});

export const BookingConfirmationFilesState = atom<File[]>({
    key: 'bookingconfirmationfilesstate',
    default: [] as File[]
});

export const BookingConfirmationReminderState = atom({
    key: 'bookingconfirmationreminderstate',
    default: false
});

export const CurrencyCountryInvalidState = atom({
    key: 'currencycountryinvalidstate',
    default: false
});

export const EvidenceReminderState = atom({
    key: 'evidencereminderstate',
    default: false
});

export const EvidenceResubmissionState = atom({
    key: 'evidenceresubmissionstate',
    default: false
});

export const LanguageSelectorModalState = atom({
    key: 'languageselectormodalstate',
    default: true
});

export const loadingShimState = atom({
    key: 'loadingShimState',
    default: {
        open: false,
        message: ''
    }
});

export const LiveChatEnabledState = atom({
    key: 'livechatenabledstate',
    default: true
});

export const MemberTermsSubDirectory = atom({
    key: 'membertermssubdirectory',
    default: ''
});

export const MsClarityInjectionState = atom({
    key: 'msclarityinjectionstate',
    default: false
});

export const RefundAmountConfirmationModalCounterState = atom({
    key: 'refundamountconfirmationmodalcounterstate',
    default: 0
});

export const RefundReasonFilesState = atom<File[]>({
    key: 'refundreasonfilesstate',
    default: [] as File[]
});

export const RefundReasonFilesSecondaryState = atom<File[]>({
    key: 'refundreasonfilessecondarystate',
    default: [] as File[]
});

export const SavedFormState = atom({
   key: 'savedformstate',
   default: false
});

export const ShowReviewSectionState = atom({
    key: 'showreviewsectionstate',
    default: false
});