export default {
    labelSavedApplicationReference: "Saglabātā lietojumprogrammas atsauce:",
    labelLanguage: "Valoda",
    messageWarningBookingHeading1: "Rezervācija atcelta:",
    messageWarningBookingHeading2: "Rezervācija atlikta / pārcelta:",
    messageWarningBookingBody1: "Ja organizators vai aviosabiedrība ir atcēlusi jūsu rezervāciju, šī komanda nevarēs jums palīdzēt atmaksāt naudu. Jums būs tieši jāsazinās ar savu klientu apkalpošanas komandu; Skatiet savu rezervācijas apstiprinājumu vai tīmekļa vietni, lai iegūtu kontaktinformāciju.",
    messageWarningBookingBody2: "Ja jūsu rezervācija ir atlikta, pagarinātie atmaksājamie noteikumi tiks pārnesti uz jauno datumu. Ja jums ir pamatots iemesls atmaksai saskaņā ar mūsu noteikumiem, lūdzu, aizpildiet veidlapu ar pareiziem apstiprinošiem pierādījumiem.",
    messageWarningBookingBody3: "Lūdzu, ņemiet vērā: šis pakalpojums var nodrošināt atmaksu tikai to iemeslu dēļ, kas iekļauti mūsu formulējumā, kas pieejams jūsu rezervācijas apstiprinājuma e-pastā.",
    messageWarningBookingBody4: "Ja jūs nevarat veikt rezervāciju tādu iemeslu dēļ, kas neatbilst mūsu noteikumiem, mēs nevarēsim palīdzēt. Jums būs tieši jāsazinās ar savu klientu apkalpošanas komandu, jo viņi var palīdzēt; Skatiet savu rezervācijas apstiprinājumu vai tīmekļa vietni, lai iegūtu kontaktinformāciju.",
    linkWarningBooking: "šeit",
    formSectionNavigatorStep1: "1. solis",
    formSectionNavigatorStep2: "2. solis",
    formSectionNavigatorStep3: "3. solis",
    formSectionNavigatorStep1Text: "Kontaktinformācija un rezervācijas informācija",
    formSectionNavigatorStep2Text: "Atmaksas iemesls",
    formSectionNavigatorStep3Text: "Maksājuma informācija",
    messageBookingMatchedAndFound: "Ja jūsu rezervācijas atsauce ir saistīta ar esošu pieteikumu, mēs nosūtīsim jums e-pastu ar saiti, lai piekļūtu jūsu pieteikumam",
    messageBookingMatchedAndFoundCompleted: "Jūsu atmaksas pieteikums jau ir apstiprināts! Ja jums ir kādas problēmas ar maksājumu, lūdzu, sazinieties ar:",
    applicationDeclinedMessage: "Jūsu pieteikums ir noraidīts un tagad ir slēgts.",
    messageBookingNotFound1: "Mēs nevaram atrast rezervāciju ar šādu atsauci. Lūdzu, pārbaudiet rezervācijas apstiprinājuma e-pastu un ievadiet to atkārtoti.",
    messageBookingNotFound2: "Neuztraucieties, ja jūsu rezervācijas atsauce nedarbojas, jūs varat izvēlēties Atrast rezervāciju un ievadīt savu informāciju. Aizpildot veidlapu, lūdzu, augšupielādējiet rezervācijas apstiprinājuma e-pasta ziņojuma kopiju, lai palīdzētu mums atrast jūsu rezervējuma informāciju.",
    messageLocatingBooking: "Rezervācijas atrašana...",
    messageLoadingBank: "Bankas informācijas ielāde...",
    messageFailCurrencyLookup: "Kļūda, izgūstot valūtu",
    messageSavingApplication: "Pieteikuma saglabāšana...",
    messageSavedApplication: "Atmaksas pieteikums ir veiksmīgi saglabāts",
    messageFailSavedApplication: "Atmaksas pieteikumu neizdevās saglabāt",
    messageSendingEmailReminder: "Apstrādes...",
    messageErrorLocationDetails: "Kļūda, izgūstot detalizētu informāciju par atrašanās vietu",
    messageFailFormSubmission: "Veidlapu iesniegšana neizdevās",
    messageApplicationCannotBeFound: "Pieteikumu nevarēja atrast",
    messageApplicationRetrievalError: "Kļūda, izgūstot lietojumprogrammu",
    messageSubmittingApplication: "Pieteikuma iesniegšana...",
    messageEventDateLimitError: "Paziņošanas iemesls ārpus termiņa",
    messageAllTicketsAssigned: "Visas biļetes ir piešķirtas citam atmaksas pieteikumam.",
    selectPrefix: "Atlasiet",
    selectPostfix: "",
    fileUpload: "Noklikšķiniet šeit, lai augšupielādētu",
    fileUploadAcceptedFilesMessage: "Mēs pieņemam tikai .png, .jpeg, .heic, .webp PDF un attēlu formātus",
    fileUploadNoFiles: "0 Atlasītie faili",
    fileUploadPreviouslyUploaded: "Iepriekš augšupielādēts:",
    fileUploadSelectedUploaded: "Atlasītie jau ir augšupielādēti:",
    buttonNext: "Nākamo",
    buttonPrevious: "Iepriekšējo",
    buttonSave: "Glābt",
    buttonCancel: "Atcelt",
    buttonSubmit: "Iesniegt",
    buttonConfirm: "Apstiprināt",
    buttonYes: "Jā",
    buttonNo: "Nē",
    buttonClose: "Aizvērt",
    linkPrivacyStatement: "Konfidencialitāti",
    linkComplaintsPolicy: "Sūdzību politika",
    validationRequired: "Nepieciešams",
    validationInvalid: "Nederīgs",
    labelUploadPrefix: "Augšupielādēt",
    labelUploadPostfix: "",
    labelChange: "Mainīties",
    labelView: "Skats",
    browserNavigationMessage: "Nesaglabātās izmaiņas tiks zaudētas",
};