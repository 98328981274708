export default {
    messageQuestionService: "Perkhidmatan ini hanya untuk digunakan jika anda menghadapi masalah mengisi borang permohonan, kami tidak dapat menjawab pertanyaan lain di sini.",
    labelName: "Nama",
    labelReference: "Rujukan tempahan",
    labelPhone: "Nombor telefon",
    labelEmail: "E-mel",
    labelQuery: "Soalan anda",
    labelAskQuestion: "Hantarkan soalan di sini ...",
    buttonOpenModal: "Tanya soalan",
    title: "Tanya soalan",
    modalTitle: "4. Ada masalah teknikal dengan borang?",
    toastSuccess: "Kami telah menerima pertanyaan anda dan kami akan menghubungi anda dalam masa 24 jam",
    toastError: "Ada yang tidak kena dengan permintaan anda, sila cuba lagi",
    nameValidation:"Nama diperlukan",
    referenceValidation:"Rujukan diperlukan",
    questionValidation: "Soalan anda dikehendaki"
}