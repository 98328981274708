export default {
    labelSavedApplicationReference: "저장된 애플리케이션 참조 :",
    labelLanguage: " 언어",
    messageWarningBookingHeading1: "예약 취소:",
    messageWarningBookingHeading2: "예약 연기 / 변경:",
    messageWarningBookingBody1: "만약 고객님의 예약이 주최자나 항공사의 사정에 의해 취소된 경우, 저희가 환불을 도와 드리기 어렵습니다. 예약을 취소한 주최자/항공사의 고객 서비스 팀에 연락하셔서 도움을 요청하시기 바랍니다. 연락처는 예약 확인 이메일이나 웹사이트에서 확인 하실 수. 있습니다.",
    messageWarningBookingBody2: "만약 고객님의 예약이 연기되었다면, 저희가 제공하는 환불 조건은 새로운 날짜로 이전될 것입니다. 고객님이 우리가 제공하는 환불 조건에 적합한 환불요청을 가지고 있다면, 올바른 증빙 자료와 함께 양식을 작성해주시기 바랍니다.",
    messageWarningBookingBody3: "참고사항: 이 서비스는 예약 확인 이메일에 기재되어 있는 문구에 포함된 조건과 사유에 대해서만 환불을 제공할 수 있습니다.",
    messageWarningBookingBody4: "만약 우리가 제공하는 환불조건 외의 이유로 예약을 취소해야 한다면, 저희가 도움을 드리기 어려울 수 있습니다. 이럴 경우 해당 회사의 고객 서비스 팀에 직접 연락하셔서 도움을 받으시기 바랍니다. 연락처는 예약 확인 이메일이나 웹사이트에서 확인 하실 수 있습니다.",
    linkWarningBooking: "여기",
    formSectionNavigatorStep1: "1 단계",
    formSectionNavigatorStep2: "2 단계",
    formSectionNavigatorStep3: "3 단계",
    formSectionNavigatorStep1Text: "연락처 및 예약 세부정보",
    formSectionNavigatorStep2Text: "환불 사유",
    formSectionNavigatorStep3Text: "결제 세부정보",
    messageBookingMatchedAndFound: "귀하의 예약 번호가 기존 신청서와 연결되어 있는 경우 신청서에 액세스할 수 있는 링크가 포함된 이메일을 보내드립니다.",
    messageBookingMatchedAndFoundCompleted: "환불 신청이 이미 승인되었습니다. 결제에 문제가 있는 경우 다음으로 문의하십시오.",
    applicationDeclinedMessage: "신청서가 거부되어 현재 종료되었습니다.",
    messageBookingNotFound1: "해당 참조로 예약을 찾을 수 없습니다. 예약 확인 메일을 확인하신 후 다시 입력해 주세요.",
    messageBookingNotFound2: "예약 번호가 작동하지 않는 경우 걱정하지 마십시오. '예약 찾기'를 선택하고 세부 정보를 입력할 수 있습니다. 양식을 작성할 때 예약 세부 정보를 찾는 데 도움이 되도록 예약 확인 이메일 사본을 업로드하십시오.",
    messageLocatingBooking: "예약을 찾는 중 ... ",
    messageLoadingBank: "은행 정보로드 중 ... ",
    messageFailCurrencyLookup: "통화 검색 오류",
    messageSavingApplication: "애플리케이션 저장 중 ... ",
    messageSavedApplication: "환불 신청이 성공적으로 저장되었습니다.",
    messageFailSavedApplication: "환불 신청을 저장하지 못했습니다.",
    messageSendingEmailReminder: "처리 중...",
    messageErrorLocationDetails: "위치 세부 정보를 검색하는 중에 오류가 발생했습니다.",
    messageFailFormSubmission: "양식 제출 실패",
    messageApplicationCannotBeFound: "응용 프로그램을 찾을 수 없습니다",
    messageApplicationRetrievalError: "애플리케이션 검색 오류",
    messageSubmittingApplication: "신청서 제출 중 ...",
    messageEventDateLimitError: "기한 외 신고 사유",
    messageAllTicketsAssigned: "모든 티켓이 다른 환불 신청에 할당되었습니다.",
    selectPrefix: "선택",
    selectPostfix: "",
    fileUpload: "업로드하려면 여기를 클릭하세요",
    fileUploadAcceptedFilesMessage: ".png, .jpeg, .heic, .webp의 PDF 및 이미지 형식만 허용합니다.",
    fileUploadNoFiles: "0 개의 파일 선택",
    fileUploadPreviouslyUploaded: "이전에 업로드 완료:",
    fileUploadSelectedUploaded: "선택한 항목이 이미 업로드되었습니다.",
    buttonNext: "다음",
    buttonPrevious: " 이전단계로",
    buttonSave: " 저장",
    buttonCancel: "취소",
    buttonSubmit: "제출",
    buttonConfirm: "확인",
    buttonYes: "예",
    buttonNo: "아니오",
    buttonClose: "닫기",
    linkPrivacyStatement: "개인 정보 보호 정책",
    linkComplaintsPolicy: "불만사항 정책",
    validationRequired: "필수",
    validationInvalid: "유효하지 않음",
    labelUploadPrefix: "업로드",
    labelUploadPostfix: "",
    labelChange: "변경",
    labelView: "보기",
    browserNavigationMessage: "저장하지 않은 변경사항은 손실됩니다."
};