export default {
    messageQuestionService: "Z tej usługi można korzystać tylko w przypadku problemów z wypełnieniem formularza zgłoszeniowego, nie możemy tutaj odpowiedzieć na żadne inne pytania.",
    labelName: "Nazwa",
    labelReference: "Numer rezerwacji",
    labelPhone: "Numer telefonu",
    labelEmail: "E-mail",
    labelQuery: "Twoje pytanie",
    labelAskQuestion: "Wyślij nam pytanie tutaj ...",
    buttonOpenModal: "Zadać pytanie",
    title: "Zadać pytanie",
    modalTitle: "4. Masz problem techniczny z formularzem?",
    toastSuccess: "Otrzymaliśmy Twoje pytanie i skontaktujemy się z Tobą w ciągu 24 godzin",
    toastError: "Coś poszło nie tak z Twoją prośbą, spróbuj ponownie",
    nameValidation: "imie jest wymagane",
    referenceValidation: "Wymagane jest odniesienie",
    questionValidation: "Twoje pytanie jest wymagane"
}