import * as React from 'react';
import {
    SelectInput,
    TextInput,
    DateInput
} from '../formInputs';
import {
    useFieldHidden,
    useWisePaymentItems
} from '../../hooks/usePaymentInformation';
import {
    Field,
    FormikProps
} from 'formik';
import {IFormValues} from '../Interfaces/IFormValues';
import {Section} from '../enums/section';
import {nameof} from 'ts-simple-nameof';
import {useFormContext} from '../providers/FormProvider';
import {useTranslation} from 'react-i18next';
import {getWiseLanguage} from '../../../languages/locales/languageSettings';
import {TermsAndConditionsModal} from '../modals/TermsAndConditionsModal';
import {useIsOffline} from '../utils/helpers';
import {AccountCaptureFieldsControl} from '../controls/AccountCaptureFieldsControl';
import {PaymentInformationAlert} from '../alerts/PaymentInformationAlert';
import {BankDetailsConfirmationModal} from '../modals/BankDetailsConfirmationModal';
import usePaymentLabels from '../../hooks/usePaymentLabels';
import {BankSelectControl} from '../controls/BankSelectControl';
import {CurrencySelectsControl} from '../controls/CurrencySelectsControl';
import {SectionDivider} from '../ui/SectionDivider';
import {SectionHeadingTextTheme} from './SectionHeadingTextTheme';

interface PaymentInformationSectionProps {
    formProps: FormikProps<IFormValues>;
}

export const PaymentInformationSection = (props: PaymentInformationSectionProps) => {

    const {
        formProps
    } = props;

    const {
        bankBranchesDropdownItems,
        regionDropdownItems,
        selectedSection,
        updateBeneficiaryLabels,
        validCurrency
    } = useFormContext();

    const {
        i18n,
        t
    } = useTranslation();

    const getWiseLanguageCode = getWiseLanguage(i18n.language);
    const isOfflinePayment = useIsOffline();
    const paymentLabels = usePaymentLabels(formProps.values.currency);
    const wisePaymentItems = useWisePaymentItems();
    const isFieldHidden = useFieldHidden();

    return (<>
            {selectedSection === Section.PaymentInformation &&
                <>
                    <SectionHeadingTextTheme text={t(`common:formSectionNavigatorStep3Text`)}/>

                    <PaymentInformationAlert/>

                    {!validCurrency &&
                        <CurrencySelectsControl formProps={formProps}/>
                    }

                    <TextInput
                        key={nameof<IFormValues>(x => x.beneficiaryAddress1)}
                        placeholder={t('form:labelAddressLine1')}
                        label={t('form:labelAddressLine1')}
                        controlId={nameof<IFormValues>(x => x.beneficiaryAddress1)}
                        hidden={false}
                        tooltip={t('form:tooltipAddressLine1')}
                        maxLength={255}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.beneficiaryCity)}
                        placeholder={t('form:labelCity')}
                        label={t('form:labelCity')}
                        controlId={nameof<IFormValues>(x => x.beneficiaryCity)}
                        hidden={false}
                        tooltip={t('form:tooltipCity')}
                        maxLength={100}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.beneficiaryPostCode)}
                        placeholder={t('form:labelZipCode')}
                        label={t('form:labelZipCode')}
                        controlId={nameof<IFormValues>(x => x.beneficiaryPostCode)}
                        hidden={false}
                        tooltip={t('form:tooltipZipCode')}
                        maxLength={100}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.beneficiaryRegion)}
                        label={t('form:labelRegion')}
                        controlId={nameof<IFormValues>(x => x.beneficiaryRegion)}
                        hidden={regionDropdownItems?.length === 0}
                        dropDownItemsInput={regionDropdownItems}
                        tooltip={t('form:tooltipRegion')}
                        required
                    />

                    <SectionDivider dividerText='Banking Details' />

                    <TextInput
                        key={nameof<IFormValues>(x => x.bankAccountName)}
                        placeholder={paymentLabels.labelBankAccountName}
                        label={paymentLabels.labelBankAccountName}
                        controlId={nameof<IFormValues>(x => x.bankAccountName)}
                        hidden={!validCurrency}
                        tooltip={paymentLabels.tooltipBankAccountName}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.bankName)}
                        placeholder={paymentLabels.labelBankName}
                        label={paymentLabels.labelBankName}
                        controlId={nameof<IFormValues>(x => x.bankName)}
                        hidden={isFieldHidden.bankName}
                        tooltip={paymentLabels.tooltipBankName}
                        required
                    />

                    <BankSelectControl formProps={formProps}/>

                    <SelectInput
                        key={nameof<IFormValues>(x => x.branchCodeSelect)}
                        label={paymentLabels.labelBranchCode}
                        controlId={nameof<IFormValues>(x => x.branchCodeSelect)}
                        hidden={isFieldHidden.branchCodeSelect}
                        dropDownItemsInput={bankBranchesDropdownItems}
                        tooltip={paymentLabels.tooltipBranchCode}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.bankAccountType)}
                        label={paymentLabels.labelBankAccountType}
                        controlId={nameof<IFormValues>(x => x.bankAccountType)}
                        prefixKey={'payment:optionBankAccountType'}
                        hidden={isFieldHidden.bankAccountType}
                        dropDownItemsInput={wisePaymentItems.bankAccountType}
                        tooltip={paymentLabels.tooltipBankAccountType}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.paymentType)}
                        label={paymentLabels.labelPaymentType}
                        controlId={nameof<IFormValues>(x => x.paymentType)}
                        onChange={(e) => updateBeneficiaryLabels(
                            formProps,
                            formProps.values.memberId,
                            formProps.values.homeCountry,
                            formProps.values.currency,
                            formProps.values.bookingValue,
                            true,
                            e.target.value)
                        }
                        hidden={isFieldHidden.paymentType}
                        dropDownItemsInput={wisePaymentItems.paymentType}
                        tooltip={paymentLabels.tooltipPaymentType}
                        required
                    />

                    <AccountCaptureFieldsControl formProps={formProps}/>

                    <TextInput
                        key={nameof<IFormValues>(x => x.branchCode)}
                        placeholder={paymentLabels.labelBranchCode}
                        label={paymentLabels.labelBranchCode}
                        controlId={nameof<IFormValues>(x => x.branchCode)}
                        hidden={isFieldHidden.branchCode}
                        tooltip={paymentLabels.tooltipBranchCode}
                        required
                        externalLink={paymentLabels.labelBranchCode.includes('Institution') ?
                            `https://wise.com/help/articles/2955294/how-do-i-find-my-cad-institution-andor-transit-number`
                            : null}
                        externalLinkText={t('payment:labelBranchCodeHelp')}
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.remittanceLine3Select)}
                        label={paymentLabels.labelDocumentType}
                        controlId={nameof<IFormValues>(x => x.remittanceLine3Select)}
                        hidden={isFieldHidden.remittanceLine3Select}
                        dropDownItemsInput={wisePaymentItems.remittanceLine3Select}
                        tooltip={paymentLabels.tooltipDocumentType}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.remittanceLine3)}
                        placeholder={paymentLabels.labelDocumentNumber}
                        label={paymentLabels.labelDocumentNumber}
                        controlId={nameof<IFormValues>(x => x.remittanceLine3)}
                        hidden={isFieldHidden.remittanceLine3}
                        tooltip={paymentLabels.tooltipDocumentNumber}
                        required
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.remittanceLine4Select)}
                        label={paymentLabels.labelRemittanceLine4}
                        controlId={nameof<IFormValues>(x => x.remittanceLine4Select)}
                        hidden={isFieldHidden.remittanceLine4Select}
                        dropDownItemsInput={wisePaymentItems.remittanceLine4Select}
                        tooltip={paymentLabels.tooltipRemittanceLine4}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.remittanceLine4)}
                        placeholder={paymentLabels.labelRemittanceLine4}
                        label={paymentLabels.labelRemittanceLine4}
                        controlId={nameof<IFormValues>(x => x.remittanceLine4)}
                        hidden={isFieldHidden.remittanceLine4}
                        tooltip={paymentLabels.tooltipRemittanceLine4}
                        required
                    />

                    <DateInput
                        key={nameof<IFormValues>(x => x.dateOfBirth)}
                        label={paymentLabels.labelDateOfBirth}
                        controlId={nameof<IFormValues>(x => x.dateOfBirth)}
                        hidden={isFieldHidden.dateOfBirth}
                        tooltip={paymentLabels.tooltipDateOfBirth}
                        showMonthAndYearSelect={true}
                        required
                    />

                    <TextInput
                        key={nameof<IFormValues>(x => x.bankSwiftBic)}
                        placeholder={paymentLabels.labelBeneficiarySWIFTBIC}
                        label={paymentLabels.labelBeneficiarySWIFTBIC}
                        controlId={nameof<IFormValues>(x => x.bankSwiftBic)}
                        hidden={isFieldHidden.bankSwiftBic}
                        tooltip={paymentLabels.tooltipBeneficiarySWIFTBIC}
                        required
                        externalLink={`https://wise.com/${getWiseLanguageCode}/swift-codes`}
                        externalLinkText={t('payment:labelBeneficiarySWIFTBICHelp')}
                    />

                    <SelectInput
                        key={nameof<IFormValues>(x => x.bankSwiftBicSelect)}
                        label={paymentLabels.labelBeneficiarySWIFTBIC}
                        controlId={nameof<IFormValues>(x => x.bankSwiftBicSelect)}
                        hidden={isFieldHidden.bankSwiftBicSelect}
                        dropDownItemsInput={wisePaymentItems.bankSwiftBicSelect}
                        tooltip={paymentLabels.tooltipBeneficiarySWIFTBIC}
                        required
                    />

                    {!isOfflinePayment &&
                        <TermsAndConditionsModal memberId={formProps.values.memberId}/>
                    }

                    <Field
                        type='number'
                        hidden={true}
                        key={nameof<IFormValues>(x => x.memberId)}
                        name={nameof<IFormValues>(x => x.memberId)}
                        id={nameof<IFormValues>(x => x.memberId)}
                    />

                    <BankDetailsConfirmationModal formProps={formProps}/>
                </>
            }
        </>
    );
}