import * as React from 'react';
import {Box} from '@mui/material';
import {IFormValues} from '../Interfaces/IFormValues';
import {FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {refundableUrl} from '../utils/constants';
import {AskQuestionModal} from '../modals/AskQuestionModal';
import {FooterContentTheme} from '../themes/FooterContentTheme';
import {useFormContext} from '../providers/FormProvider';

interface FooterProps {
    formProps: FormikProps<IFormValues>;
    url: string;
}

export const Footer = (props: FooterProps) => {

    const {
        formProps,
        url
    } = props;

    const {
        applicationSubmitted,
        bookingCounter
    } = useFormContext();

    const {t} = useTranslation();

    const FooterContent = () => {
        return (
            <>
                <Box component='span' sx={{pr: 1}}>
                    <a href={`${refundableUrl}/privacy-notice`}
                       target='_blank'
                       rel='noreferrer'>{t('common:linkPrivacyStatement')}</a>
                </Box>|
                <Box component='span' sx={{pl: 1}}>
                    <a href={`${refundableUrl}/complaints-policy`}
                       target='_blank'
                       rel='noreferrer'>{t('common:linkComplaintsPolicy')}</a>
                </Box>
            </>
        );
    }

    return (
        <>
            <FooterContentTheme
                content={<FooterContent/>}
                formProps={formProps}
                url={url}
            />

            <AskQuestionModal formProps={formProps}/>
        </>
    );
}