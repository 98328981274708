import {IRefundBlanketDeclineReason} from '../Interfaces/IRefundBlanketDeclineReason';

export const refundBlanketDeclineReasons: IRefundBlanketDeclineReason[] = [
    { id: 17, name: 'COVID19TravelRestrictionsId' },
    { id: 20, name: 'bookingCancelledByOperatorOrganiserId' },
    { id: 21, name: 'bookingRescheduledId' },
    { id: 22, name: 'bookedWrongDateId' },
    { id: 23, name: 'bookedWrongTicketsId' },
    { id: 24, name: 'bookedTooManyTicketsId' }
];

export const refundBlanketCovidDeclineReasons: IRefundBlanketDeclineReason[] = [
    { id: 19, name: 'COVID19PositiveTestId' }
];

export const refundableUrl = 'https://refundable.me';

export const RefundReasonIllnessInjury = 1;
export const RefundReasonPreExistingMedicalCondition = 2;
export const RefundReasonPregnancyComplication = 3;
export const RefundReasonDeath = 4;
export const RefundReasonPublicTransportFailure = 5;
export const RefundReasonScheduledAirlineFailure = 6;
export const RefundReasonMechanicalBreakdown = 7;
export const RefundReasonJuryService = 8;
export const RefundReasonHomeEmergency = 9;
export const RefundReasonArmedForcesAndEmergencyServicesRecall = 10;
export const RefundReasonAdverseWeather = 11;
export const RefundReasonWorkRelocation = 12;
export const RefundReasonTheftOfDocuments = 13;
export const RefundReasonGovernmentTravelBan = 14;
export const RefundReasonChangesToExaminationDates = 15;
export const RefundReasonEmergencyCircumstances = 16;
export const RefundReasonCOVID19TravelRestrictions = 17;
export const RefundReasonCourtSummons = 18;
export const RefundReasonCOVID19PositiveTest = 19;
export const RefundReasonBookingCancelledByOperator = 20;
export const RefundReasonBookingRescheduledByOperator = 21;
export const RefundReasonBookedWrongDate = 22;
export const RefundReasonBookedWrongTickets = 23;
export const RefundReasonBookedTooManyTickets = 24;
export const RefundReasonWorkplaceRedundancy = 25;
export const RefundReasonTestOption = 26;

export const NotApplicable = 'Not Applicable';
export const System = 'System';
export const OptionNo = 1;
export const OptionYes = 2;
export const OptionArmedForcesOther = 10;
export const OptionCourtSummonsOther = 7;
export const OptionCOVID19PositiveTestAnotherPerson = 2;
export const OptionCOVID19PositiveTestHouseholdPerson = 3;
export const OptionCOVID19PositiveTestOther = 4;
export const OptionCOVID19PositiveTestTypeOther = 6;
export const OptionDeathOther = 11;
export const OptionHomeEmergencyOther = 7;
export const OptionWorkRelocationTemporary = 1;
export const OptionImmediateRelativeOfSomeoneInTheBooking = 2;

export const bankBeforeAccountCurrencyCodes: string[] = [
  'AUD'
];

export const errorInvalidCPFCode = 'cpf code';