import axios, {AxiosResponse} from 'axios';
import {IDropDownItem} from '../form/Interfaces/IDropDownItem';

export const getAutoSaveMinutes = async (): Promise<AxiosResponse<number>> => {
    const url = `/api/referencedata/auto-save-minutes`;
    return axios.get<number>(url);
}

export const getLiveChatEnabledStatus = async (): Promise<AxiosResponse<boolean>> => {
    const url = `/api/referencedata/livechat-enabled-status`;
    return axios.get<boolean>(url);
}

export const getMemberTermsSubDirectory = async (memberId: number): Promise<AxiosResponse<string>> => {
    const url = `/api/member/${memberId}/terms-subdirectory`;
    return axios.get<string>(url);
}

export const getMsClarityInjection = async (): Promise<AxiosResponse<boolean>> => {
    const url = `/api/referencedata/msclarity-injection`;
    return axios.get<boolean>(url);
}

export const getSelectData = async (dataSourceUrl: string): Promise<AxiosResponse<IDropDownItem[]>> => {
    return axios.get<IDropDownItem[]>(dataSourceUrl);
}